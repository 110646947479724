import { apiService } from "./apiService";
import { ListProjectsResponse } from "./listProject";

export type ProjectDynamicKey = {
  [key: string]: any;
};

export const getProject = async (
  project_id: string
): Promise<ProjectDynamicKey> => {
  const response = await apiService.get<string>(
    `/get-project?project_id=${project_id}`
  );

  // Assuming response is a JSON string, parse it into an object

  const parsedResponse: ProjectDynamicKey = JSON.parse(response);
  console.log(parsedResponse);
  return parsedResponse;
};
