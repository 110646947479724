import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import logo from "../../assets/images/listen-bravo-logo.png";
import theme from "../../theme";
import BurstLoader from "./BurstLoader";

const GettingReady = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#ffffff",
      }}>
      <img src={logo} height={100} width={100} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "#333",
          marginTop: theme.spacing(2),
          gap: 1,
        }}>
        <BurstLoader />
        <Typography typography='h2'>
          Hang in there. Getting things ready for you...
        </Typography>
      </Box>
    </Box>
  );
};

export default GettingReady;
