import { Box, Button, Popover, Typography } from "@mui/material";
import React from "react";
import PeopleChips from "../common/PeopleChips";
import aiEnrich from "../../assets/images/ai-enrich.svg";
import peopleIcon from "../../assets/images/people.svg";

interface AiEnrichPopoverProps {
  anchorEl: null;
  handleClose: () => void;
  setShowAIEnrichment: any;
  setPeoplePopoverAnchor: any;
}

const AiEnrichPopover: React.FC<AiEnrichPopoverProps> = ({
  anchorEl,
  handleClose,
  setShowAIEnrichment,
  setPeoplePopoverAnchor,
}) => {
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Button
          startIcon={<img src={aiEnrich} height={16} width={16} />}
          onClick={() => {
            setShowAIEnrichment(true);
          }}
          style={{ color: "#757575", fontSize: 14 }}
        >
          AI Enrichment
        </Button>
        <Button
          onClick={(e: any) => {
            setPeoplePopoverAnchor(e.currentTarget);
          }}
          startIcon={<img src={peopleIcon} height={16} width={16} />}
          style={{ color: "#757575", fontSize: 14 }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            People
            {/* <Box
                        sx={{
                          borderRadius: 50,
                          padding: "0.5em 1em",
                          backgroundColor: "#FFE7E6",
                        }}>
                        <Typography
                          fontSize={10}
                          color={theme.palette.secondary.main}>
                          Coming soon
                        </Typography>
                      </Box> */}
          </Box>
        </Button>
      </Box>
    </Popover>
  );
};

export default AiEnrichPopover;
