import { Box, Button, Popover, Typography } from "@mui/material";
import React from "react";
import PeopleChips from "../common/PeopleChips";
import aiEnrich from "../../assets/images/ai-enrich.svg";
import peopleIcon from "../../assets/images/people.svg";
import { AIEnrichmentRequest } from "../../service/aiEnrichment";
import { calculateCreditsUsed } from "../home/SpreadSheet";

interface RunPopoverProps {
  anchorEl: null;
  handleClose: () => void;
  nextCounts: { [key: string]: number };
  setNextCounts: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >;
  filteredData: any[];
  setSelectedRows: any;
  runCsvEnrich: (number: number, data: any[], isPeopleCol: boolean) => void;
  aiEnrichRequest: AIEnrichmentRequest | null;
  currentId: string;
  selectedRows: any[];
}

const RunPopover: React.FC<RunPopoverProps> = ({
  anchorEl,
  setNextCounts,
  handleClose,
  nextCounts,
  filteredData,
  setSelectedRows,
  runCsvEnrich,
  aiEnrichRequest,
  currentId,
  selectedRows,
}) => {
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}>
      <Box
        p={2}
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='flex-start'>
        <Button
          onClick={() => {
            runCsvEnrich(
              10,
              filteredData,
              aiEnrichRequest?.is_people_col as boolean
            );
          }}
          style={{ color: "#757575", fontSize: 14 }}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            <Typography variant='h3'>Run next 10 rows</Typography>
            <PeopleChips
              chips={`${
                aiEnrichRequest?.enrichments
                  ? calculateCreditsUsed(10, aiEnrichRequest)
                  : 10
              }`}
            />
          </Box>
        </Button>
        <Button
          onClick={() => {
            runCsvEnrich(
              100,
              filteredData,
              aiEnrichRequest?.is_people_col as boolean
            );
          }}
          style={{ color: "#757575", fontSize: 14 }}>
          <Box display='flex' alignItems='center' gap={1}>
            <Typography variant='h3'>Run next 100 rows</Typography>
            <PeopleChips
              chips={`${
                aiEnrichRequest?.enrichments
                  ? calculateCreditsUsed(100, aiEnrichRequest)
                  : 100
              }`}
            />
          </Box>
        </Button>
        <Button
          onClick={() => {
            runCsvEnrich(
              1000,
              filteredData,
              aiEnrichRequest?.is_people_col as boolean
            );
          }}
          style={{ color: "#757575", fontSize: 14 }}>
          <Box display='flex' alignItems='center' gap={1}>
            <Typography variant='h3'>Run all selected rows</Typography>
            <PeopleChips
              chips={`${
                aiEnrichRequest?.enrichments
                  ? calculateCreditsUsed(selectedRows.length, aiEnrichRequest)
                  : selectedRows.length * 1
              }`}
            />
          </Box>
        </Button>
      </Box>
    </Popover>
  );
};

export default RunPopover;
