import { apiService } from "./apiService";
import { ListProjectsResponse } from "./listProject";

export type ProjectPrompts = {
  column_name: string;
  prompt: string;
  answer_format: string;
  enrichments?: number[];
  locations?: string[];
  number_of_people?: number;
  titles?: string[];
  seniorities: string[];
};
export type DynamicPrompt = {
  dynamic_prompts: ProjectPrompts[];
};
export type ProjectHeaders = {
  count: string;
  data: DynamicPrompt[];
};
export const getProjectHeaders = async (
  project_id: string
): Promise<ProjectHeaders> => {
  const response = await apiService.get<string>(
    `/get-dynamic-prompts?project_id=${project_id}`
  );

  // Assuming response is a JSON string, parse it into an object
  const parsedResponse: ProjectHeaders = JSON.parse(response);

  return parsedResponse;
};
