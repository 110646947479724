import {
  Add,
  ArrowBackIos,
  Cached,
  Close,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowDownRounded,
  LaunchOutlined,
  Pause,
  PauseOutlined,
  PauseRounded,
  PlayArrow,
  Refresh,
  Search,
  Update,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  Divider,
  IconButton,
  Link,
  Paper,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import theme from "../../theme";
import csvIcon from "../../assets/images/csv-icon.svg";
import Pagination from "./Pagination";
import pauseIcon from "../../assets/images/pause-icon.svg";
import aiEnrich from "../../assets/images/ai-enrich.svg";
import peopleIcon from "../../assets/images/people.svg";
import { DataItem, formatDate } from "../../utils/utils";
import { AIEnrichmentRequest, aiEnrichment } from "../../service/aiEnrichment";
import { toast } from "react-toastify";
import BurstLoader from "../common/BurstLoader";
import { ProjectRecord } from "../../service/listProject";
import astrix from "../../assets/images/astrix.svg";
import { updateColumnService } from "../../service/updateColumnService";
import { deleteColumn } from "../../service/deleteColumn";
import {
  DynamicPrompt,
  getProjectHeaders,
  ProjectPrompts,
} from "../../service/getProjectHeaders";
import elipsIcon from "../../assets/images/elipses-icon.svg";
import duplicate from "../../assets/images/duplicate.svg";
import rename from "../../assets/images/rename.svg";
import deleteIcon from "../../assets/images/delete.svg";
import { DeleteDialog, RenameDialog } from "./projectDialogs";
import { saveAs } from "file-saver";
import AiEnrichPopover from "../myTable/AiEnrichPopover";
import AiEnrichmentColumnPopover from "../myTable/AiEnrichmentColumnPopover";
import AddPeoplePopOver from "./AddPeoplePopOver";
import RunPopover from "../myTable/RunPopover";
import ColumnDetailPopover from "../myTable/ColumnDetailPopover";
import { NewCol } from "./SpreadSheet";
import matchFound from "../../assets/images/match_found.png";
import matchNotFound from "../../assets/images/match_not_found.png";
import {
  researchAiEnrichment,
  researchFindPeople,
} from "../../service/aiEnrichmentResearchCsv";
import HeaderFilter from "../myTable/HeaderFilter";

type MyTableProps = {
  title: string;
  data: DataItem[];
  initialHeaders: string[];
  onClose: () => void;
  projectId: string;
  projectHeaders: ProjectPrompts[];
  isResearch: boolean;
  handleRefresh: () => void;
  setCredits: (credits: number) => void;
  showTableLoader: boolean;
  initialHeaderMappings: { [key: string]: string };
};

const MyTable: React.FC<MyTableProps> = ({
  title,
  data,
  projectHeaders,
  initialHeaders,
  onClose,
  isResearch,
  projectId,
  handleRefresh,
  showTableLoader,
  initialHeaderMappings,
  setCredits,
}) => {
  const [filteredData, setFilteredData] = useState(data);
  const [page, setPage] = useState(0);
  const [showAIEnrichProgress, setShowAIEnrichProgress] =
    useState<boolean>(false);
  const [showAIEnrichment, setShowAIEnrichment] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAiEnrich, setAnchorElAiEnrich] = useState(null);
  const [anchorElPeople, setAnchorElPeople] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [showRunPopup, setShowRunPop] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [anchorElRun, setAnchorElRun] = useState(null);
  const [headers, setHeaders] = useState<NewCol[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const [aiEnrichRequest, setAiEnrichRequest] =
    useState<AIEnrichmentRequest | null>(null);
  const [requestPrompt, setRequestPrompt] = useState<string>("");
  const [columnName, setColumnName] = useState<string>("");
  const [answerFormat, setAnswerFormat] = useState<string>("Yes/No");
  const [dataSource, setDataSource] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPromptDetail, setShowPromptDetail] = useState(false);
  const [colDetailEl, setColDetailEl] = useState(null);
  const [selectedHeader, setSelectedHeader] = useState<any>();
  const [showColumnDetailPop, setShowColumnDetailPop] =
    useState<boolean>(false);
  const [headerMappings, setHeaderMappings] = useState<{
    [key: string]: string;
  }>({});
  const [anchorElColumnDetail, setAnchorElColumnDetail] = useState(null);
  const [newCols, setNewCols] = useState<any[]>([]);
  const [peoplePopoverAnchor, setPeoplePopoverAnchor] = useState(null);
  const [updateLocalStorage, setUpdateLocalStorage] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [columnNextCount, setColumnNextCount] = useState<{
    [key: string]: number;
  }>({});

  const [colChecked, setColChecked] = useState(false);

  useEffect(() => {
    if (
      selectedRows.length === filteredData.length &&
      filteredData.length !== 0
    ) {
      setColChecked(true);
    } else {
      setColChecked(false);
    }
  }, [selectedRows]);
  const handleRowSelect = (row: ProjectRecord) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(row)
        ? prevSelectedRows.filter((rowPrev) => rowPrev !== row)
        : [...prevSelectedRows, row]
    );
  };

  useEffect(() => {
    if (data.length > 0) {
      setFilteredData(
        data.map((v, idx) => {
          return { id: idx, ...v };
        })
      );
      console.log(data);
      const newHeaders: any[] = [];
      // if its notResearch then initialheaders.map

      initialHeaders.map((h) => {
        newHeaders.push({
          column_name: h,
          sources: [dataSource],
          prompt: "",
          answer_format: "",
          answer: "",
          records: [],
          reason: "",
          link: "",
          links: [],
          user_generated: false,
        });
      });

      const newHeaderMappings = initialHeaderMappings;

      if (projectHeaders && projectHeaders.length > 0) {
        console.log(projectHeaders);

        projectHeaders.map((p) => {
          if (p.number_of_people) {
            newHeaders.push({
              column_name: `${p.column_name}`,
              project_id: projectId,
              prompt: requestPrompt,
              answer_format: answerFormat,
              answer: "",
              reason: "",
              link: "",
              links: [],
              sources: [dataSource],
              number_of_people: p.number_of_people,
              titles: p.titles,
              locations: p.locations,
              seniorities: p.seniorities,
              enrichments: p.enrichments,
              is_enrich_col: false,
              is_people_col: true,
              user_generated: true, // Main column, not an enrichment column
            });
            for (let i = 0; i < p.number_of_people; i++) {
              // Enrichment columns for the person
              p?.enrichments?.forEach((enrichment, idx) => {
                newHeaders.push({
                  column_name: `${p.column_name} - ${
                    enrichment === 1
                      ? "Name"
                      : enrichment === 2
                      ? "Email"
                      : enrichment === 3
                      ? "LinkedIn"
                      : "Phone"
                  } ${i + 1}`,
                  project_id: projectId,

                  prompt: p.prompt,
                  answer_format: p.answer_format,
                  answer: "",
                  reason: "",
                  link: "",
                  links: [],
                  sources: [dataSource],
                  number_of_people: p.number_of_people,
                  titles: p.titles,
                  locations: p.locations,
                  seniorities: p.seniorities,
                  enrichments: p.enrichments,
                  is_enrich_col: true,
                  is_people_col: false,
                  user_generated: true,
                });
              });
            }
          } else {
            newHeaders.push({
              column_name: p.column_name,
              prompt: p.prompt,
              answer_format: p.answer_format,
              answer: "",
              records: [],
              reason: "",
              link: "",
              user_generated: true,
              sources: [dataSource],
              links: [],
              // headers,
            });
          }
          newHeaderMappings[p.column_name] = p.column_name;
          // newHeaderMappings[p.column_name] = p.column_name;
        });
      }
      console.log("headermapping", newHeaders);
      setHeaders(newHeaders);
      setVisibleColumns(
        newHeaders
          .filter((nh) => nh.column_name !== "index")
          .map((nH) => nH.column_name)
      );
      // setHeaderMappings((prevMappings) => ({
      //   ...prevMappings,
      //   ...newHeaderMappings,
      // }));
      // setHeaderMappings(newHeaderMappings);
      const storedData = localStorage.getItem("newHeaders");
      if (storedData) {
        const newHeadersArray = JSON.parse(storedData);

        const projectData = newHeadersArray.find(
          (item: any) => item.project === projectId
        );
        if (projectData) {
          console.log(projectData, projectId);
          setNewCols(
            projectData.cols.filter(
              (col: any) =>
                !newHeaders.some(
                  (header) => header.column_name === col.column_name
                )
            )
          );
        }
      }
    }
  }, []);
  const updateColumn = () => {
    // Retrieve existing data from localStorage
    const storedData = localStorage.getItem("newHeaders");
    let newHeadersArray = storedData ? JSON.parse(storedData) : [];

    // Check if the projectId already exists in the array
    const projectIndex = newHeadersArray.findIndex(
      (item: any) => item.project === projectId
    );
    if (newCols.length > 0) {
      if (projectIndex >= 0) {
        // Update existing project entry
        newHeadersArray[projectIndex].cols = newCols;
      } else {
        // Add new project entry
        newHeadersArray.push({
          project: projectId,
          cols: newCols,
        });
      }
    } else {
      // If newCols is empty, remove the project entry if it exists
      if (projectIndex >= 0) {
        newHeadersArray.splice(projectIndex, 1);
      }
    }

    // Save the updated array back to localStorage
    localStorage.setItem("newHeaders", JSON.stringify(newHeadersArray));
  };

  const handleRowSelectAll = () => {
    setSelectedRows(data);
  };

  const handleRunClick = async () => {
    const selectedRecords = selectedRows.map((rowId) =>
      filteredData.find((row) => row.id === rowId)
    );
    console.log(selectedRecords);
  };

  // console.log("the project id is: ", projectId);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const updateNextCount = (columnName: string, increment: number) => {
    setColumnNextCount((prev) => {
      const currentCount = prev[columnName] || 0; // Get the current count or default to 0
      return {
        ...prev,
        [columnName]: currentCount + increment, // Update the count for the column
      };
    });
  };

  const runCsvEnrich = async (
    rowCount: number,
    rowsAdded: any[],
    isPeopleCol: boolean
  ) => {
    if (!isPeopleCol) {
      try {
        if (rowCount > 100 && selectedRows.length === 0) {
          toast.error("Please select rows");
        } else {
          var currentNextCount =
            columnNextCount[aiEnrichRequest?.column_name as string] || 0;

          if (currentNextCount >= filteredData.length) {
            currentNextCount = 0;
            updateNextCount(aiEnrichRequest?.column_name as string, 0);
          }
          console.log(currentNextCount, filteredData.length);
          const rowsToProcess = rowsAdded.slice(
            currentNextCount,
            currentNextCount + rowCount
          );
          setSelectedRows(rowsToProcess);
          const companies = rowsToProcess.map((item, index) => {
            return item["Company Name"];
          });
          setCurrentId(aiEnrichRequest?.column_name as string);
          setAnchorEl(null);
          setAnchorElRun(null);

          setShowAIEnrichProgress(true);
          const dataSpliced = selectedRows;

          const aiEnrichResponse: any = await researchAiEnrichment({
            project_id: projectId,
            column_name: aiEnrichRequest?.column_name as string,
            // records: rowsAdded,
            answer_format: aiEnrichRequest?.answer_format as string,
            prompt: aiEnrichRequest?.prompt as string,
            sources: aiEnrichRequest?.sources as number[],
            companies: companies as string[],
          });
          setCredits(aiEnrichResponse.credits_left);
          setNewCols(
            newCols.filter(
              (co) => co.column_name !== aiEnrichRequest?.column_name
            )
          );

          if (
            headers.filter(
              (h) => h.column_name === aiEnrichRequest?.column_name
            ).length === 0
          ) {
            setHeaders((prev) => [
              ...prev,
              {
                project_id: projectId,
                column_name: aiEnrichRequest?.column_name as string,
                records: [],
                answer_format: aiEnrichRequest?.answer_format as string,
                prompt: aiEnrichRequest?.prompt as string,
                answer: "",
                reason: "",
                link: "",
                sources: aiEnrichRequest?.sources as number[],
                links: companies as string[],
                user_generated: true,
              },
            ]);
            setVisibleColumns((prev) => [
              ...prev,
              aiEnrichRequest?.column_name as string,
            ]);
          }

          const updatedData = filteredData.map((record) => {
            const newRecord = aiEnrichResponse.records.find(
              (newRec: any) => newRec["Company Name"] === record["Company Name"]
            );
            return newRecord ? newRecord : record;
          });
          // console.log(updatedData);
          setFilteredData(updatedData);

          // const updatedData = filteredData.map((record) => {
          //   const companyName = record["Company Name"];
          //   if (companyName && aiEnrichResponse[companyName]) {
          //     // Update the record with the enrichment data only if the company name is found in aiEnrichResponse
          //     const enrichmentData = aiEnrichResponse[companyName];
          //     return {
          //       ...record,
          //       [aiEnrichRequest?.column_name as string]: enrichmentData, // Assuming enrichmentData is not an array
          //     };
          //   }
          //   // Return the record unchanged if the company name is not found
          //   return record;
          // });

          // console.log(updatedData);

          // setFilteredData((prev) => {
          //   // Filter out any records in `prev` that have the same ID as one in `rowsAdded`
          //   const filteredPrev = prev.filter(
          //     (item) => !rowsAdded.some((added) => added.id === item.id)
          //   );

          //   // Add the `updatedData` to the filtered previous data
          //   return [...filteredPrev, ...updatedData];
          // });
          // handleRefresh();

          setShowRunPop(false);

          setShowAIEnrichProgress(false);
          setShowAIEnrichment(false);
          setSelectedRows([]);
          if (rowCount > 10) {
            updateNextCount(aiEnrichRequest?.column_name as string, 100);
          } else {
            updateNextCount(aiEnrichRequest?.column_name as string, 10);
          }
          setAiEnrichRequest(null);
          // handleRefresh();

          // setNewCols([]);
        }
      } catch (error: any) {
        setShowRunPop(false);
        setAiEnrichRequest(null);
        setShowAIEnrichProgress(false);
        setShowAIEnrichment(false);
        //   setNewCols([]);
        toast.error(error.message);
      }
    } else {
      try {
        if (rowCount > 100 && selectedRows.length === 0) {
          toast.error("Please select rows");
        } else {
          var currentNextCount =
            columnNextCount[aiEnrichRequest?.column_name as string] || 0;

          if (currentNextCount >= filteredData.length) {
            currentNextCount = 0;
            updateNextCount(aiEnrichRequest?.column_name as string, 0);
          }

          const rowsToProcess = rowsAdded.slice(
            currentNextCount,
            currentNextCount + rowCount
          );
          setSelectedRows(rowsToProcess);
          const companies = rowsToProcess.map((item, index) => {
            return item["Company Name"];
          });
          console.log("enrichRequest", aiEnrichRequest);
          setCurrentId(aiEnrichRequest?.column_name as string);
          setAnchorEl(null);
          setAnchorElRun(null);

          setShowAIEnrichProgress(true);
          const dataSpliced = selectedRows;

          const aiEnrichResponse = await researchFindPeople({
            project_id: projectId,
            column_name: aiEnrichRequest?.column_name as string,
            // records: rowsAdded,
            answer_format: aiEnrichRequest?.answer_format as string,
            prompt: aiEnrichRequest?.prompt as string,
            sources: aiEnrichRequest?.sources as number[],
            titles: aiEnrichRequest?.titles,
            seniorities: aiEnrichRequest?.seniorities,
            locations: aiEnrichRequest?.locations,
            number_of_people: aiEnrichRequest?.number_of_people,
            enrichments: aiEnrichRequest?.enrichments,
            companies: companies as string[],
            links: [],
          });
          setCredits(aiEnrichResponse.credits_left);
          setNewCols(
            newCols.filter(
              (co) =>
                !co.column_name.includes(
                  aiEnrichRequest?.column_name + " - "
                ) && co.column_name !== aiEnrichRequest?.column_name
            )
          );

          if (
            headers.filter(
              (h) => h.column_name === aiEnrichRequest?.column_name
            ).length === 0
          ) {
            if (aiEnrichRequest?.number_of_people) {
              var newHeaders: NewCol[] = [];
              newHeaders.push({
                column_name: `${aiEnrichRequest?.column_name}`,
                project_id: projectId,
                prompt: requestPrompt,
                answer_format: answerFormat,
                answer: "",
                reason: "",
                link: "",
                links: [],
                sources: [dataSource],
                number_of_people: aiEnrichRequest?.number_of_people,
                titles: aiEnrichRequest?.titles,
                locations: aiEnrichRequest?.locations,
                seniorities: aiEnrichRequest?.seniorities,
                enrichments: aiEnrichRequest?.enrichments,
                is_enrich_col: false,
                is_people_col: true,
                user_generated: true,
                records: [],
              });
              for (let i = 0; i < aiEnrichRequest?.number_of_people; i++) {
                // Main column for the person

                // Enrichment columns for the person
                aiEnrichRequest?.enrichments?.forEach((enrichment, idx) => {
                  newHeaders.push({
                    column_name: `${aiEnrichRequest?.column_name} - ${
                      enrichment === 1
                        ? "Name"
                        : enrichment === 2
                        ? "Email"
                        : enrichment === 3
                        ? "LinkedIn"
                        : "Phone"
                    } ${i + 1}`,
                    project_id: projectId,
                    prompt: requestPrompt,
                    answer_format: answerFormat,
                    answer: "",
                    reason: "",
                    link: "",
                    links: [],
                    sources: [dataSource],
                    number_of_people: aiEnrichRequest?.number_of_people,
                    titles: aiEnrichRequest?.titles,
                    locations: aiEnrichRequest?.locations,
                    seniorities: aiEnrichRequest?.seniorities,
                    enrichments: aiEnrichRequest?.enrichments,
                    is_enrich_col: true,
                    is_people_col: false,
                    user_generated: true,
                    records: [],
                  });
                  setVisibleColumns((prev) => [
                    ...prev,
                    `${aiEnrichRequest?.column_name} - ${
                      enrichment === 1
                        ? "Name"
                        : enrichment === 2
                        ? "Email"
                        : enrichment === 3
                        ? "LinkedIn"
                        : "Phone"
                    } ${i + 1}`,
                  ]);
                });
              }
              setHeaders((prevHeaders) => [...prevHeaders, ...newHeaders]);
              setVisibleColumns((prev) => [
                ...prev,
                aiEnrichRequest?.column_name as string,
              ]);
            }
          }
          console.log(aiEnrichResponse);
          console.log(aiEnrichResponse);
          const updatedData = filteredData.map((record) => {
            const companyName = record["Company Name"];
            if (companyName && aiEnrichResponse[companyName]) {
              // Update the record with the enrichment data only if the company name is found in aiEnrichResponse
              const enrichmentData = aiEnrichResponse[companyName];
              return {
                ...record,
                [aiEnrichRequest?.column_name as string]: enrichmentData, // Assuming enrichmentData is not an array
              };
            }
            // Return the record unchanged if the company name is not found
            return record;
          });

          console.log(updatedData);

          setFilteredData((prev) => {
            // Filter out any records in `prev` that have the same ID as one in `rowsAdded`
            const filteredPrev = prev.filter(
              (item) => !rowsAdded.some((added) => added.id === item.id)
            );

            // Add the `updatedData` to the filtered previous data
            return [...filteredPrev, ...updatedData];
          });
          // handleRefresh();

          setShowRunPop(false);

          setShowAIEnrichProgress(false);
          setShowAIEnrichment(false);
          setSelectedRows([]);
          if (rowCount > 10) {
            updateNextCount(aiEnrichRequest?.column_name as string, 100);
          } else {
            updateNextCount(aiEnrichRequest?.column_name as string, 10);
          }
          setAiEnrichRequest(null);
          // handleRefresh();

          // setNewCols([]);
        }
      } catch (error: any) {
        setShowRunPop(false);
        setAiEnrichRequest(null);
        setShowAIEnrichProgress(false);
        setShowAIEnrichment(false);
        //   setNewCols([]);
        toast.error(error.message);
      }
    }
  };

  const runSingleAiEnrich = async (header: any, row: any) => {
    if (!header.is_people_col) {
      try {
        const companies = [row].map((item, index) => {
          return item["Company Name"];
        });

        setAiEnrichRequest(header);
        handleRowSelect(row);
        setAnchorEl(null);
        setAnchorElRun(null);
        setShowAIEnrichProgress(true);

        const aiEnrichResponse = await researchAiEnrichment({
          project_id: projectId,
          column_name: header?.column_name as string,
          // records: rowsAdded,
          answer_format: header?.answer_format as string,
          prompt: header?.prompt as string,
          sources: header?.sources as number[],
          companies: companies as string[],
        });
        setCredits(aiEnrichResponse.credits_left);
        setNewCols(
          newCols.filter((co) => co.column_name !== header?.column_name)
        );

        if (
          headers.filter((h) => h.column_name === header?.column_name)
            .length === 0
        ) {
          setHeaders((prev) => [
            ...prev,
            {
              project_id: projectId,
              column_name: header?.column_name as string,
              records: [],
              answer_format: header?.answer_format as string,
              prompt: header?.prompt as string,
              answer: "",
              reason: "",
              link: "",
              user_generated: true,
              sources: header?.sources as number[],
              links: companies as string[],
            },
          ]);
        }

        setVisibleColumns((prev) => [...prev, header?.column_name as string]);
        const updatedData = filteredData.map((record) => {
          const newRecord = aiEnrichResponse.records.find(
            (newRec) => newRec["Company Name"] === record["Company Name"]
          );
          return newRecord ? newRecord : record;
        });
        // console.log(updatedData);
        setFilteredData(updatedData);
        // handleRefresh();

        setAiEnrichRequest(null);
        setShowAIEnrichProgress(false);
        setSelectedRows([]);
      } catch (error: any) {
        setShowAIEnrichProgress(false);
        setSelectedRows([]);
        //   setNewCols([]);
        toast.error(error.message);
      }
    } else {
      try {
        const companies = [row].map((item, index) => {
          return item["Company Name"];
        });
        handleRowSelect(row);
        console.log("enrichRequest", header);
        setCurrentId(header?.column_name as string);
        setAnchorEl(null);
        setAnchorElRun(null);
        setAiEnrichRequest(header);
        setShowAIEnrichProgress(true);
        const dataSpliced = selectedRows;

        const aiEnrichResponse = await researchFindPeople({
          project_id: projectId,
          column_name: header?.column_name as string,
          // records: rowsAdded,
          answer_format: header?.answer_format as string,
          prompt: header?.prompt as string,
          sources: header?.sources as number[],
          titles: header?.titles,
          seniorities: header?.seniorities,
          locations: header?.locations,
          number_of_people: header?.number_of_people,
          enrichments: header?.enrichments,
          companies: companies as string[],
          links: [],
        });
        setCredits(aiEnrichResponse.credits_left);
        setNewCols(
          newCols.filter(
            (co) =>
              !co.column_name.includes(header?.column_name + " - ") &&
              co.column_name !== header?.column_name
          )
        );

        if (
          headers.filter((h) => h.column_name === header?.column_name)
            .length === 0
        ) {
          if (header?.number_of_people) {
            var newHeaders: NewCol[] = [];
            newHeaders.push({
              column_name: `${header.column_name}`,
              project_id: projectId,
              prompt: header.prompt,
              answer_format: header.answer_format,
              answer: "",
              reason: "",
              link: "",
              links: [],
              sources: [dataSource],
              number_of_people: header?.number_of_people,
              titles: header?.titles,
              locations: header?.locations,
              seniorities: header?.seniorities,
              enrichments: header?.enrichments,
              is_enrich_col: false,
              is_people_col: true,
              user_generated: true,
              records: [],
            });
            for (let i = 0; i < header?.number_of_people; i++) {
              // Main column for the person

              // Enrichment columns for the person
              header?.enrichments?.forEach((enrichment: any, idx: any) => {
                newHeaders.push({
                  column_name: `${header.column_name} - ${
                    enrichment === 1
                      ? "Name"
                      : enrichment === 2
                      ? "Email"
                      : enrichment === 3
                      ? "LinkedIn"
                      : "Phone"
                  } ${i + 1}`,
                  project_id: projectId,
                  prompt: header.prompt,
                  answer_format: header.answer_format,
                  answer: "",
                  reason: "",
                  link: "",
                  links: [],
                  sources: [dataSource],
                  number_of_people: header?.number_of_people,
                  titles: header?.titles,
                  locations: header?.locations,
                  seniorities: header?.seniorities,
                  enrichments: header?.enrichments,
                  is_enrich_col: true,
                  is_people_col: false,
                  user_generated: true,
                  records: [],
                });
                setVisibleColumns((prev) => [
                  ...prev,
                  `${header.column_name} - ${
                    enrichment === 1
                      ? "Name"
                      : enrichment === 2
                      ? "Email"
                      : enrichment === 3
                      ? "LinkedIn"
                      : "Phone"
                  } ${i + 1}`,
                ]);
              });
            }
            setHeaders((prevHeaders) => [...prevHeaders, ...newHeaders]);
            setVisibleColumns((prev) => [
              ...prev,
              header?.column_name as string,
            ]);
          }
        }

        const updatedData = filteredData.map((record) => {
          const companyName = record["Company Name"];
          if (companyName && aiEnrichResponse[companyName]) {
            // Update the record with the enrichment data
            const enrichmentData = aiEnrichResponse[companyName];
            return {
              ...record,
              [header?.column_name as string]: enrichmentData,
            };
          }
          return record;
        });

        setFilteredData(updatedData);
        // handleRefresh();

        setShowRunPop(false);

        setShowAIEnrichProgress(false);
        setShowAIEnrichment(false);
        setSelectedRows([]);

        setAiEnrichRequest(null);
        // handleRefresh();

        // setNewCols([]);
      } catch (error: any) {
        setShowRunPop(false);
        setAiEnrichRequest(null);
        setShowAIEnrichProgress(false);
        setShowAIEnrichment(false);
        //   setNewCols([]);
        toast.error(error.message);
      }
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDisplayedData = () => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredData.slice(start, end);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const CustomDropdownIcon = () => {
    return (
      <KeyboardArrowDown
        sx={{
          color: "#6A7383",
        }}
      /> // Replace this with your custom icon
    );
  };

  useEffect(() => {
    if (updateLocalStorage) {
      updateColumn();
    }
    setUpdateLocalStorage(false);
  }, [updateLocalStorage]);

  const handleSearch = (searchTerm: string) => {
    if (searchTerm.trim().length === 0) {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((d) =>
          d.company.name.toLowerCase().trim().includes(searchTerm.toLowerCase())
        )
      );
    }
  };

  const [anchorElProject, setAnchorElProject] = useState(null);
  const openProject = Boolean(anchorElProject);

  const handleOpenPopover = (event: any) => {
    setAnchorElProject(event.currentTarget);
  };
  const handleCloseProjectPop = () => {
    setAnchorElProject(null);
  };

  const handleCloseAiEnrichPopover = () => {
    setAnchorElRun(null);
    setAnchorEl(null);
    setAnchorElAiEnrich(null);
    setAnchorElPeople(null);
    setShowAIEnrichment(false);
    setShowRunPop(false);
  };

  const updateColumnName = (oldColumnName: string, newColumnName: string) => {
    setNewCols((prevCols) =>
      prevCols.map((col) =>
        col.column_name === oldColumnName
          ? { ...col, column_name: newColumnName }
          : col
      )
    );
    setShowColumnDetailPop(false);
    setAnchorElColumnDetail(null);
    // updateColumnService(projectId, oldColumnName, newColumnName)
    //   .then(() => {
    //     const updatedHeaders = headers.map((header) => {
    //       if (header.column_name === oldColumnName) {
    //         return { ...header, column_name: newColumnName };
    //       }
    //       return header;
    //     });

    //     setHeaders(updatedHeaders);
    //     const updatedData = filteredData.map((record) => {
    //       const newRecord = { ...record };
    //       if (record[oldColumnName] !== undefined) {
    //         newRecord[newColumnName] = newRecord[oldColumnName];
    //         delete newRecord[oldColumnName];
    //       }
    //       return newRecord;
    //     });
    //     setFilteredData(updatedData);
    //     toast.success("Column updated successfully");
    //   })
    //   .catch((e: any) => {
    //     toast.error(e.message);
    //   });
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const handleRowHover = (index: number | null) => {
    setHoveredRowIndex(index);
  };

  const handleCloseAiEnrichmentColumnPopover = () => {};

  const handleSaveColumnName = async () => {
    try {
      const links = filteredData.map((item, index) => {
        return item.link;
      });
      if (
        columnName.trim().length === 0 ||
        requestPrompt.trim().length === 0 ||
        answerFormat.trim().length === 0
      ) {
        toast.error("All fields are mandatory");
      } else {
        setLoading(true);

        setNewCols([
          ...newCols.filter((c) => c.column_name !== columnName),
          {
            column_name: columnName,
            project_id: projectId,
            prompt: requestPrompt,
            answer_format: answerFormat,
            answer: "",
            reason: "",
            link: "",
            links: links,
            sources: [dataSource],
            user_generated: true,
          },
        ]);
        console.log("the new columns are :", columnName);
        setAnchorElAiEnrich(null);
        setAnchorEl(null);
        setShowAIEnrichment(false);
        setLoading(false);
        setColumnName("");
        setRequestPrompt("");
        setAnswerFormat("Yes/No");
        setUpdateLocalStorage(true);
      }
    } catch (error) {
      setLoading(true);
      toast.error(`Failed to enrich column with AI ${error}`);
    }
  };

  const handleSavePeopleColumnName = async (
    numPeople: number,
    location: string,
    seniority: string[],
    title: string,
    enrichments: number[],
    columnName: string
  ) => {
    try {
      setLoading(true);

      const newColumns = [];
      newColumns.push({
        column_name: `${columnName}`,
        project_id: projectId,
        prompt: requestPrompt,
        answer_format: answerFormat,
        answer: "",
        reason: "",
        link: "",
        links: [],
        sources: [dataSource],
        number_of_people: numPeople,
        titles: [title],
        locations: [location],
        seniorities: seniority,
        enrichments: enrichments,
        is_enrich_col: false,
        is_people_col: true,
        user_generated: true, // Main column, not an enrichment column
      });
      for (let i = 0; i < numPeople; i++) {
        // Main column for the person

        // Enrichment columns for the person
        enrichments.forEach((enrichment, idx) => {
          newColumns.push({
            column_name: `${columnName} - ${
              enrichment === 1
                ? "Name"
                : enrichment === 2
                ? "Email"
                : enrichment === 3
                ? "LinkedIn"
                : "Phone"
            } ${i + 1}`,
            project_id: projectId,
            prompt: requestPrompt,
            answer_format: answerFormat,
            answer: "",
            reason: "",
            link: "",
            links: [],
            sources: [dataSource],
            number_of_people: numPeople,
            titles: [title],
            locations: [location],
            seniorities: seniority,
            enrichments: enrichments,
            is_enrich_col: true,
            is_people_col: false,
            user_generated: true,
          });
        });
      }

      setNewCols([
        ...newCols.filter((c) => c.column_name !== columnName),
        ...newColumns,
      ]);

      setAnchorEl(null);
      setShowAIEnrichment(false);
      setPeoplePopoverAnchor(null);
      setAnchorElPeople(null);
      setLoading(false);
      setColumnName("");
      setRequestPrompt("");
      setAnswerFormat("Yes/No");
      setUpdateLocalStorage(true);
    } catch (error) {
      setLoading(false);
      toast.error(`Failed to enrich column with AI: ${error}`);
    }
  };

  const [anchorFilter, setAnchorFilter] = useState<null | HTMLElement>(null);

  const handleToggleColumn = (key: string) => {
    setVisibleColumns((prev) =>
      prev.includes(key) ? prev.filter((col) => col !== key) : [...prev, key]
    );
  };

  const getTitle = (row: any, header: any) => {
    var notKeysTitle: any = "";

    if (header.enrichments) {
      if (header.is_enrich_col) {
        const enrichmentKey = header.column_name.split(" - ")[0]?.trim();
        let enrichmentKeyCol = "";
        let enrichmentIndex = 0; // Default to the first index

        // Extract the column type (name, email, etc.)
        enrichmentKeyCol = header.column_name
          .split(" - ")[1]
          ?.trim()
          .toLowerCase();

        // Determine the index number based on the column name (e.g., "Manager Name - 2")
        const match = enrichmentKeyCol.match(/\d+/);

        if (match) {
          enrichmentIndex = parseInt(match[0], 10) - 1; // Convert to zero-based index
        }
        // console.log(enrichmentKeyCol, row[enrichmentKey][enrichmentIndex]);
        // Standardize the enrichment type (name, email, phone, linkedin)
        if (enrichmentKeyCol.includes("name")) {
          enrichmentKeyCol = "name";
        } else if (enrichmentKeyCol.includes("email")) {
          enrichmentKeyCol = "email";
        } else if (enrichmentKeyCol.includes("phone")) {
          enrichmentKeyCol = "phone_number";
        } else if (enrichmentKeyCol.includes("linkedin")) {
          enrichmentKeyCol = "linkedin_url";
        }

        // Access the specific index in the enrichment array

        if (
          enrichmentKey &&
          row[enrichmentKey] &&
          Array.isArray(row[enrichmentKey]) &&
          row[enrichmentKey][enrichmentIndex] &&
          enrichmentKeyCol
        ) {
          notKeysTitle =
            row[enrichmentKey][enrichmentIndex][enrichmentKeyCol] ||
            "Not found";
        } else {
          notKeysTitle = "";
        }
      } else {
        const enrichmentColumn = row[header.column_name];
        if (enrichmentColumn) {
          notKeysTitle = (
            <div
              style={{
                fontSize: 12,
                display: "flex",
                gap: 4,
                alignItems: "center",
              }}>
              <img
                src={matchNotFound}
                style={{
                  height: 12,
                  width: 12,
                }}
              />{" "}
              Match Not Found
            </div>
          );
          for (let index = 0; index < enrichmentColumn.length; index++) {
            const element = enrichmentColumn[index];
            if (
              Object.values(element).some(
                (value) => value !== "" && value !== "Not found"
              )
            ) {
              notKeysTitle = (
                <div
                  style={{
                    fontSize: 12,
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                  }}>
                  <img
                    src={matchFound}
                    style={{
                      height: 12,
                      width: 12,
                    }}
                  />{" "}
                  Match Found
                </div>
              );
              break;
            }
          }
        } else {
          notKeysTitle = "";
        }
      }
    } else if (row[header.column_name] && row[header.column_name].length) {
      notKeysTitle =
        row[header.column_name][0].answer && row[header.column_name][0].answer;
    } else if (row[header.column_name] && row[header.column_name].answer) {
      // console.log(row[header.column_name]);
      notKeysTitle =
        row[header.column_name].answer && row[header.column_name].answer;
    }
    return notKeysTitle;
  };

  const getTitleValue = (row: any, header: any): string => {
    const value = row[header.column_name];

    if (header.enrichments) {
      return getTitle(row, header);
    }

    if (typeof value !== "object") {
      return value || "";
    }

    if (Array.isArray(value) && value.length > 0) {
      const answer = value[0]?.answer;
      return answer && answer.length > 0 ? answer : "Not found";
    }

    if (value && value.answer) {
      return value.answer.length > 0 ? value.answer : "Not found";
    }

    return "";
  };

  const logHeader = (row: any) => {
    console.log(row);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingRight: 3,
        paddingLeft: 3,
        width: 600,
        flex: 4,
        height: "100vh",
      }}>
      <CssBaseline />
      <Toolbar
        disableGutters
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: 0,
          margin: 0,
        }}>
        <Box padding={0} display='flex' alignItems='center' gap={1}>
          <IconButton
            onClick={() => {
              onClose();
            }}>
            <ArrowBackIos />
          </IconButton>
          <Typography variant='h1' fontSize={24}>
            {title}
          </Typography>
          {/* <IconButton onClick={handleOpenPopover}>
            <img src={elipsIcon} />
          </IconButton> */}
        </Box>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          gap={3}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              border: "1px solid #E5E5E5",
              padding: 1,
              borderRadius: 2,
              gap: 2,
            }}>
            <input
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              style={{
                outline: "none",
                border: "none",
                width: "100%",
                fontSize: 14,
                fontFamily: "Gilroy-Medium",
              }}
              placeholder='Search'
            />
            <Box display='flex' gap={1} alignItems='center'>
              <div
                style={{
                  width: 1,
                  padding: 1,
                  backgroundColor: theme.palette.primary.light,
                  height: 16,
                }}></div>
              <Search
                sx={{ color: "#9EA7B5", marginRight: 0 }}
                fontSize='small'
              />
            </Box>
          </Box>
        </Box>
      </Toolbar>
      <Divider />
      <Box display='flex' flexDirection='column'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center' justifyContent='flex-start'>
            <IconButton onClick={handleRefresh}>
              <Cached />
            </IconButton>
            <Typography typography='h3' fontFamily='Gilroy-Semibold'>
              Refresh
            </Typography>
          </Box>

          <Box
            display='flex'
            alignItems='center'
            gap={2}
            marginRight={2}
            justifyContent='flex-end'>
            <Typography color='#6A7383' variant='h3' fontFamily='Gilroy-Medium'>
              {selectedRows.length}/{filteredData.length} rows selected
            </Typography>
            <Box
              sx={{
                height: 18,
                width: 2,
                backgroundColor: theme.palette.primary.light,
              }}
            />
            <Box
              display='flex'
              alignItems='center'
              gap={1}
              sx={{
                cursor: "pointer",
              }}>
              <img src={pauseIcon} height={16} width={16} />
              <Typography
                variant='h3'
                fontFamily='Gilroy-Semibold'
                color={theme.palette.primary.main}>
                {headers.length + newCols.length}/
                {headers.length + newCols.length} Columns
              </Typography>
              <IconButton onClick={(e) => setAnchorFilter(e.currentTarget)}>
                <KeyboardArrowDownRounded />
              </IconButton>
            </Box>
            <Box
              sx={{
                height: 18,
                width: 2,
                backgroundColor: theme.palette.primary.light,
              }}
            />
            <Box display='flex' alignItems='center'>
              <Switch color='secondary' />
              <Typography fontFamily='Gilroy-Semibold' variant='h3'>
                Auto update
              </Typography>
            </Box>
          </Box>
          <HeaderFilter
            handleClose={() => {
              setAnchorFilter(null);
            }}
            anchorEl={anchorFilter}
            headers={headers}
            visibleColumns={visibleColumns}
            onToggleColumn={handleToggleColumn}
          />
        </Box>
      </Box>
      <Divider />
      {showTableLoader ? (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'>
          <BurstLoader />
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, overflowX: "auto" }}>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 140px)", // Adjust height to fit the available space
              overflow: "auto",
              boxShadow: "none",
            }}>
            <Table
              stickyHeader={false}
              sx={{
                minWidth: 1000,
                tableLayout: "fixed",
                "& .MuiTableCell-sizeMedium": {
                  padding: "10px 16px",
                },
              }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: "#757575",
                      width: 60,

                      backgroundColor: "white",

                      borderRight: `1px solid #F0F0F0`,
                    }}>
                    <Checkbox
                      onChange={(v) => {
                        if (selectedRows.length === filteredData.length) {
                          setSelectedRows([]);
                        } else {
                          handleRowSelectAll();
                        }
                      }}
                      checked={colChecked}
                      sx={{
                        padding: 0,
                        margin: 0,
                      }}
                    />
                  </TableCell>
                  {/* <TableCell
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#757575",
                    width: 60,
                    backgroundColor: "white",
                    borderRight: `1px solid #F0F0F0`,
                  }}
                >
                  ID
                </TableCell> */}

                  {headers.map(
                    (header, idx) =>
                      visibleColumns.includes(header.column_name) && (
                        <>
                          {header.user_generated ? (
                            <TableCell
                              key={idx}
                              sx={{
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: "#757575",
                                fontFamily: "Gilroy-Semibold",
                                fontSize: 14,
                                width: 200,
                                borderRight: `1px solid #F0F0F0`,
                              }}>
                              <Box
                                width={"100%"}
                                display='flex'
                                alignItems='center'
                                gap={1}>
                                <img src={astrix} height={14} width={14} />
                                <span
                                  onClick={(e: any) => {
                                    if (
                                      !header.is_enrich_col &&
                                      !header.is_people_col
                                    ) {
                                      setAiEnrichRequest(header);
                                      setAnchorElColumnDetail(e.currentTarget);
                                    }
                                    // setColumnName(header.column_name);
                                    // setAiEnrichRequest(header);
                                  }}
                                  style={{
                                    color: "#757575",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    // width: 20,
                                    textAlign: "start",

                                    fontFamily: "Gilroy-Semibold",
                                    fontSize: 14,
                                  }}>
                                  {header.column_name}
                                </span>
                                {!header.is_enrich_col && (
                                  <Button
                                    startIcon={<PlayArrow />}
                                    sx={{
                                      backgroundColor: "#F4F4F4",
                                      borderRadius: 50,
                                      paddingRight: 2,
                                      paddingLeft: 2,
                                      paddingTop: 0.4,
                                      paddingBottom: 0.4,
                                    }}
                                    onClick={(e: any) => {
                                      setColumnName(header.column_name);
                                      setAiEnrichRequest(header);
                                      setShowRunPop(true);
                                      setAnchorElRun(e.currentTarget);
                                    }}
                                    variant='text'
                                    color='secondary'>
                                    Run
                                  </Button>
                                )}
                              </Box>
                            </TableCell>
                          ) : (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#757575",
                                borderRight: `1px solid #F0F0F0`,
                                width: 180,
                                whiteSpace: "nowrap",
                                fontFamily: "Gilroy-Semibold",
                                position: idx === 0 ? "sticky" : "",
                              }}>
                              {header.column_name}
                            </TableCell>
                          )}
                        </>
                      )
                  )}
                  {newCols.map((header, idx) => {
                    return (
                      <TableCell
                        key={idx}
                        sx={{
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          overflow: "hidden",

                          textOverflow: "ellipsis",
                          color: "#757575",
                          fontFamily: "Gilroy-Semibold",
                          fontSize: 14,
                          width: 200,
                          borderLeft: `1px solid #F0F0F0`,
                        }}>
                        <Box
                          width={"100%"}
                          display='flex'
                          alignItems='center'
                          gap={1}>
                          <img src={astrix} height={14} width={14} />
                          <span
                            onClick={(e: any) => {
                              if (
                                !header.is_enrich_col &&
                                !header.is_people_col
                              ) {
                                setAiEnrichRequest(header);
                                setAnchorElColumnDetail(e.currentTarget);
                              }
                              // setColDetailEl(e.currentTarget);
                              // setShowColumnDetailPop(true);
                            }}
                            style={{
                              color: "#757575",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              // width: 20,

                              fontFamily: "Gilroy-Semibold",
                              fontSize: 14,
                              textAlign: "start",
                            }}>
                            {header.column_name}
                          </span>
                          {!header.is_enrich_col && (
                            <Button
                              startIcon={<PlayArrow />}
                              sx={{
                                backgroundColor: "#F4F4F4",
                                borderRadius: 50,
                                paddingRight: 2,
                                paddingLeft: 2,
                                paddingTop: 0.4,
                                paddingBottom: 0.4,
                              }}
                              onClick={(e: any) => {
                                setColumnName(header.column_name);
                                setAiEnrichRequest(header);
                                setAnchorElRun(e.currentTarget);
                                setShowRunPop(true);
                              }}
                              variant='text'
                              color='secondary'>
                              Run
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                    );
                  })}
                  <TableCell
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: "#757575",
                      // borderRight: `1px solid #F0F0F0`,
                      borderLeft: `1px solid #F0F0F0`,
                      width: 180,
                      whiteSpace: "nowrap",

                      fontFamily: "Gilroy-Semibold",

                      cursor: "pointer",
                    }}
                    onClick={handleClick}>
                    <Box display='flex' alignItems='center' gap={1}>
                      <Add />
                      <Typography
                        fontWeight={600}
                        variant='h3'
                        color={theme.palette.primary.main}>
                        Add Column
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getDisplayedData().map((row, rowIdx) => (
                  <TableRow
                    selected={selectedRows.includes(row)}
                    key={rowIdx}
                    onMouseEnter={() => handleRowHover(rowIdx)}
                    onMouseLeave={() => handleRowHover(null)}
                    hover>
                    <TableCell
                      sx={{
                        width: 180,
                        whiteSpace: "nowrap",
                        borderRight: `1px solid #F0F0F0`,

                        // backgroundColor:  ,
                      }}>
                      <Box>
                        <Checkbox
                          onChange={(v) => {
                            handleRowSelect(row as ProjectRecord);
                          }}
                          key={rowIdx}
                          checked={selectedRows.includes(row)}
                          sx={{
                            padding: 0,
                          }}
                        />
                      </Box>
                    </TableCell>
                    {/* <TableCell
                    key={rowIdx}
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: "#757575",
                      width: 60,
                      backgroundColor: "",
                      borderRight: `1px solid #F0F0F0`,
                    }}
                  >
                    {rowIdx + 1}
                  </TableCell> */}
                    {headers.map(
                      (header, idx) =>
                        visibleColumns.includes(header.column_name) && (
                          <>
                            {!header.user_generated ? (
                              <TableCell
                                key={idx}
                                sx={{
                                  borderLeft: "1px solid #F0F0F0",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  minWidth: 180, // Ensure minimum width for horizontal scroll
                                  maxWidth: 200, // Optionally set a maximum width
                                  // backgroundColor: "white",
                                }}>
                                <Tooltip
                                  title={
                                    header.enrichments
                                      ? "Not found"
                                      : typeof row[header.column_name] !==
                                        "object"
                                      ? row[header.column_name]
                                      : row[header.column_name].length
                                      ? row[header.column_name][0].answer
                                      : row[header.column_name].answer
                                      ? row[header.column_name].answer
                                      : ""
                                  }>
                                  {header.enrichments
                                    ? "Not found"
                                    : typeof row[header.column_name] !==
                                      "object"
                                    ? row[header.column_name]
                                    : row[header.column_name].length
                                    ? row[header.column_name][0].answer
                                    : row[header.column_name].answer
                                    ? row[header.column_name].answer
                                    : ""}
                                </Tooltip>
                              </TableCell>
                            ) : (
                              <TableCell
                                key={idx}
                                sx={{
                                  borderLeft: "1px solid #F0F0F0",

                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",

                                  width: 200,
                                }}>
                                {showAIEnrichProgress &&
                                selectedRows.includes(row) &&
                                aiEnrichRequest?.column_name ===
                                  header.column_name ? (
                                  <Box
                                    width='100%'
                                    display='flex'
                                    alignItems='center'
                                    gap={1}
                                    justifyContent='center'>
                                    <BurstLoader fontSize='16px' />
                                    <Typography
                                      variant='h4'
                                      fontFamily='Gilroy-Medium-Italic'
                                      color='#ADADAD'>
                                      Loading...
                                    </Typography>
                                  </Box>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}>
                                    <Typography
                                      display='flex'
                                      alignItems='center'
                                      justifyContent='space-between'
                                      onClick={(e: any) => {
                                        if (row[header.column_name]) {
                                          if (!header.is_enrich_col) {
                                            if (
                                              row[header.column_name].length
                                            ) {
                                              setSelectedHeader(
                                                row[header.column_name][0]
                                              );
                                              setShowPromptDetail(true);
                                              setColDetailEl(e.currentTarget);
                                            } else {
                                              setSelectedHeader(
                                                row[header.column_name]
                                              );
                                              setShowPromptDetail(true);
                                              setColDetailEl(e.currentTarget);
                                            }
                                          }
                                        }
                                      }}>
                                      {getTitleValue(row, header)}&nbsp;
                                    </Typography>
                                    {rowIdx === hoveredRowIndex &&
                                      header.user_generated &&
                                      !header.is_enrich_col && (
                                        <Button
                                          startIcon={<PlayArrow />}
                                          sx={{
                                            backgroundColor: "#F4F4F4",
                                            borderRadius: 50,
                                            paddingRight: 2,
                                            paddingLeft: 2,
                                            paddingTop: 0.4,
                                            paddingBottom: 0.4,
                                          }}
                                          onClick={(e: any) => {
                                            runSingleAiEnrich(header, row);
                                          }}
                                          variant='text'
                                          color='secondary'>
                                          Run
                                        </Button>
                                      )}
                                  </div>
                                )}
                              </TableCell>
                            )}
                          </>
                        )
                    )}
                    {newCols.map((header, idx) => {
                      return (
                        <>
                          {!header.user_generated ? (
                            <TableCell
                              key={idx}
                              sx={{
                                borderLeft: "1px solid #F0F0F0",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                minWidth: 180, // Ensure minimum width for horizontal scroll
                                maxWidth: 200, // Optionally set a maximum width
                                // backgroundColor: "white",
                              }}>
                              <Tooltip
                                title={
                                  typeof row[header.column_name] !== "object"
                                    ? row[header.column_name]
                                    : row[header.column_name].length
                                    ? row[header.column_name][0].answer
                                    : row[header.column_name].answer
                                    ? row[header.column_name].answer
                                    : "not found"
                                }>
                                {typeof row[header.column_name] !== "object"
                                  ? row[header.column_name]
                                  : row[header.column_name].length
                                  ? row[header.column_name][0].answer
                                  : row[header.column_name].answer
                                  ? row[header.column_name].answer
                                  : "not found"}
                              </Tooltip>
                            </TableCell>
                          ) : (
                            <TableCell
                              key={idx}
                              sx={{
                                borderLeft: "1px solid #F0F0F0",

                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",

                                width: 200,
                              }}>
                              {showAIEnrichProgress &&
                              selectedRows.includes(row) &&
                              aiEnrichRequest?.column_name ===
                                header.column_name ? (
                                <Box
                                  width='100%'
                                  display='flex'
                                  alignItems='center'
                                  gap={1}
                                  justifyContent='center'>
                                  <BurstLoader fontSize='16px' />
                                  <Typography
                                    variant='h4'
                                    fontFamily='Gilroy-Medium-Italic'
                                    color='#ADADAD'>
                                    Loading...
                                  </Typography>
                                </Box>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}>
                                  <Typography
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='space-between'
                                    width='100%'
                                    onClick={(e: any) => {
                                      if (row[header.column_name]) {
                                        if (!header.is_enrich_col) {
                                          if (row[header.column_name].length) {
                                            setSelectedHeader(
                                              row[header.column_name][0]
                                            );
                                            setShowPromptDetail(true);
                                            setColDetailEl(e.currentTarget);
                                          } else {
                                            setSelectedHeader(
                                              row[header.column_name]
                                            );
                                            setShowPromptDetail(true);
                                            setColDetailEl(e.currentTarget);
                                          }
                                        }
                                      }
                                    }}>
                                    {getTitle(row, header)}&nbsp;
                                  </Typography>
                                  {rowIdx === hoveredRowIndex &&
                                    header.user_generated &&
                                    !header.is_enrich_col && (
                                      <Button
                                        startIcon={<PlayArrow />}
                                        sx={{
                                          backgroundColor: "#F4F4F4",
                                          borderRadius: 50,
                                          paddingRight: 2,
                                          paddingLeft: 2,
                                          paddingTop: 0.4,
                                          paddingBottom: 0.4,
                                        }}
                                        onClick={(e: any) => {
                                          runSingleAiEnrich(header, row);
                                        }}
                                        variant='text'
                                        color='secondary'>
                                        Run
                                      </Button>
                                    )}
                                </div>
                              )}
                            </TableCell>
                          )}
                        </>
                      );
                    })}
                    <TableCell
                      sx={{ borderLeft: "1px solid #F0F0F0" }}></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <AiEnrichPopover
            anchorEl={anchorEl}
            handleClose={handleCloseAiEnrichPopover}
            setShowAIEnrichment={setAnchorElAiEnrich}
            setPeoplePopoverAnchor={setAnchorElPeople}
          />
          <AiEnrichmentColumnPopover
            anchorEl={anchorElAiEnrich}
            handleClose={handleCloseAiEnrichPopover}
            setColumnName={setColumnName}
            setRequestPrompt={setRequestPrompt}
            dataSource={dataSource}
            setDataSource={setDataSource}
            setAnswerFormat={setAnswerFormat}
            loading={loading}
            handleSaveColumnName={handleSaveColumnName}
          />
          <AddPeoplePopOver
            anchorEl={anchorElPeople}
            handleSaveColumnName={handleSavePeopleColumnName}
            handleClosePopover={handleCloseAiEnrichPopover}
          />
          <RunPopover
            anchorEl={anchorElRun}
            handleClose={handleCloseAiEnrichPopover}
            nextCounts={columnNextCount}
            filteredData={filteredData}
            setSelectedRows={setSelectedRows}
            runCsvEnrich={runCsvEnrich}
            setNextCounts={setColumnNextCount}
            aiEnrichRequest={aiEnrichRequest}
            currentId={currentId}
            selectedRows={selectedRows}
          />
          <ColumnDetailPopover
            anchorEl={anchorElColumnDetail}
            setShowColumnDetailPop={setShowColumnDetailPop}
            setAnchorEl={setAnchorElColumnDetail}
            setColumnName={setColumnName}
            loading={loading}
            setNewCols={setNewCols}
            newCols={newCols}
            setUpdateLocalStorage={setUpdateLocalStorage}
            updateColumnName={updateColumnName}
            aiEnrichRequest={aiEnrichRequest}
            projectId={projectId}
            headers={headers}
            deleteColumn={async (project_id: string, column: string) => {
              await deleteColumn(project_id, column);
              setUpdateLocalStorage(true);
            }}
            setHeaders={setHeaders}
            columnName={columnName}
          />
          {showPromptDetail && (
            <Popover
              id={showPromptDetail ? "cols-pop" : undefined}
              open={showPromptDetail}
              anchorEl={colDetailEl}
              onClose={() => {
                setShowPromptDetail(false);
                setColDetailEl(null);
                setSelectedHeader(null);
              }}
              sx={{
                borderRadius: 2,
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}>
              <Box
                p={2}
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                width={450}
                justifyContent='flex-start'>
                <Box sx={{ mt: 2, width: "100%" }}>
                  <Box
                    sx={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      display: "flex",
                    }}>
                    <Typography
                      typography='h3'
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      fontWeight={600}>
                      Response
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setShowPromptDetail(false);
                        setColDetailEl(null);
                        setSelectedHeader(null);
                      }}>
                      <Close></Close>
                    </IconButton>
                  </Box>

                  <TextField
                    fullWidth
                    //   label='Enter link'
                    placeholder='Headquarters'
                    multiline
                    variant='outlined'
                    disabled
                    value={selectedHeader.answer || "Not found"}
                    sx={{
                      mt: 2,
                      padding: 0,
                    }}
                  />
                </Box>

                <Box sx={{ mt: 2, width: "100%" }}>
                  <Typography
                    typography='h3'
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                    fontWeight={600}>
                    Reason
                  </Typography>

                  <Box
                    sx={{
                      mt: 1,
                      padding: 2,
                      width: "100%",
                      borderRadius: 2,
                      bgcolor: "#FAF9F8",
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      gap: 2,
                    }}
                    width={"100%"}>
                    <Typography
                      typography='h3'
                      color='#666666'
                      fontWeight={600}>
                      {selectedHeader.reason}
                    </Typography>
                    <a
                      target='_blank'
                      style={{
                        marginTop: 4,
                        color: theme.palette.primary.main,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 600,
                        gap: 1,
                      }}
                      href={selectedHeader.link || ""}>
                      Proof link{" "}
                      <LaunchOutlined
                        fontSize='small'
                        sx={{
                          color: "#ADADAD",
                        }}
                      />
                    </a>
                  </Box>
                </Box>
              </Box>
            </Popover>
          )}
        </Box>
      )}

      <Box display='flex' flexDirection='column' marginTop='auto'>
        <Divider sx={{}} />
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            padding: 0.5,
          }}>
          <Box display='flex' alignItems='center' justifyContent='flex-start'>
            <Typography typography='h3'>View</Typography>
            <TextField
              select
              variant='outlined'
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              SelectProps={{
                native: true,
                IconComponent: CustomDropdownIcon,
              }}
              inputProps={{
                style: {
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingRight: 4,
                  paddingLeft: 4,
                  textAlign: "start",
                  marginRight: 0,
                  color: "#6A7383",
                },
              }}
              sx={{
                padding: 1,
              }}>
              {[10, 20, 50, 100].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <Typography typography='h3'>rows per page</Typography>
          </Box>

          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            <Typography color='#6A7383'>
              Showing {filteredData.length > 0 ? page + 1 : 0}-
              {Math.min((page + 1) * rowsPerPage, filteredData.length)} of{" "}
              {filteredData.length}
            </Typography>

            <Pagination
              totalPages={totalPages}
              currentPage={page}
              handlePageChange={handleChangePage}
            />
          </Box>
        </Box>
        <Divider sx={{}} />
      </Box>
    </Box>
  );
};

export default MyTable;
