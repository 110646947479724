import { useState } from "react";
import { ProjectRecord } from "../../service/listProject";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import theme from "../../theme";

interface RenameDialogProps {
  open: boolean;
  selectedRow: ProjectRecord | null;
  onClose: () => void;
  onRename: (id: string, newName: string) => void;
}

const RenameDialog: React.FC<RenameDialogProps> = ({
  open,
  selectedRow,
  onClose,
  onRename,
}) => {
  const [newName, setNewName] = useState(selectedRow?.name || "");

  const handleRename = () => {
    if (selectedRow && newName) {
      onRename(selectedRow.id, newName);
      onClose();
      setNewName("");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography fontFamily='Gilroy-Semibold' fontSize={14}>
          Rename Project {selectedRow?.name}
        </Typography>
        <IconButton onClick={onClose}>
          <Close
            sx={{
              height: 14,
              width: 14,
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          placeholder={selectedRow?.name}
          type='text'
          fullWidth
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          sx={{
            ".MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#E5E5E5",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#E5E5E5",
                outlineColor: "#E5E5E5",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#E5E5E5",
                outlineColor: "#E5E5E5",
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          sx={{
            bgcolor: "#F4F4F4",
            color: theme.palette.primary.main,
            paddingRight: 6,
            paddingLeft: 6,
            marginRight: 3,
          }}
          onClick={() => {
            setNewName("");
            onClose();
          }}>
          Cancel
        </Button>
        <Button
          variant='contained'
          sx={{
            paddingRight: 6,
            paddingLeft: 6,
            bgcolor: theme.palette.primary.main,
          }}
          onClick={handleRename}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface DeleteDialogProps {
  open: boolean;
  selectedRow: ProjectRecord | null;
  onClose: () => void;
  onDelete: (id: string) => void;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  selectedRow,
  onClose,
  onDelete,
}) => {
  const handleDelete = () => {
    if (selectedRow) {
      onDelete(selectedRow.id);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: 4,
        }}>
        <Typography fontFamily='Gilroy-Semibold' fontSize={14}>
          Are you sure?
        </Typography>
        <IconButton onClick={onClose}>
          <Close
            sx={{
              height: 14,
              width: 14,
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingRight: 4,
        }}>
        <DialogContentText fontSize={12} fontFamily='Gilroy-Medium'>
          If you delete a project, all associated data and enrichments will be
          lost. Are you sure you want to delete this project?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          paddingRight: 4,
          paddingBottom: 2,
        }}>
        <Button
          variant='text'
          sx={{
            bgcolor: "#F4F4F4",
            color: theme.palette.primary.main,
            paddingRight: 4,
            paddingLeft: 4,
            marginRight: 2,
          }}
          onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='error'
          sx={{
            paddingRight: 4,
            paddingLeft: 4,
            backgroundColor: "#FF0703",
          }}
          onClick={handleDelete}>
          Yes, delete it
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { RenameDialog, DeleteDialog };
