import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import theme from "../../theme";
import HiringSignaList from "./HiringSignalList";
import { toast } from "react-toastify";
import { HiringSignal, staticPrompt } from "../../service/staticPromt";
import BurstLoader from "../common/BurstLoader";
import { log } from "console";
import { ProjectRecord } from "../../service/listProject";

type HiringSignalDialogProps = {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  onSave: (records: HiringSignal[]) => void;
  onHiringBack: () => void;
};

const HiringSignalDialog: React.FC<HiringSignalDialogProps> = ({
  open,
  onClose,
  onBack,
  onSave,
  onHiringBack,
}) => {
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [designation, setDesignation] = useState("");
  const [location, setLocation] = useState("");
  const [functionValue, setFunctionValue] = useState("");
  const [companies, setCompanies] = useState<HiringSignal[]>([]);
  const [allCompanies, setAllCompanies] = useState<HiringSignal[]>([]);
  const [count, setCount] = useState(0);
  if (showList) {
    return (
      <HiringSignaList
        companies={allCompanies}
        open={showList}
        onClose={() => {
          setShowList(false);
          onClose();
        }}
        onBack={() => {
          setShowList(false);
          onHiringBack();
        }}
        onSave={onSave}
        count={count}
        functionValue={functionValue}
        designation={designation}
        location={location}
      />
    );
  }

  const handleStaticPrompt = async () => {
    setLoading(true);
    try {
      const response = await staticPrompt({
        designation,
        function: functionValue,
        location,
      });

      setCompanies(response.data ? response.data.slice(0, 20) : []);
      setCount(response.data ? response.data.length : 0);
      setShowList(true);
      setAllCompanies(response.data);
      onClose();
    } catch (error) {
      toast.error("Failed to save profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <DialogContent
        sx={{
          padding: 4,
        }}>
        <Box
          width='100%'
          display='flex'
          alignItems='center'
          marginBottom={2}
          justifyContent='space-between'>
          <Box display='flex' alignItems='center' justifyContent='flex-start'>
            <IconButton aria-label='close' onClick={onBack}>
              <ArrowBackIos
                sx={{
                  height: 14,
                  width: 14,
                }}
              />
            </IconButton>
            <Typography variant='h2'>Back</Typography>
          </Box>
          <IconButton aria-label='close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography marginBottom={1} variant='h1'>
          Hiring Signal
        </Typography>
        <Typography marginBottom={3} variant='h3'>
          Ask ListenBravo for accounts that are showing hiring signals that fit
          well with your outreach needs
        </Typography>

        <Card
          variant='outlined'
          sx={{
            padding: 4,
            mt: 2,
            mb: 2,
            borderRadius: 2,
            height: 550,
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}>
          <Typography
            variant='h4'
            color='textSecondary'
            fontFamily='Gilroy-Semibold'>
            Hey Bravo!
          </Typography>
          <Box
            mt={4}
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
            gap={1}>
            <Typography
              variant='h2'
              color={theme.palette.primary.main}
              gutterBottom>
              Let’s start with a template prompt to find your target accounts.
            </Typography>
            {/* <Typography variant='h2' color='#ADADAD' gutterBottom>
              And refine later with free text prompt
            </Typography> */}
          </Box>
          <Typography
            fontStyle='italic'
            variant='body2'
            color='textSecondary'
            mt={2}
            gutterBottom>
            Fill up to one field and press enter
          </Typography>
          <Box></Box>
          <Box marginTop='auto'>
            <Card
              variant='outlined'
              sx={{
                paddingTop: 1.5,
                paddingBottom: 1.5,
                paddingRight: 2.5,
                paddingLeft: 2.5,
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                bgcolor: "#FAF9F8",
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}>
                <Typography color={theme.palette.primary.main} variant='h3'>
                  Hey ListenBravo, find me companies
                  hiring&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <TextField
                  variant='standard'
                  placeholder='associates'
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  InputProps={{
                    style: {
                      borderRadius: 0,
                      padding: 0,
                      fontSize: 14,
                    },
                  }}
                  inputProps={{
                    style: {
                      width: `${"associates".length}ch`,
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      color: "gray", // Change this to your desired color
                      opacity: 0.5, // This is necessary for the color to apply
                    },
                  }}
                />
                <Typography color={theme.palette.primary.main} variant='h3'>
                  &nbsp;&nbsp;&nbsp;&nbsp;in their&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <TextField
                  variant='standard'
                  placeholder='customer support'
                  value={functionValue}
                  onChange={(e) => setFunctionValue(e.target.value)}
                  InputProps={{
                    style: {
                      borderRadius: 0,
                      padding: 0,
                      fontSize: 14,
                    },
                  }}
                  inputProps={{
                    style: {
                      width: `${"customersuppor".length}ch`,
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      color: "gray", // Change this to your desired color
                      opacity: 0.5, // This is necessary for the color to apply
                    },
                  }}
                />
                <Typography variant='h3' color={theme.palette.primary.main}>
                  team at location&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <TextField
                  variant='standard'
                  placeholder='USA'
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  InputProps={{
                    style: {
                      borderRadius: 0,
                      padding: 0,
                      fontSize: 14,
                    },
                  }}
                  inputProps={{
                    style: {
                      width: `${"USAA".length}ch`,
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      color: "gray", // Change this to your desired color
                      opacity: 0.5, // This is necessary for the color to apply
                    },
                  }}
                />
              </Box>
              {!loading ? (
                <IconButton
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 2,
                    bgcolor: "#F4F4F4",
                    padding: 0.5,
                  }}
                  onClick={handleStaticPrompt}>
                  <ArrowForwardIos color='primary' fontSize='small' />
                </IconButton>
              ) : (
                <BurstLoader />
              )}
            </Card>
          </Box>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default HiringSignalDialog;
