import {
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PeopleChips from "../common/PeopleChips";
import aiEnrich from "../../assets/images/ai-enrich.svg";
import peopleIcon from "../../assets/images/people.svg";
import { AIEnrichmentRequest } from "../../service/aiEnrichment";
import { Close, Delete, KeyboardArrowDown } from "@mui/icons-material";
import theme from "../../theme";
import BurstLoader from "../common/BurstLoader";
import { toast } from "react-toastify";

type NewCol = {
  column_name: string;
  project_id: string;
  prompt: string;
  answer_format: string;
  records: any[];
  answer: string;
  reason: string;
  link: string;
  user_generated: boolean;
  sources: number[];
  links: string[];
  // id:string // Added attribute
};

interface ColumnDetailPopoverProps {
  anchorEl: null;
  setShowColumnDetailPop: React.Dispatch<React.SetStateAction<boolean>>;
  setAnchorEl: React.Dispatch<React.SetStateAction<null>>;
  setColumnName: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  setNewCols: React.Dispatch<React.SetStateAction<any[]>>;
  setUpdateLocalStorage: React.Dispatch<React.SetStateAction<boolean>>;
  deleteColumn: (project_id: string, column: string) => Promise<void>;
  setHeaders: React.Dispatch<React.SetStateAction<NewCol[]>>;
  updateColumnName: any;
  aiEnrichRequest: AIEnrichmentRequest | null;
  projectId: string;
  headers: any[];
  newCols: any[];
  columnName: string;
}

const ColumnDetailPopover: React.FC<ColumnDetailPopoverProps> = ({
  anchorEl,
  setShowColumnDetailPop,
  setAnchorEl,
  aiEnrichRequest,
  setColumnName,
  loading,
  setNewCols,
  setUpdateLocalStorage,
  deleteColumn,
  setHeaders,
  updateColumnName,
  projectId,
  headers,
  columnName,
  newCols,
}) => {
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;
  const CustomDropdownIcon = () => {
    return (
      <KeyboardArrowDown
        sx={{
          color: "#6A7383",
        }}
      /> // Replace this with your custom icon
    );
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        setShowColumnDetailPop(false);
        setAnchorEl(null);
      }}
      sx={{
        borderRadius: 2,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}>
      <Box
        p={2}
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        width={450}
        justifyContent='flex-start'>
        <Box sx={{ mt: 2, width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}>
            <Typography
              typography='h3'
              sx={{
                color: theme.palette.primary.main,
              }}
              fontWeight={600}>
              Column Name
            </Typography>
            <IconButton
              onClick={() => {
                setShowColumnDetailPop(false);
                setAnchorEl(null);
              }}>
              <Close></Close>
            </IconButton>
          </Box>

          <TextField
            fullWidth
            //   label='Enter link'
            defaultValue={aiEnrichRequest?.column_name}
            variant='outlined'
            onChange={(e) => {
              setColumnName(e.target.value);
            }}
            sx={{
              mt: 2,
              padding: 0,
              color: theme.palette.primary.main,
            }}
          />
          <Typography mt={2} typography='h4' fontWeight={600}>
            Provide a name that you want to give to this column
          </Typography>
        </Box>

        <Box sx={{ mt: 2, width: "100%" }}>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={600}>
            Request Prompt
          </Typography>
          <TextField
            placeholder='For each company, tell me if they are using “Mixpanel” in their product team'
            multiline
            rows={4}
            disabled
            value={aiEnrichRequest?.prompt}
            variant='outlined'
            fullWidth
            sx={{
              "& .MuiInputBase-root": {
                height: "auto",
              },
              mt: 1,
            }}
          />
        </Box>
        <Box mt={2} width='100%'>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={600}>
            Answer format
          </Typography>
          <TextField
            fullWidth
            select
            placeholder='Yes/No'
            variant='outlined'
            defaultValue=''
            SelectProps={{
              native: true,
              IconComponent: CustomDropdownIcon,
            }}
            disabled
            inputProps={{
              style: {
                paddingTop: 4,
                paddingBottom: 4,
                paddingRight: 8,
                paddingLeft: 8,
                textAlign: "start",

                color: theme.palette.primary.main,
              },
            }}
            sx={{ mt: 2 }}>
            <option value={"Yes/No"}>{aiEnrichRequest?.answer_format}</option>
          </TextField>
        </Box>

        {loading ? (
          <Box
            width='100%'
            mt={2}
            display='flex'
            alignItems='center'
            justifyContent='center'>
            <BurstLoader />
          </Box>
        ) : (
          <Box
            display='flex'
            width='100%'
            alignItems='center'
            justifyContent='space-between'>
            <Button
              startIcon={<Delete />}
              variant='outlined'
              color='secondary'
              sx={{
                marginTop: 2,
              }}
              onClick={() => {
                setNewCols((prevCols) =>
                  prevCols.filter(
                    (col) => col.column_name !== aiEnrichRequest?.column_name
                  )
                );
                setNewCols((prevCols) =>
                  prevCols.filter(
                    (col) => col.column_name !== aiEnrichRequest?.column_name
                  )
                );
                setNewCols((prevCols) =>
                  prevCols.filter(
                    (col) =>
                      !col.column_name.startsWith(
                        aiEnrichRequest?.column_name + " - "
                      )
                  )
                );
                setUpdateLocalStorage(true);
                setAnchorEl(null);
                setShowColumnDetailPop(false);
                deleteColumn(projectId, aiEnrichRequest?.column_name as string)
                  .then(() => {
                    toast.success("Column deleted successfully");
                    setHeaders(
                      headers.filter(
                        (co) =>
                          !co.column_name.includes(
                            aiEnrichRequest?.column_name + " - "
                          ) && co.column_name !== aiEnrichRequest?.column_name
                      )
                    );
                    setNewCols(
                      newCols.filter(
                        (co) =>
                          !co.column_name.includes(
                            aiEnrichRequest?.column_name + " - "
                          ) && co.column_name !== aiEnrichRequest?.column_name
                      )
                    );
                  })
                  .catch((e: any) => {
                    toast.error(e.message);
                  });
              }}>
              Delete
            </Button>
            <Button
              variant='contained'
              sx={{
                marginTop: 2,
              }}
              onClick={() => {
                updateColumnName(
                  aiEnrichRequest?.column_name as string,
                  columnName
                );
              }}>
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Popover>
  );
};

export default ColumnDetailPopover;
