import { apiService } from "./apiService";

export const renameProject = async (
  project_id: string,
  name: string
): Promise<void> => {
  const response: any = await apiService.post<string>(
    `/rename-project?project_id=${project_id}&name=${name}`,
    {}
  );
};
