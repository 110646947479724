import React, { useState } from "react";
import {
  Box,
  Container,
  useTheme,
  useMediaQuery,
  Typography,
  Button,
} from "@mui/material";
import Logo from "./Logo";
import stepIcon from "../../assets/images/step-icon.svg";
import feedbackIcon from "../../assets/images/feedback-icon.svg";
import supportIcon from "../../assets/images/support-icon.svg";

const ScreenSiizeUnavailable: React.FC = () => {
  const theme = useTheme();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
      }}
      maxWidth={false}
      disableGutters>
      <Box
        sx={{
          paddingTop: 4,
          paddingBottom: 2,
          paddingRight: 2,
          paddingLeft: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          flex: 1,
          backgroundColor: theme.palette.background.default,
          minHeight: "100vh",
        }}>
        <Logo />
        <Container
          sx={{
            mt: 14,
          }}>
          <Typography
            fontSize={24}
            color={theme.palette.primary.main}
            fontFamily='Gilroy-Semibold'>
            This screen size is not supported (yet)
          </Typography>
          <Typography
            fontSize={12}
            mt={1}
            color='#757575'
            fontFamily='Gilroy-Medium'>
            Right now, we don’t support mobile screen size
          </Typography>
          <Typography
            fontSize={14}
            mt={6}
            color='#757575'
            fontFamily='Gilroy-Semibold'>
            Please switch to your desktop or laptop to use ListenBravo
          </Typography>
        </Container>
        <Container
          sx={{
            marginTop: "auto",
          }}
          disableGutters>
          <Box mt='auto'>
            <Button
              onClick={() => {
                window.open(
                  "https://silken-diploma-c64.notion.site/How-ListenBravo-Works-4ae62d3c7d6f49118ede6090a25fc141?pvs=4"
                );
              }}
              startIcon={<img src={stepIcon} />}
              fullWidth
              sx={{
                justifyContent: "flex-start",
                mb: 1,
                color: "#757575",
                fontFamily: "Gilroy-Medium",
              }}>
              Step-by-step guide
            </Button>

            <Button
              onClick={() => {
                window.open("mailto:hello@listenbravo.com");
              }}
              startIcon={<img src={supportIcon} />}
              fullWidth
              sx={{
                justifyContent: "flex-start",
                mb: 1,
                color: "#757575",
                fontFamily: "Gilroy-Medium",
              }}>
              Help and Support
            </Button>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default ScreenSiizeUnavailable;
