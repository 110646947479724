import { apiService } from "./apiService";
import { ProjectRecord } from "./listProject";

export type ResearchAIEnrichmentRequest = {
  project_id: string;
  column_name: string;
  prompt?: string;
  answer_format?: string;
  // records: ProjectRecord[];
  titles?: string[];
  seniorities?: string[];
  locations?: string[];
  number_of_people?: number;
  enrichments?: number[];

  sources?: number[];
  links?: string[];
  companies?: string[];
  is_enrich_col?: boolean;
  is_people_col?: boolean;
  // id: string;
};

export type ResearchAIEnrichmentResponse = {
  column_name: string;
  records: ResearchProjectRecord[];
  credits_left: number;
};

export type ResearchProjectRecord = ProjectRecord & {
  [key: string]: {
    source: string;
    answer: string;
    reason: string;
    link: string;
  };
};

export const researchAiEnrichment = async (
  enrichmentRequest: ResearchAIEnrichmentRequest
): Promise<ResearchAIEnrichmentResponse> => {
  const response = await apiService.post<ResearchAIEnrichmentResponse>(
    "/research/dynamic-prompt",
    JSON.stringify(enrichmentRequest),
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const researchFindPeople = async (
  enrichmentRequest: ResearchAIEnrichmentRequest
): Promise<any> => {
  const response = await apiService.post<any>(
    "/research/find-people",
    JSON.stringify(enrichmentRequest),
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};
