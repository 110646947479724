import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

interface LoaderModalProps {
  open: boolean;
  message?: string;
}

const LoaderModal: React.FC<LoaderModalProps> = ({
  open,
  message = "Loading...",
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby='loading-modal-title'
      aria-describedby='loading-modal-description'
      closeAfterTransition>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        bgcolor='background.paper'
        borderRadius={1}
        boxShadow={24}
        p={4}
        position='absolute'
        top='50%'
        left='50%'
        sx={{ transform: "translate(-50%, -50%)", outline: "none" }}>
        <CircularProgress />
      </Box>
    </Modal>
  );
};

export default LoaderModal;
