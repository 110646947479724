import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Step,
  Typography,
} from "@mui/material";
import React from "react";
import theme from "../../theme";
import {
  Feedback,
  Folder,
  Help,
  PlayCircleFilledOutlined,
  PlayCircleFilledWhiteOutlined,
} from "@mui/icons-material";
import highlightIcon from "../../assets/images/highlight-icon.svg";
import stepIcon from "../../assets/images/step-icon.svg";
import feedbackIcon from "../../assets/images/feedback-icon.svg";
import supportIcon from "../../assets/images/support-icon.svg";
import thumbNail from "../../assets/images/how_to_thumbnail.png";

const RightSidebar = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.default",
        pl: 1,
        pr: 6,
        pt: 3,
        pb: 3,
        flex: 0.9,
      }}>
      <Card sx={{ mb: 3, mt: 4 }}>
        <CardMedia
          component='img'
          height='170'
          image={thumbNail}
          alt='Thumbnail'
        />
        <CardContent>
          <Typography
            typography='h3'
            fontFamily='Gilroy-Semibold'
            sx={{
              color: theme.palette.primary.main,
            }}>
            HOW LISTENBRAVO WORKS
          </Typography>
          <Typography
            onClick={() => {
              window.open(
                "https://www.loom.com/share/f213922e37814c30aea113f96e93d1dd?sid=d6f16790-f16e-46ee-9efb-b550b66925bd",
                "_blank"
              );
            }}
            typography='h4'
            fontFamily='Gilroy-Medium'
            sx={{
              color: theme.palette.secondary.main,
              mt: 1,
              cursor: "pointer",
            }}>
            Click to watch video
          </Typography>
        </CardContent>
      </Card>

      <Box mt={8}>
        <Button
          onClick={() => {
            window.open(
              "https://silken-diploma-c64.notion.site/How-ListenBravo-Works-4ae62d3c7d6f49118ede6090a25fc141?pvs=4"
            );
          }}
          startIcon={<img src={stepIcon} />}
          fullWidth
          sx={{
            justifyContent: "flex-start",
            mb: 1,
            color: "#757575",
            fontFamily: "Gilroy-Medium",
          }}>
          Step-by-step guide
        </Button>
        <Button
          onClick={() => {
            window.open("mailto:hello@listenbravo.com");
          }}
          startIcon={<img src={feedbackIcon} />}
          fullWidth
          sx={{
            justifyContent: "flex-start",
            mb: 1,
            color: "#757575",
            fontFamily: "Gilroy-Medium",
          }}>
          Give feedback
        </Button>
        <Button
          onClick={() => {
            window.open("mailto:hello@listenbravo.com");
          }}
          startIcon={<img src={supportIcon} />}
          fullWidth
          sx={{
            justifyContent: "flex-start",
            mb: 1,
            color: "#757575",
            fontFamily: "Gilroy-Medium",
          }}>
          Help and Support
        </Button>
      </Box>

      {/* <Box display='flex' alignItems='center' gap={1} mb={3}>
        <img src={highlightIcon} />
        <Typography
          variant='h3'
          sx={{
            fontFamily: "Gilroy-Semibold",
          }}>
          Highlights
        </Typography>
      </Box>
      {[1, 2, 3].map((item) => (
        <Card
          variant='outlined'
          key={item}
          sx={{ mb: 2, height: 50, borderRadius: 2 }}>
          <CardContent
            sx={{
              padding: 1,
              paddingRight: 2,
              display: "flex",
            }}>
            <Box display='flex' alignItems='center' gap={1}>
              <img src={noDataIcon} height={32} width={27} />
              <Typography
                sx={{
                  fontFamily: "Gilroy-Medium",
                }}
                typography='h2'>
                No data yet
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))} */}
    </Box>
  );
};

export default RightSidebar;
