import React, { ChangeEvent, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import discoveryIcon from "../../assets/images/discovery-icon.svg";
import enrichIcon from "../../assets/images/enrich-icon.svg";
import Papa from "papaparse";
import upload from "../../assets/images/upload.svg";
import GridCardWithAction from "./GridCardWithAction";
import { useAuth } from "../../context/AuthContext";
import { uploadCsv } from "../../service/uploadCsv";
import { DataItem } from "../../utils/utils";
import theme from "../../theme";
import { toast } from "react-toastify";

interface CreateProjectDialogProps {
  open: boolean;
  onClose: () => void;
  discoveryAction: () => void;
  enrichAction: (data: DataItem[], headers: string[], title: string) => void;
}

const CreateProjectDialog: React.FC<CreateProjectDialogProps> = ({
  open,
  onClose,
  discoveryAction,
  enrichAction,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const [companyName, setCompanyName] = useState<string>("");
  const [isCompanyDialogOpen, setCompanyDialogOpen] = useState<boolean>(false);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadCsv(user?.email as string, companyName, file)
        .then((response: any) => {
          console.log("Upload successful", response.data);
          Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: (results) => {
              const parsedData = results.data as DataItem[];
              const headers = results.meta.fields as string[];
              console.log("the headers logged are ", headers);
              enrichAction(parsedData, headers, file.name);
              onClose();
            },
            error: (error) => {
              console.error("Error parsing CSV:", error);
            },
          });
        })
        .catch((error) => {
          console.error("Upload failed", error);
          // Handle upload error
        });
    }
  };

  const triggerFileUpload = () => {
    fileInputRef.current?.click();
  };

  const handleCompanyNameSubmit = () => {
    setCompanyDialogOpen(false);
    triggerFileUpload();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <DialogContent
        sx={{
          padding: 6,
        }}>
        <Box
          width={"100%"}
          display='flex'
          alignItems='center'
          justifyContent='space-between'>
          <Typography
            typography='h1'
            sx={{
              color: theme.palette.primary.main,
              fontSize: 24,
            }}>
            Create new project
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          typography='h3'
          sx={{
            color: "#757575",
            marginTop: 1,
          }}
          fontFamily='Gilroy-Medium'>
          What do you want to create today with ListenBravo? Please select from
          the options below
        </Typography>
        <Grid marginTop={0} container spacing={4} justifyContent='center'>
          <GridCardWithAction
            action={discoveryAction}
            title='Discover new accounts'
            subtitle='Hunt new accounts based on buying signals'
            icon={discoveryIcon}
            isComingSoon={false}
            actionIcon={<ArrowForwardIos />}
          />
          <input
            type='file'
            accept='.csv'
            onChange={handleFileUpload}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <GridCardWithAction
            action={() => {
              setCompanyDialogOpen(true);
            }}
            title='Enrich your accounts'
            subtitle='Upload your own csv and enrich accounts'
            icon={enrichIcon}
            isComingSoon={false}
            actionIcon={<img src={upload} height={24} width={24} />}
          />
        </Grid>
      </DialogContent>

      {/* Company Name Input Dialog */}
      <Dialog
        open={isCompanyDialogOpen}
        onClose={() => setCompanyDialogOpen(false)}
        maxWidth='md'>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 2,
            }}>
            <Typography> Enter company column name</Typography>
            <IconButton
              onClick={() => setCompanyDialogOpen(false)}
              sx={{ position: "absolute", right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            autoFocus
            margin='dense'
            label='Company column name'
            fullWidth
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <Typography
            variant='body2'
            sx={{
              mt: 1,
              fontSize: 12,
            }}>
            {" "}
            Enter the column name which has company names. This will help our
            agent
          </Typography>
          <Typography
            variant='body2'
            sx={{
              mt: 1,
              fontSize: 12,
            }}>
            {" "}
            to refer to the company for its research efforts
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (companyName.trim().length === 0) {
                toast.error("Company column name is required");
              } else {
                handleCompanyNameSubmit();
              }
            }}
            sx={{
              margin: 2,
              fontSize: 12,
            }}
            variant='contained'>
            Upload CSV
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CreateProjectDialog;
