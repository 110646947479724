import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Icon,
  IconButton,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import OTPInput from "react-otp-input";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import BurstLoader from "../common/BurstLoader";
import { useAuth } from "../../context/AuthContext";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type OtpViewProps = {
  email: string;
  onEditEmail: () => void;
};

const OtpView: React.FC<OtpViewProps> = ({ email, onEditEmail }) => {
  const { signInWithEmail, verifyOTP, signInWithGoogle } = useAuth();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (otpNew: string) => {
    setOtp(otpNew);
    if (otpNew.length === 6) {
      setLoading(true);
      handleVerifyOtp(otpNew);
    }
  };

  const handleVerifyOtp = async (otp: string) => {
    try {
      await verifyOTP(email, otp);
      toast.success("OTP verified successfully");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error("Invalid OTP!");
    }
  };

  const handleEmailSignIn = async () => {
    try {
      setLoading(true);
      await signInWithEmail(email);
      setLoading(false);
    } catch (error) {
      console.error("Error signing in with email:", error);
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        marginTop: 12,
        gap: 2,
        width: "100%",
        paddingLeft: isMobile ? 1 : 2,
        paddingRight: isMobile ? 1 : 4,
      }}>
      <Typography variant={isMobile ? "h3" : "h1"}>Enter OTP</Typography>
      <Box>
        <Typography sx={{}} variant={isMobile ? "body1" : "h3"}>
          Enter the 6-digit OTP you received on {email}!
          <IconButton onClick={onEditEmail}>
            <Edit sx={{ height: 16, width: 16 }} />
          </IconButton>
        </Typography>
      </Box>
      <OTPInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        inputType='tel'
        renderSeparator={<span style={{ padding: 4 }}></span>}
        renderInput={(props) => (
          <input
            {...props}
            style={{
              height: isMobile ? 40 : 55,
              width: isMobile ? 40 : 55,
              textAlign: "center",
              marginTop: 24,
              border: `1px solid ${theme.palette.primary.light}`,
              borderRadius: 4,
              outline: "none",
            }}
          />
        )}
      />
      {!loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 1,
            marginTop: 4,
          }}>
          <Typography
            variant={isMobile ? "body1" : "h3"}
            sx={{
              color: theme.palette.primary.main,
            }}>
            Didn’t receive OTP?
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 0.5,
              flexWrap: "wrap",
              flexDirection: "row",
            }}>
            <Typography variant={isMobile ? "body1" : "h3"}>
              Please make sure to check your
            </Typography>
            <Typography
              variant={isMobile ? "body1" : "h3"}
              fontFamily='Gilroy-Bold'
              sx={{
                color: theme.palette.primary.main,
              }}>
              SPAM
            </Typography>
            <Typography variant={isMobile ? "body1" : "h3"}>
              folder or
            </Typography>
            {isMobile && (
              <Typography
                variant={isMobile ? "body1" : "h3"}
                onClick={handleEmailSignIn}
                sx={{
                  textDecoration: "underline",
                  color: theme.palette.secondary.main,
                  cursor: "pointer",
                  fontWeight: 500,
                }}>
                Resend
              </Typography>
            )}
          </Box>
          {!isMobile && (
            <Typography
              variant={isMobile ? "body1" : "h3"}
              onClick={handleEmailSignIn}
              sx={{
                textDecoration: "underline",
                color: theme.palette.secondary.main,
                cursor: "pointer",
                fontWeight: 500,
              }}>
              Resend
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          marginTop={2}>
          <BurstLoader />
        </Box>
      )}
      <ToastContainer transition={Bounce} />
    </Container>
  );
};

export default OtpView;
