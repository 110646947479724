import { apiService } from "./apiService";

export const deleteColumn = async (
  project_id: string,
  column: string
): Promise<void> => {
  const response: any = await apiService.delete<string>(
    `/delete-column?project_id=${project_id}&column=${column}`
  );
};
