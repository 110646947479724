import React from "react";
import chipImage from "../../assets/images/coinCurrency.png";
import { Typography } from "@mui/material";
import theme from "../../theme";

interface PeopleChipsProps {
  chips: string;
}

const PeopleChips: React.FC<PeopleChipsProps> = ({ chips }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FAF9F8",
        borderRadius: "4px",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <div style={{ marginRight: "8px" }}>
        <img style={{ width: 12, height: 12 }} src={chipImage} alt="Chip" />
      </div>
      <Typography
        typography="h4"
        sx={{
          color: theme.palette.primary.main,
          fontWeight: 400,
        }}
      >
        {chips}
      </Typography>
    </div>
  );
};

export default PeopleChips;
