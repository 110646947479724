import { apiService } from "./apiService";
import { HiringSignal } from "./staticPromt";

export type SaveProjectRequest = {
  email: string;
  designation: string;
  team: string;
  function: string;
  location: string;
  records: HiringSignal[];
};

export type SaveProjectResponse = {
  status_code: number;
};

export const saveProject = (data: SaveProjectRequest): Promise<any> => {
  return apiService.post<any>("/hiring/save-project", data);
};
