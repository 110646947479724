import { Box, Link, Typography } from "@mui/material";

const TermsPrivacy: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginTop: 10,
        flex: 1,
        paddingLeft: 2,
        paddingRight: 6,
      }}>
      <Typography typography='h4'>
        By continuing, you acknowledge that you understand and agree to the{" "}
        <a
          target='_blank'
          href='https://silken-diploma-c64.notion.site/Terms-of-Use-fc1f2c70b2ca43378960208295c83955?pvs=4'
          style={{
            color: "#ADADAD",
          }}>
          Terms & Conditions
        </a>{" "}
        and{" "}
        <a
          target='_blank'
          href='https://silken-diploma-c64.notion.site/Privacy-Policy-40013bbecaa04216928d5d84aa0f7944?pvs=4'
          style={{
            color: "#ADADAD",
          }}>
          Privacy Policy
        </a>
        .
      </Typography>
    </Box>
  );
};

export default TermsPrivacy;
