import React, { useState } from "react";
import { Box, Container, useTheme, useMediaQuery } from "@mui/material";
import SignInAction from "../components/SignIn/SignInAction";
import SignInDesign from "../components/SignIn/SignInDesign";
import OtpView from "../components/SignIn/OtpView";
import TermsPrivacy from "../components/SignIn/TermsPrivacy";
import Logo from "../components/common/Logo";

const Signin: React.FC = () => {
  const [showOtp, setShowOtp] = useState(false);
  const [email, setEmail] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        padding: isMobile ? 2 : 0,
      }}
      maxWidth={false}
      disableGutters>
      <Box
        sx={{
          paddingTop: isMobile ? 4 : 12,
          paddingBottom: isMobile ? 2 : 8,
          paddingRight: isMobile ? 2 : 14,
          paddingLeft: isMobile ? 2 : 14,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          flex: 1,
          backgroundColor: theme.palette.background.default,
          minHeight: "100vh",
        }}>
        <Logo />
        {showOtp && (
          <OtpView
            email={email}
            onEditEmail={() => {
              setShowOtp(false);
            }}
          />
        )}
        {!showOtp && (
          <SignInAction
            email={email}
            onSetEmail={(mail: string) => {
              setEmail(mail);
            }}
            onContinuePress={() => {
              setShowOtp(true);
            }}
          />
        )}
        <TermsPrivacy />
      </Box>
      {!isMobile && <SignInDesign />}
    </Container>
  );
};

export default Signin;
