import {
  Add,
  ArrowBackIos,
  Cached,
  Close,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowDownRounded,
  LaunchOutlined,
  Pause,
  PauseOutlined,
  PauseRounded,
  PlayArrow,
  Refresh,
  Search,
  Update,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  Divider,
  IconButton,
  Link,
  Paper,
  Popover,
  Radio,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Papa from "papaparse";
import theme from "../../theme";
import csvIcon from "../../assets/images/csv-icon.svg";
import Pagination from "./Pagination";
import pauseIcon from "../../assets/images/pause-icon.svg";
import aiEnrich from "../../assets/images/ai-enrich.svg";
import peopleIcon from "../../assets/images/people.svg";
import { formatDate } from "../../utils/utils";
import {
  AIEnrichmentRequest,
  aiEnrichment,
  hiringFindPeople,
} from "../../service/aiEnrichment";
import { toast } from "react-toastify";
import BurstLoader from "../common/BurstLoader";
import { ProjectRecord } from "../../service/listProject";
import astrix from "../../assets/images/astrix.svg";
import { updateColumnService } from "../../service/updateColumnService";
import { deleteColumn } from "../../service/deleteColumn";
import {
  DynamicPrompt,
  getProjectHeaders,
  ProjectPrompts,
} from "../../service/getProjectHeaders";
import elipsIcon from "../../assets/images/elipses-icon.svg";
import duplicate from "../../assets/images/duplicate.svg";
import rename from "../../assets/images/rename.svg";
import deleteIcon from "../../assets/images/delete.svg";
import { DeleteDialog, RenameDialog } from "./projectDialogs";
import { saveAs } from "file-saver";
import AddPeoplePopOver from "./AddPeoplePopOver";
import PeopleChips from "../common/PeopleChips";
import HeaderFilter from "../myTable/HeaderFilter";
import matchFound from "../../assets/images/match_found.png";
import matchNotFound from "../../assets/images/match_not_found.png";
import EnrichmentColDetail from "./spreadsheet/enrichmentColDetail";

export type NewCol = {
  column_name: string;
  project_id: string;
  prompt: string;
  answer_format: string;
  records: any[];
  answer: string;
  reason: string;
  link: string;
  user_generated: boolean;
  sources: number[];
  links: string[];
  is_enrich_col?: boolean;
  is_people_col?: boolean;
  titles?: string[];
  seniorities?: string[];
  locations?: string[];
  number_of_people?: number;
  enrichments?: number[];
};

type SpreadsheetProps = {
  title: string;
  data: any[];
  onClose: () => void;
  projectId: string;
  initialHeaderMappings: { [key: string]: string };
  projectHeaders: ProjectPrompts[];
  initialHeaders: string[];
  handleRefresh: () => void;
  showTableLoader: boolean;
  handleDuplicateProject: (data: ProjectRecord | null) => void;
  handleRenameProject: (data: ProjectRecord | null, newName: string) => void;
  handleDeleteProject: (data: ProjectRecord | null) => void;
  projectRecord: ProjectRecord | null;
  isResearch: boolean;
  setCredits: (credits: number) => void;
};

export function calculateCreditsUsed(
  rows: number,
  aiEnrichRequest?: AIEnrichmentRequest
): number {
  const baseCreditPerRow = 1;
  const enrichmentCreditMap: { [key in number]: number } = {
    1: 1, // name
    2: 1, // email
    3: 1, // linkedin
    4: 5, // phonenumber
  };
  const numberOfPeople = aiEnrichRequest?.number_of_people || 1;
  const totalBaseCredits = rows * baseCreditPerRow * numberOfPeople;

  let totalEnrichmentCredits = 0;

  if (aiEnrichRequest?.enrichments) {
    totalEnrichmentCredits =
      aiEnrichRequest?.enrichments.reduce((sum, enrichment) => {
        return sum + enrichmentCreditMap[enrichment];
      }, 0) * rows;
  }

  return totalBaseCredits + totalEnrichmentCredits;
}

const Spreadsheet: React.FC<SpreadsheetProps> = ({
  title,
  data,
  onClose,
  isResearch,
  projectId,
  initialHeaders,
  initialHeaderMappings,
  handleRefresh,
  showTableLoader,
  projectHeaders,
  handleDeleteProject,
  handleDuplicateProject,
  handleRenameProject,
  projectRecord,
  setCredits,
}) => {
  const [filteredData, setFilteredData] = useState(data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAIEnrichment, setShowAIEnrichment] = useState(false);
  const [columnName, setColumnName] = useState<string>("");
  const [requestPrompt, setRequestPrompt] = useState<string>("");
  const [answerFormat, setAnswerFormat] = useState<string>("Yes/No");
  const [loading, setLoading] = useState<boolean>(false);
  const [numColumns, setNumColumns] = useState(1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isRenameDialogOpen, setRenameDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const [newCols, setNewCols] = useState<any[]>([]);
  const [showRunPopup, setShowRunPop] = useState<boolean>(false);
  const [showColumnDetailPop, setShowColumnDetailPop] =
    useState<boolean>(false);
  const [colDetailEl, setColDetailEl] = useState(null);
  const [showAIEnrichProgress, setShowAIEnrichProgress] =
    useState<boolean>(false);
  const [colChecked, setColChecked] = useState(false);
  const [showPromptDetail, setShowPromptDetail] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState<any>();
  const [columnNextCount, setColumnNextCount] = useState<{
    [key: string]: number;
  }>({});
  const [jobId, setJobId] = useState("");
  const [currentId, setCurrentId] = useState("");
  const [dataSource, setDataSource] = useState<number>(0);
  const [peoplePopoverAnchor, setPeoplePopoverAnchor] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePeoplePopover = () => {
    setPeoplePopoverAnchor(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowAIEnrichment(false);
    setShowRunPop(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [headers, setHeaders] = useState<NewCol[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const [headerMappings, setHeaderMappings] = useState<{
    [key: string]: string;
  }>({});
  const [aiEnrichRequest, setAiEnrichRequest] =
    useState<AIEnrichmentRequest | null>(null);

  //   const handleColumnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     const newNumColumns = parseInt(e.target.value, 10);
  //     setHeaders(initialHeaders.slice(0, newNumColumns));
  //   };
  const [updateLocalStorage, setUpdateLocalStorage] = useState(false);
  useEffect(() => {
    if (updateLocalStorage) {
      updateColumn();
    }
    setUpdateLocalStorage(false);
  }, [updateLocalStorage]);

  const updateColumn = () => {
    // Retrieve existing data from localStorage
    const storedData = localStorage.getItem("newHeaders");
    let newHeadersArray = storedData ? JSON.parse(storedData) : [];

    // Check if the projectId already exists in the array
    const projectIndex = newHeadersArray.findIndex(
      (item: any) => item.project === projectId
    );
    if (newCols.length > 0) {
      if (projectIndex >= 0) {
        // Update existing project entry
        newHeadersArray[projectIndex].cols = newCols;
      } else {
        // Add new project entry
        newHeadersArray.push({
          project: projectId,
          cols: newCols,
        });
      }
    } else {
      // If newCols is empty, remove the project entry if it exists
      if (projectIndex >= 0) {
        newHeadersArray.splice(projectIndex, 1);
      }
    }

    // Save the updated array back to localStorage
    localStorage.setItem("newHeaders", JSON.stringify(newHeadersArray));
  };

  useEffect(() => {
    if (data.length > 0) {
      setFilteredData(data);
      console.log(data);
      console.log(filteredData);
      const newHeaders: any[] = [];
      // if its notResearch then initialheaders.map

      initialHeaders.map((h) => {
        newHeaders.push({
          column_name: h,
          sources: [dataSource],
          prompt: "",
          answer_format: "",
          answer: "",
          records: [],
          reason: "",
          link: "",
          links: [],
          user_generated: false,
        });
      });

      const newHeaderMappings = initialHeaderMappings;

      if (projectHeaders && projectHeaders.length > 0) {
        console.log(projectHeaders);

        projectHeaders.map((p) => {
          if (p.number_of_people) {
            newHeaders.push({
              column_name: `${p.column_name}`,
              project_id: projectId,
              prompt: requestPrompt,
              answer_format: answerFormat,
              answer: "",
              reason: "",
              link: "",
              links: [],
              sources: [dataSource],
              number_of_people: p.number_of_people,
              titles: p.titles,
              locations: p.locations,
              seniorities: p.seniorities,
              enrichments: p.enrichments,
              is_enrich_col: false,
              is_people_col: true,
              user_generated: true, // Main column, not an enrichment column
            });
            for (let i = 0; i < p.number_of_people; i++) {
              // Enrichment columns for the person
              p?.enrichments?.forEach((enrichment, idx) => {
                newHeaders.push({
                  column_name: `${p.column_name} - ${
                    enrichment === 1
                      ? "Name"
                      : enrichment === 2
                      ? "Email"
                      : enrichment === 3
                      ? "LinkedIn"
                      : "Phone"
                  } ${i + 1}`,
                  project_id: projectId,

                  prompt: p.prompt,
                  answer_format: p.answer_format,
                  answer: "",
                  reason: "",
                  link: "",
                  links: [],
                  sources: [dataSource],
                  number_of_people: p.number_of_people,
                  titles: p.titles,
                  locations: p.locations,
                  seniorities: p.seniorities,
                  enrichments: p.enrichments,
                  is_enrich_col: true,
                  is_people_col: false,
                  user_generated: true,
                });
              });
            }
          } else {
            newHeaders.push({
              column_name: p.column_name,
              prompt: p.prompt,
              answer_format: p.answer_format,
              answer: "",
              records: [],
              reason: "",
              link: "",
              user_generated: true,
              sources: [dataSource],
              links: [],
              // headers,
            });
          }
          newHeaderMappings[p.column_name] = p.column_name;
          // newHeaderMappings[p.column_name] = p.column_name;
        });
      }
      console.log("headermapping", newHeaders);
      setHeaders(newHeaders);
      setVisibleColumns(newHeaders.map((nH) => nH.column_name));
      // setHeaderMappings((prevMappings) => ({
      //   ...prevMappings,
      //   ...newHeaderMappings,
      // }));
      setHeaderMappings(newHeaderMappings);
      const storedData = localStorage.getItem("newHeaders");
      if (storedData) {
        const newHeadersArray = JSON.parse(storedData);

        const projectData = newHeadersArray.find(
          (item: any) => item.project === projectId
        );
        if (projectData) {
          setNewCols(
            projectData.cols.filter(
              (col: any) =>
                !newHeaders.some(
                  (header) => header.column_name === col.column_name
                )
            )
          );
        }
      }
    }
  }, [showTableLoader]);

  useEffect(() => {
    if (
      selectedRows.length === filteredData.length &&
      filteredData.length !== 0
    ) {
      setColChecked(true);
    } else {
      setColChecked(false);
    }
  }, [selectedRows]);
  const handleRowSelect = (row: ProjectRecord) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(row)
        ? prevSelectedRows.filter((rowPrev) => rowPrev !== row)
        : [...prevSelectedRows, row]
    );
  };

  const handleRowSelectAll = () => {
    setSelectedRows(data);
  };

  const handleRunClick = async () => {
    const selectedRecords = selectedRows.map((rowId) =>
      filteredData.find((row) => row.id === rowId)
    );
    console.log(selectedRecords);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDisplayedData = () => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredData.slice(start, end);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const updateNextCount = (columnName: string, increment: number) => {
    setColumnNextCount((prev) => {
      const currentCount = prev[columnName] || 0; // Get the current count or default to 0
      return {
        ...prev,
        [columnName]: currentCount + increment, // Update the count for the column
      };
    });
  };

  const runAiEnrich = async (
    rowCount: number,
    rowsAdded: any[],
    isPeopleCol: boolean
  ) => {
    if (!isPeopleCol) {
      try {
        console.log("the sources are", aiEnrichRequest?.sources);

        if (rowCount > 100 && selectedRows.length === 0) {
          toast.error("Please select rows");
        } else {
          var currentNextCount =
            columnNextCount[aiEnrichRequest?.column_name as string] || 0;
          if (currentNextCount >= filteredData.length) {
            currentNextCount = 0;
            updateNextCount(aiEnrichRequest?.column_name as string, 0);
          }
          const rowsToProcess = rowsAdded.slice(
            currentNextCount,
            currentNextCount + rowCount
          );
          setSelectedRows(rowsToProcess);
          const links = rowsToProcess.map((item) => item.link);
          setCurrentId(aiEnrichRequest?.column_name as string);
          setAnchorEl(null);
          setShowAIEnrichProgress(true);
          const dataSpliced = selectedRows;

          const aiEnrichResponse = await aiEnrichment({
            project_id: projectId,
            column_name: aiEnrichRequest?.column_name as string,
            // records: rowsAdded,
            answer_format: aiEnrichRequest?.answer_format as string,
            prompt: aiEnrichRequest?.prompt as string,
            sources: aiEnrichRequest?.sources as number[],
            links: links as string[],
          });
          setCredits(aiEnrichResponse.credits_left);
          setNewCols(
            newCols.filter(
              (co) => co.column_name !== aiEnrichRequest?.column_name
            )
          );

          if (
            headers.filter(
              (h) => h.column_name === aiEnrichRequest?.column_name
            ).length === 0
          ) {
            setHeaders((prev) => [
              ...prev,
              {
                project_id: projectId,
                column_name: aiEnrichRequest?.column_name as string,
                records: [],
                answer_format: aiEnrichRequest?.answer_format as string,
                prompt: aiEnrichRequest?.prompt as string,
                answer: "",
                reason: "",
                link: "",
                sources: aiEnrichRequest?.sources as number[],
                links: links as string[],
                user_generated: true,
              },
            ]);
            setVisibleColumns((prev) => [
              ...prev,
              aiEnrichRequest?.column_name as string,
            ]);
          }
          console.log(aiEnrichResponse);
          const updatedData = filteredData.map((record) => {
            const newRecord = aiEnrichResponse.records.find(
              (newRec) => newRec.id === record.id
            );

            return newRecord ? newRecord : record;
          });

          // console.log(updatedData);
          setFilteredData(updatedData);
          // handleRefresh();

          setShowRunPop(false);

          setShowAIEnrichProgress(false);
          setShowAIEnrichment(false);
          setSelectedRows([]);
          if (rowCount > 10) {
            updateNextCount(aiEnrichRequest?.column_name as string, 100);
          } else {
            updateNextCount(aiEnrichRequest?.column_name as string, 10);
          }

          setAiEnrichRequest(null);
          // handleRefresh();

          // setNewCols([]);
        }
      } catch (error: any) {
        setShowRunPop(false);
        setAiEnrichRequest(null);
        setShowAIEnrichProgress(false);
        setShowAIEnrichment(false);
        //   setNewCols([]);
        toast.error(error.message);
      }
    } else {
      try {
        console.log("the sources are", aiEnrichRequest?.sources);

        if (rowCount > 100 && selectedRows.length === 0) {
          toast.error("Please select rows");
        } else {
          var currentNextCount =
            columnNextCount[aiEnrichRequest?.column_name as string] || 0;
          if (currentNextCount >= filteredData.length) {
            currentNextCount = 0;
            updateNextCount(aiEnrichRequest?.column_name as string, 0);
          }
          const rowsToProcess = rowsAdded.slice(
            currentNextCount,
            currentNextCount + rowCount
          );
          setSelectedRows(rowsToProcess);
          const links = rowsToProcess.map((item) => item.link);
          setCurrentId(aiEnrichRequest?.column_name as string);
          setAnchorEl(null);
          setShowAIEnrichProgress(true);

          const aiEnrichResponse = await hiringFindPeople({
            project_id: projectId,
            column_name: aiEnrichRequest?.column_name as string,
            // records: rowsAdded,
            answer_format: aiEnrichRequest?.answer_format as string,
            prompt: aiEnrichRequest?.prompt as string,
            sources: aiEnrichRequest?.sources as number[],
            titles: aiEnrichRequest?.titles,
            seniorities: aiEnrichRequest?.seniorities,
            locations: aiEnrichRequest?.locations,
            number_of_people: aiEnrichRequest?.number_of_people,
            enrichments: aiEnrichRequest?.enrichments,
            links: links as string[],
          });
          setCredits(aiEnrichResponse.credits_left);
          setNewCols(
            newCols.filter(
              (co) =>
                !co.column_name.includes(
                  aiEnrichRequest?.column_name + " - "
                ) && co.column_name !== aiEnrichRequest?.column_name
            )
          );

          if (
            headers.filter(
              (h) => h.column_name === aiEnrichRequest?.column_name
            ).length === 0
          ) {
            if (aiEnrichRequest?.number_of_people) {
              var newHeaders: NewCol[] = [];
              newHeaders.push({
                column_name: `${aiEnrichRequest?.column_name}`,
                project_id: projectId,
                prompt: requestPrompt,
                answer_format: answerFormat,
                answer: "",
                reason: "",
                link: "",
                links: [],
                sources: [dataSource],
                number_of_people: aiEnrichRequest?.number_of_people,
                titles: aiEnrichRequest?.titles,
                locations: aiEnrichRequest?.locations,
                seniorities: aiEnrichRequest?.seniorities,
                enrichments: aiEnrichRequest?.enrichments,
                is_enrich_col: false,
                is_people_col: true,
                user_generated: true,
                records: [],
              });
              for (let i = 0; i < aiEnrichRequest?.number_of_people; i++) {
                // Main column for the person

                // Enrichment columns for the person
                aiEnrichRequest?.enrichments?.forEach((enrichment, idx) => {
                  newHeaders.push({
                    column_name: `${columnName} - ${
                      enrichment === 1
                        ? "Name"
                        : enrichment === 2
                        ? "Email"
                        : enrichment === 3
                        ? "LinkedIn"
                        : "Phone"
                    } ${i + 1}`,
                    project_id: projectId,
                    prompt: requestPrompt,
                    answer_format: answerFormat,
                    answer: "",
                    reason: "",
                    link: "",
                    links: [],
                    sources: [dataSource],
                    number_of_people: aiEnrichRequest?.number_of_people,
                    titles: aiEnrichRequest?.titles,
                    locations: aiEnrichRequest?.locations,
                    seniorities: aiEnrichRequest?.seniorities,
                    enrichments: aiEnrichRequest?.enrichments,
                    is_enrich_col: true,
                    is_people_col: false,
                    user_generated: true,
                    records: [],
                  });
                  setVisibleColumns((prev) => [
                    ...prev,
                    `${columnName} - ${
                      enrichment === 1
                        ? "Name"
                        : enrichment === 2
                        ? "Email"
                        : enrichment === 3
                        ? "LinkedIn"
                        : "Phone"
                    } ${i + 1}`,
                  ]);
                });
              }
              setHeaders((prevHeaders) => [...prevHeaders, ...newHeaders]);
              setVisibleColumns((prev) => [
                ...prev,
                aiEnrichRequest?.column_name as string,
              ]);
            }
          }
          const updatedData = rowsAdded.map((record) => {
            const companyName = record.company?.name;
            if (companyName && aiEnrichResponse[companyName]) {
              // Update the record with the enrichment data
              const enrichmentData = aiEnrichResponse[companyName][0];
              return {
                ...record,
                [aiEnrichRequest?.column_name as string]: [enrichmentData],
              };
            }
            return record;
          });

          console.log(updatedData);

          setFilteredData((prev) => {
            // Filter out any records in `prev` that have the same ID as one in `rowsAdded`
            const filteredPrev = prev.filter(
              (item) => !rowsAdded.some((added) => added.id === item.id)
            );

            // Add the `updatedData` to the filtered previous data
            return [...filteredPrev, ...updatedData];
          });
          // handleRefresh();

          setShowRunPop(false);

          setShowAIEnrichProgress(false);
          setShowAIEnrichment(false);
          setSelectedRows([]);
          if (rowCount > 10) {
            updateNextCount(aiEnrichRequest?.column_name as string, 100);
          } else {
            updateNextCount(aiEnrichRequest?.column_name as string, 10);
          }

          setAiEnrichRequest(null);
          setUpdateLocalStorage(true);
          // handleRefresh();

          // setNewCols([]);
        }
      } catch (error: any) {
        setShowRunPop(false);
        setAiEnrichRequest(null);
        setShowAIEnrichProgress(false);
        setShowAIEnrichment(false);
        //   setNewCols([]);
        toast.error(error.message);
      }
    }
  };
  const handleSaveColumnName = async () => {
    try {
      const links = filteredData.map((item, index) => {
        return item.link;
      });
      if (
        columnName.trim().length === 0 ||
        requestPrompt.trim().length === 0 ||
        answerFormat.trim().length === 0
      ) {
        toast.error("All fields are mandatory");
      } else {
        setLoading(true);

        setNewCols([
          ...newCols.filter((c) => c.column_name !== columnName),
          {
            column_name: columnName,
            project_id: projectId,
            prompt: requestPrompt,
            answer_format: answerFormat,
            answer: "",
            reason: "",
            link: "",
            links: links,
            sources: [dataSource],
            user_generated: true,
          },
        ]);

        setAnchorEl(null);
        setShowAIEnrichment(false);
        setLoading(false);
        setColumnName("");
        setRequestPrompt("");
        setAnswerFormat("Yes/No");
        setUpdateLocalStorage(true);
      }
    } catch (error) {
      setLoading(true);
      toast.error(`Failed to enrich column with AI ${error}`);
    }
  };

  const handleSavePeopleColumnName = async (
    numPeople: number,
    location: string,
    seniority: string[],
    title: string,
    enrichments: number[],
    columnName: string
  ) => {
    try {
      setLoading(true);

      const newColumns = [];
      newColumns.push({
        column_name: `${columnName}`,
        project_id: projectId,
        prompt: requestPrompt,
        answer_format: answerFormat,
        answer: "",
        reason: "",
        link: "",
        links: [],
        sources: [dataSource],
        number_of_people: numPeople,
        titles: [title],
        locations: [location],
        seniorities: seniority,
        enrichments: enrichments,
        is_enrich_col: false,
        is_people_col: true,
        user_generated: true, // Main column, not an enrichment column
      });
      console.log(numPeople);
      for (let i = 0; i < numPeople; i++) {
        // Main column for the person

        // Enrichment columns for the person
        enrichments.forEach((enrichment, idx) => {
          newColumns.push({
            column_name: `${columnName} - ${
              enrichment === 1
                ? "Name"
                : enrichment === 2
                ? "Email"
                : enrichment === 3
                ? "LinkedIn"
                : "Phone"
            } ${i + 1}`,
            project_id: projectId,
            prompt: requestPrompt,
            answer_format: answerFormat,
            answer: "",
            reason: "",
            link: "",
            links: [],
            sources: [dataSource],
            number_of_people: numPeople,
            titles: [title],
            locations: [location],
            seniorities: seniority,
            enrichments: enrichments,
            is_enrich_col: true,
            is_people_col: false,
            user_generated: true,
          });
        });
      }

      setNewCols([
        ...newCols.filter((c) => c.column_name !== columnName),
        ...newColumns,
      ]);

      setAnchorEl(null);
      setShowAIEnrichment(false);
      setPeoplePopoverAnchor(null);
      setLoading(false);
      setColumnName("");
      setRequestPrompt("");
      setAnswerFormat("Yes/No");
      setUpdateLocalStorage(true);
    } catch (error) {
      setLoading(false);
      toast.error(`Failed to enrich column with AI: ${error}`);
    }
  };

  const updateColumnData = (
    columnName: string,
    answer: string,
    reason: string,
    link: string
  ) => {
    setNewCols((prevCols) =>
      prevCols.map((col) =>
        col.column_name === columnName
          ? { ...col, columnName, answer, reason, link }
          : col
      )
    );
  };

  const updateColumnName = (oldColumnName: string, newColumnName: string) => {
    setNewCols((prevCols) =>
      prevCols.map((col) =>
        col.column_name === oldColumnName
          ? { ...col, column_name: newColumnName }
          : col
      )
    );
    setShowColumnDetailPop(false);
    setColDetailEl(null);
    updateColumnService(projectId, oldColumnName, newColumnName)
      .then(() => {
        const updatedHeaders = headers.map((header) => {
          if (header.column_name === oldColumnName) {
            return { ...header, column_name: newColumnName };
          }
          return header;
        });

        setHeaders(updatedHeaders);
        const updatedData = filteredData.map((record) => {
          const newRecord = { ...record };
          if (record[oldColumnName] !== undefined) {
            newRecord[newColumnName] = newRecord[oldColumnName];
            delete newRecord[oldColumnName];
          }
          return newRecord;
        });
        setFilteredData(updatedData);
        toast.success("Column updated successfully");
      })
      .catch((e: any) => {
        toast.error(e.message);
      });
  };

  const CustomDropdownIcon = () => {
    return (
      <KeyboardArrowDown
        sx={{
          color: "#6A7383",
        }}
      /> // Replace this with your custom icon
    );
  };
  const colDetailElRef = useRef(null);
  useEffect(() => {
    if (colDetailElRef.current) {
      setShowPromptDetail(true);
    }
  }, [colDetailElRef.current]);
  const renderCell = (row: any, header: any) => {
    const keys = headerMappings[header.column_name]?.split(".");
    var notKeysTitle: any = "";
    let value: any = row;
    if (header.enrichments) {
      if (header.is_enrich_col) {
        const enrichmentKey = header.column_name.split(" - ")[0]?.trim();
        let enrichmentKeyCol = "";
        let enrichmentIndex = 0; // Default to the first index

        // Extract the column type (name, email, etc.)
        enrichmentKeyCol = header.column_name
          .split(" - ")[1]
          ?.trim()
          .toLowerCase();

        // Determine the index number based on the column name (e.g., "Manager Name - 2")
        const match = enrichmentKeyCol.match(/\d+/);

        if (match) {
          enrichmentIndex = parseInt(match[0], 10) - 1; // Convert to zero-based index
        }
        // console.log(enrichmentKeyCol, row[enrichmentKey][enrichmentIndex]);
        // Standardize the enrichment type (name, email, phone, linkedin)
        if (enrichmentKeyCol.includes("name")) {
          enrichmentKeyCol = "name";
        } else if (enrichmentKeyCol.includes("email")) {
          enrichmentKeyCol = "email";
        } else if (enrichmentKeyCol.includes("phone")) {
          enrichmentKeyCol = "phone_number";
        } else if (enrichmentKeyCol.includes("linkedin")) {
          enrichmentKeyCol = "linkedin_url";
        }

        // Access the specific index in the enrichment array

        if (
          enrichmentKey &&
          row[enrichmentKey] &&
          Array.isArray(row[enrichmentKey]) &&
          row[enrichmentKey][enrichmentIndex] &&
          enrichmentKeyCol
        ) {
          notKeysTitle =
            row[enrichmentKey][enrichmentIndex][enrichmentKeyCol] ||
            "Not found";
        } else {
          notKeysTitle = "";
        }
      } else {
        const enrichmentColumn = row[header.column_name];

        if (enrichmentColumn) {
          notKeysTitle = (
            <p
              style={{
                fontSize: 12,
                display: "flex",
                gap: 4,
                alignItems: "center",
              }}>
              <img
                src={matchNotFound}
                style={{
                  height: 12,
                  width: 12,
                }}
              />{" "}
              Match Not Found
            </p>
          );
          for (let index = 0; index < enrichmentColumn.length; index++) {
            const element = enrichmentColumn[index];
            if (
              Object.values(element).some(
                (value) => value !== "" && value !== "Not found"
              )
            ) {
              notKeysTitle = (
                <p
                  style={{
                    fontSize: 12,
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                  }}>
                  <img
                    src={matchFound}
                    style={{
                      height: 12,
                      width: 12,
                    }}
                  />{" "}
                  Match Found
                </p>
              );
              break;
            }
          }
        }
      }
    } else if (row[header.column_name] && row[header.column_name].length) {
      notKeysTitle = row[header.column_name][0].answer || "Not found";
    } else if (row[header.column_name] && row[header.column_name].answer) {
      // console.log(row[header.column_name]);
      notKeysTitle = row[header.column_name].answer || "Not found";
    }
    if (!keys || header.enrichments) {
      return (
        <Tooltip
          key={row}
          sx={{ cursor: "pointer" }}
          onClick={(e: any) => {
            if (!header.enrichments) {
              if (row[header.column_name].length > 0) {
                setSelectedHeader(row[header.column_name][0]);

                setColDetailEl(e.currentTarget);
                setShowPromptDetail(true);
              } else {
                setSelectedHeader(row[header.column_name]);

                setColDetailEl(e.currentTarget);
                setShowPromptDetail(true);
              }
            }
          }}
          title={notKeysTitle}>
          <Typography
            color={theme.palette.primary.main}
            typography='h3'
            fontSize={14}
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            overflow='hidden'
            fontFamily='Gilroy-Medium'>
            {notKeysTitle}
          </Typography>
        </Tooltip>
      );
    }

    keys.forEach((key: any) => {
      if (value[key]) {
        if (typeof value[key] === "object" && value[key].length) {
          value = value[key][0];
        } else {
          value = value[key];
        }
      }
    });

    // Format the date if it's the posted_at field
    if (header.column_name === "Posted" && value) {
      value = formatDate(value);
    }

    if (header.column_name === "Location" && value) {
      const { addressLocality, addressCountry } = value;
      value = [addressLocality, addressCountry].filter(Boolean).join(", ");
    }

    if (value) {
      if (typeof value === "object") {
        value = value.length
          ? value[0].answer || "Not found"
          : value.answer
          ? value.answer || "Not found"
          : "";
      }
    }

    return (
      <Tooltip
        key={row}
        sx={{ cursor: "pointer" }}
        title={typeof value === "object" ? "-" : value}>
        <Typography
          onClick={(e: any) => {
            colDetailElRef.current = e.currentTarget; // Capture currentTarget here

            if (colDetailElRef !== null) {
              if (header.user_generated && value) {
                if (!header.enrichments) {
                  if (row[header.column_name].length > 0) {
                    console.log(e);
                    setSelectedHeader(row[header.column_name][0]);
                    setColDetailEl(colDetailElRef.current); // Use the captured currentTarget
                  } else {
                    setSelectedHeader(row[header.column_name]);
                    setColDetailEl(colDetailElRef.current); // Use the captured currentTarget
                  }
                }
              }
            }
          }}
          typography='h3'
          color={theme.palette.primary.main}
          fontSize={14}
          whiteSpace='nowrap'
          textOverflow='ellipsis'
          overflow='hidden'
          fontFamily='Gilroy-Medium'>
          {typeof value === "object" ? "-" : value}
        </Typography>
      </Tooltip>
    );
  };

  const handleSearch = (searchTerm: string) => {
    if (searchTerm.trim().length === 0) {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((d) =>
          d.company.name.toLowerCase().trim().includes(searchTerm.toLowerCase())
        )
      );
    }
  };

  const extractCellValue = (row: any, header: any) => {
    const keys = headerMappings[header.column_name]?.split(".");

    if (!keys) {
      return row[header.column_name]?.answer || "";
    }

    let value = row;
    keys.forEach((key) => {
      value = value[key] || "";
    });

    // Format the date if it's the posted_at field
    if (header.column_name === "Posted" && value) {
      value = formatDate(value);
    }

    // Concatenate address fields if it's a location field
    if (header.column_name === "Location" && value) {
      const { addressLocality, addressCountry } = value;
      value = [addressLocality, addressCountry].filter(Boolean).join(", ");
    }

    if (typeof value === "object") {
      value = value.answer;
    }

    return value;
  };

  const exportToCSV = () => {
    const headerCols = headers.concat(newCols);

    // Create headers array
    const csvHeaders = headerCols.map((col) => col.column_name);

    // Create data rows array
    const csvData = getDisplayedData().map((row) => {
      return headerCols.map((header) => extractCellValue(row, header));
    });

    // Combine headers and data into a single array
    const csvRows = [csvHeaders, ...csvData];

    // Convert to CSV string
    const csvString = Papa.unparse(csvRows);

    // Trigger file download
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${title}.csv`);
  };

  const [anchorElProject, setAnchorElProject] = useState(null);
  const openProject = Boolean(anchorElProject);
  const projectPopId = open ? "simple-popover-project" : undefined;

  const handleOpenPopover = (event: any) => {
    setAnchorElProject(event.currentTarget);
  };
  const handleCloseProjectPop = () => {
    setAnchorElProject(null);
  };

  const handleDuplicate = async () => {
    setLoading(true);
    handleDuplicateProject(projectRecord);
    setLoading(false);
    handleClose();
  };

  const handleRename = async (id: string, name: string) => {
    setLoading(true);
    handleRenameProject(projectRecord, name);
    setLoading(false);
    handleClose();
    // setSelectedRow(null);
  };

  const handleDelete = async () => {
    setLoading(true);
    handleDeleteProject(projectRecord);
    setLoading(false);
    handleClose();
    // setSelectedRow(null);
  };
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const handleRowHover = (index: number | null) => {
    setHoveredRowIndex(index);
  };

  const runSingleAiEnrich = async (header: any, row: any) => {
    if (!header.is_people_col) {
      try {
        const links = [row.link];
        console.log("the links logged are :", links);
        setAiEnrichRequest(header);
        handleRowSelect(row);
        setAnchorEl(null);
        setShowAIEnrichProgress(true);

        const aiEnrichResponse = await aiEnrichment({
          project_id: projectId,
          column_name: header?.column_name as string,
          // records: [row],
          answer_format: header?.answer_format as string,
          prompt: header?.prompt as string,
          sources: header?.sources as number[],
          links: links as string[],
        });
        setCredits(aiEnrichResponse.credits_left);

        setNewCols(
          newCols.filter((co) => co.column_name !== header?.column_name)
        );

        if (
          headers.filter((h) => h.column_name === header?.column_name)
            .length === 0
        ) {
          setHeaders((prev) => [
            ...prev,
            {
              project_id: projectId,
              column_name: header?.column_name as string,
              records: [],
              answer_format: header?.answer_format as string,
              prompt: header?.prompt as string,
              answer: "",
              reason: "",
              link: "",
              user_generated: true,
              sources: header?.sources as number[],
              links: links as string[],
            },
          ]);
        }
        const updatedData = filteredData.map((record) => {
          const newRecord = aiEnrichResponse.records.find(
            (newRec) => newRec.id === record.id
          );
          return newRecord ? newRecord : record;
        });
        // console.log(updatedData);
        setFilteredData(updatedData);
        // handleRefresh();

        setAiEnrichRequest(null);
        setShowAIEnrichProgress(false);
        setSelectedRows([]);
      } catch (error: any) {
        setShowAIEnrichProgress(false);
        setSelectedRows([]);
        //   setNewCols([]);
        toast.error(error.message);
      }
    } else {
      try {
        console.log("the sources are", aiEnrichRequest?.sources);

        setSelectedRows((prev) => [...prev, row]);
        const links = [row].map((item) => item.link);
        setAiEnrichRequest(header);
        setCurrentId(header?.column_name as string);
        setAnchorEl(null);
        setShowAIEnrichProgress(true);
        const dataSpliced = selectedRows;
        console.log(header);
        console.log(links);
        const aiEnrichResponse = await hiringFindPeople({
          project_id: projectId,
          column_name: header?.column_name as string,
          // records: rowsAdded,
          answer_format: header?.answer_format as string,
          prompt: header?.prompt as string,
          sources: header?.sources as number[],
          titles: header?.titles,
          seniorities: header?.seniorities,
          locations: header?.locations,
          number_of_people: header?.number_of_people,
          enrichments: header?.enrichments,
          links: links as string[],
        });
        setCredits(aiEnrichResponse.credits_left);
        setNewCols(
          newCols.filter(
            (co) =>
              !co.column_name.includes(header?.column_name + " - ") &&
              co.column_name !== header?.column_name
          )
        );

        if (
          headers.filter((h) => h.column_name === header?.column_name)
            .length === 0
        ) {
          if (header?.number_of_people) {
            var newHeaders: NewCol[] = [];
            newHeaders.push({
              column_name: `${header.column_name}`,
              project_id: projectId,
              prompt: header.prompt,
              answer_format: header.answer_format,
              answer: "",
              reason: "",
              link: "",
              links: [],
              sources: [dataSource],
              number_of_people: header?.number_of_people,
              titles: header?.titles,
              locations: header?.locations,
              seniorities: header?.seniorities,
              enrichments: header?.enrichments,
              is_enrich_col: false,
              is_people_col: true,
              user_generated: true,
              records: [],
            });
            for (let i = 0; i < header?.number_of_people; i++) {
              // Main column for the person

              // Enrichment columns for the person
              header?.enrichments?.forEach((enrichment: any, idx: any) => {
                newHeaders.push({
                  column_name: `${header.column_name} - ${
                    enrichment === 1
                      ? "Name"
                      : enrichment === 2
                      ? "Email"
                      : enrichment === 3
                      ? "LinkedIn"
                      : "Phone"
                  } ${i + 1}`,
                  project_id: projectId,
                  prompt: header.prompt,
                  answer_format: header.answer_format,
                  answer: "",
                  reason: "",
                  link: "",
                  links: [],
                  sources: [dataSource],
                  number_of_people: header?.number_of_people,
                  titles: header?.titles,
                  locations: header?.locations,
                  seniorities: header?.seniorities,
                  enrichments: header?.enrichments,
                  is_enrich_col: true,
                  is_people_col: false,
                  user_generated: true,
                  records: [],
                });
                setVisibleColumns((prev) => [
                  ...prev,
                  `${header?.column_name} - ${
                    enrichment === 1
                      ? "Name"
                      : enrichment === 2
                      ? "Email"
                      : enrichment === 3
                      ? "LinkedIn"
                      : "Phone"
                  } ${i + 1}`,
                ]);
              });
            }
            setHeaders((prevHeaders) => [...prevHeaders, ...newHeaders]);
            setVisibleColumns((prev) => [
              ...prev,
              header?.column_name as string,
            ]);
          }
        }

        const updatedData = filteredData.map((record) => {
          const companyName = record.company?.name;
          if (
            companyName &&
            aiEnrichResponse[companyName] &&
            record.id === row.id
          ) {
            // Update the record with the enrichment data
            const enrichmentData = aiEnrichResponse[companyName];
            return {
              ...record,
              [header?.column_name as string]: enrichmentData,
            };
          }
          return record;
        });

        console.log(updatedData);

        setFilteredData(updatedData);
        // handleRefresh();

        setShowRunPop(false);

        setShowAIEnrichProgress(false);
        setShowAIEnrichment(false);
        setSelectedRows([]);

        setAiEnrichRequest(null);
        setUpdateLocalStorage(true);
        // handleRefresh();

        // setNewCols([]);
      } catch (error: any) {
        setShowRunPop(false);
        setAiEnrichRequest(null);
        setShowAIEnrichProgress(false);
        setShowAIEnrichment(false);
        //   setNewCols([]);
        toast.error(error.message);
      }
    }
  };

  const [anchorFilter, setAnchorFilter] = useState<null | HTMLElement>(null);

  const handleToggleColumn = (key: string) => {
    setVisibleColumns((prev) =>
      prev.includes(key) ? prev.filter((col) => col !== key) : [...prev, key]
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingRight: 3,
        paddingLeft: 3,

        flex: 3,
        height: "100vh",
      }}>
      <CssBaseline />
      <Toolbar
        disableGutters
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: 0,
          margin: 0,
        }}>
        <Box padding={0} display='flex' alignItems='center' gap={1}>
          <IconButton
            onClick={() => {
              onClose();
              setHeaderMappings({});
              setAiEnrichRequest(null);
              setHeaders([]);
              setColumnNextCount({});
            }}>
            <ArrowBackIos />
          </IconButton>
          <Typography variant='h1' fontSize={24}>
            {title}
          </Typography>
          <IconButton onClick={handleOpenPopover}>
            <img src={elipsIcon} />
          </IconButton>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          gap={3}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              border: "1px solid #E5E5E5",
              padding: 1,
              borderRadius: 2,
              gap: 2,
            }}>
            <input
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              style={{
                outline: "none",
                border: "none",
                width: "100%",
                fontSize: 14,
                fontFamily: "Gilroy-Medium",
              }}
              placeholder='Search'
            />
            <Box display='flex' gap={1} alignItems='center'>
              <div
                style={{
                  width: 1,
                  padding: 1,
                  backgroundColor: theme.palette.primary.light,
                  height: 16,
                }}></div>
              <Search
                sx={{ color: "#9EA7B5", marginRight: 0 }}
                fontSize='small'
              />
            </Box>
          </Box>

          <Button
            onClick={() => {
              exportToCSV();
            }}
            disabled={selectedRows.length === 0}
            sx={{
              fontWeight: 600,
              fontSize: 16,
              borderRadius: 2,
              border: "1px solid #E5E5E5",
              paddingRight: 6,
              paddingTop: 0.5,
              paddingBottom: 0.5,
              paddingLeft: 6,
              "&:hover": {
                borderColor: "#E5E5E5",
              },
              "&:focus": {
                borderColor: "#E5E5E5",
              },
            }}
            variant='outlined'
            startIcon={<img src={csvIcon} height={16} width={16} />}>
            Export to CSV
          </Button>
        </Box>
      </Toolbar>
      <Divider />
      <Box display='flex' flexDirection='column'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center' justifyContent='flex-start'>
            <IconButton onClick={handleRefresh}>
              <Cached />
            </IconButton>
            <Typography typography='h3' fontFamily='Gilroy-Semibold'>
              Refresh
            </Typography>
          </Box>

          <Box
            display='flex'
            alignItems='center'
            gap={2}
            marginRight={2}
            justifyContent='flex-end'>
            <Typography color='#6A7383' variant='h3' fontFamily='Gilroy-Medium'>
              {selectedRows.length}/{filteredData.length} rows selected
            </Typography>
            <Box
              sx={{
                height: 18,
                width: 2,
                backgroundColor: theme.palette.primary.light,
              }}
            />
            <Box
              display='flex'
              alignItems='center'
              gap={1}
              sx={{
                cursor: "pointer",
              }}>
              <img src={pauseIcon} height={16} width={16} />
              <Typography
                variant='h3'
                fontFamily='Gilroy-Semibold'
                color={theme.palette.primary.main}>
                {headers.length + newCols.length}/
                {headers.length + newCols.length} Columns
              </Typography>
              <IconButton onClick={(e) => setAnchorFilter(e.currentTarget)}>
                <KeyboardArrowDownRounded />
              </IconButton>
            </Box>
            <Box
              sx={{
                height: 18,
                width: 2,
                backgroundColor: theme.palette.primary.light,
              }}
            />
            <Box display='flex' alignItems='center'>
              <Switch color='secondary' />
              <Typography fontFamily='Gilroy-Semibold' variant='h3'>
                Auto update
              </Typography>
            </Box>
          </Box>
          <HeaderFilter
            handleClose={() => {
              setAnchorFilter(null);
            }}
            anchorEl={anchorFilter}
            headers={headers}
            visibleColumns={visibleColumns}
            onToggleColumn={handleToggleColumn}
          />
        </Box>
      </Box>
      <Divider />

      {showTableLoader ? (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'>
          <BurstLoader />
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, overflowX: "auto" }}>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 170px)", // Adjust height to fit the available space
              overflow: "auto",
              boxShadow: "none",
            }}>
            <Table
              stickyHeader={false}
              sx={{
                minWidth: 1000,
                tableLayout: "fixed",
                "& .MuiTableCell-sizeMedium": {
                  padding: "10px 16px",
                },
              }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: "#757575",
                      width: 60,

                      backgroundColor: "white",

                      borderRight: `1px solid #F0F0F0`,
                    }}>
                    <Checkbox
                      onChange={(v) => {
                        if (selectedRows.length === filteredData.length) {
                          setSelectedRows([]);
                        } else {
                          handleRowSelectAll();
                        }
                      }}
                      checked={colChecked}
                      sx={{
                        padding: 0,
                        margin: 0,
                      }}
                    />
                  </TableCell>
                  {headers.map((header, idx) => (
                    <>
                      {visibleColumns.includes(header.column_name) &&
                      header.user_generated ? (
                        <TableCell
                          key={idx}
                          sx={{
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color: "#757575",
                            fontFamily: "Gilroy-Semibold",
                            fontSize: 14,
                            width: 200,
                            borderRight: `1px solid #F0F0F0`,
                          }}>
                          <Box
                            width={"100%"}
                            display='flex'
                            alignItems='center'
                            gap={1}>
                            <img src={astrix} height={14} width={14} />
                            <span
                              onClick={(e: any) => {
                                if (
                                  !header.is_enrich_col &&
                                  !header.is_people_col
                                ) {
                                  setColumnName(header.column_name);
                                  setAiEnrichRequest({
                                    column_name: header.column_name,
                                    prompt: header.prompt,
                                    project_id: header.project_id,
                                    answer_format: header.answer_format,
                                    // records: header.records,
                                    sources: header.sources,
                                    links: header.links,
                                  });
                                  setRequestPrompt(header.prompt);
                                  setAnswerFormat(header.answer_format);
                                  setColDetailEl(e.currentTarget);
                                  setShowColumnDetailPop(true);
                                }
                              }}
                              style={{
                                color: "#757575",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                // width: 20,
                                textAlign: "start",

                                fontFamily: "Gilroy-Semibold",
                                fontSize: 14,
                              }}>
                              {header.column_name}
                            </span>
                            {!header.is_enrich_col && (
                              <Button
                                startIcon={<PlayArrow />}
                                sx={{
                                  backgroundColor: "#F4F4F4",
                                  borderRadius: 50,
                                  paddingRight: 2,
                                  paddingLeft: 2,
                                  paddingTop: 0.4,
                                  paddingBottom: 0.4,
                                }}
                                onClick={(e: any) => {
                                  setColumnName(header.column_name);
                                  setAiEnrichRequest(header);
                                  setRequestPrompt(header.prompt);
                                  setAnswerFormat(header.answer_format);
                                  setAnchorEl(e.currentTarget);
                                  setShowRunPop(true);
                                }}
                                variant='text'
                                color='secondary'>
                                Run
                              </Button>
                            )}
                          </Box>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={idx}
                          sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#757575",
                            borderRight: `1px solid #F0F0F0`,
                            width: 180,
                            whiteSpace: "nowrap",
                            fontFamily: "Gilroy-Semibold",
                            position: idx === 0 ? "sticky" : "",
                          }}>
                          {header.column_name}
                        </TableCell>
                      )}
                    </>
                  ))}
                  {newCols.map((header, idx) => {
                    return (
                      <TableCell
                        key={idx}
                        sx={{
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "#757575",
                          fontFamily: "Gilroy-Semibold",
                          fontSize: 14,
                          width: 200,
                          borderRight: `1px solid #F0F0F0`,
                        }}>
                        <Box
                          width={"100%"}
                          display='flex'
                          alignItems='center'
                          gap={1}>
                          <img src={astrix} height={14} width={14} />
                          <span
                            onClick={(e: any) => {
                              if (!header.is_enrich_col) {
                                setColDetailEl(e.currentTarget);
                                setShowColumnDetailPop(true);
                              }

                              setAiEnrichRequest(header);
                            }}
                            style={{
                              color: "#757575",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              // width: 20,

                              fontFamily: "Gilroy-Semibold",
                              fontSize: 14,
                              textAlign: "start",
                            }}>
                            {header.column_name}
                          </span>
                          {!header.is_enrich_col && (
                            <Button
                              startIcon={<PlayArrow />}
                              sx={{
                                backgroundColor: "#F4F4F4",
                                borderRadius: 50,
                                paddingRight: 2,
                                paddingLeft: 2,
                                paddingTop: 0.4,
                                paddingBottom: 0.4,
                              }}
                              onClick={(e: any) => {
                                setAnchorEl(e.currentTarget);
                                setShowRunPop(true);
                                setColumnName(header.column_name);
                                setAiEnrichRequest(header);

                                // console.log(header);
                              }}
                              variant='text'
                              color='secondary'>
                              Run
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                    );
                  })}
                  <TableCell
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: "#757575",
                      borderRight: `1px solid #F0F0F0`,
                      width: 180,
                      whiteSpace: "nowrap",

                      fontFamily: "Gilroy-Semibold",

                      cursor: "pointer",
                    }}
                    onClick={handleClick}>
                    <Box display='flex' alignItems='center' gap={1}>
                      <Add />
                      <Typography
                        fontWeight={600}
                        variant='h3'
                        color={theme.palette.primary.main}>
                        Add Column
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getDisplayedData().map((row, rowIdx) => (
                  <TableRow
                    selected={selectedRows.includes(row)}
                    key={rowIdx}
                    onMouseEnter={() => handleRowHover(rowIdx)}
                    onMouseLeave={() => handleRowHover(null)}
                    hover>
                    <TableCell
                      sx={{
                        width: 180,
                        whiteSpace: "nowrap",
                        borderRight: `1px solid #F0F0F0`,

                        // backgroundColor:  ,
                      }}>
                      <Box>
                        <Checkbox
                          onChange={(v) => {
                            handleRowSelect(row);
                          }}
                          key={rowIdx}
                          checked={selectedRows.includes(row)}
                          sx={{
                            padding: 0,
                          }}
                        />
                      </Box>
                    </TableCell>

                    {headers.map((header, idx) => {
                      return !header.user_generated ? (
                        <TableCell
                          key={idx}
                          sx={{
                            borderLeft: "1px solid #F0F0F0",

                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: 200,

                            // backgroundColor: "white",
                          }}>
                          {renderCell(row, header)}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={idx}
                          sx={{
                            borderLeft: "1px solid #F0F0F0",

                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                            width: 200,
                          }}>
                          {showAIEnrichProgress &&
                          selectedRows.includes(row) &&
                          aiEnrichRequest?.column_name ===
                            header.column_name ? (
                            <Box
                              width='100%'
                              display='flex'
                              alignItems='center'
                              gap={1}
                              justifyContent='center'>
                              <BurstLoader fontSize='16px' />
                              <Typography
                                variant='h4'
                                fontFamily='Gilroy-Medium-Italic'
                                color='#ADADAD'>
                                Loading...
                              </Typography>
                            </Box>
                          ) : (
                            <Typography
                              display='flex'
                              alignItems='center'
                              key={idx}
                              justifyContent='space-between'>
                              {renderCell(row, header)}
                              {rowIdx === hoveredRowIndex &&
                                header.user_generated &&
                                !header.is_enrich_col && (
                                  <Button
                                    startIcon={<PlayArrow />}
                                    sx={{
                                      backgroundColor: "#F4F4F4",
                                      borderRadius: 50,
                                      paddingRight: 2,
                                      paddingLeft: 2,
                                      paddingTop: 0.4,
                                      paddingBottom: 0.4,
                                    }}
                                    onClick={(e: any) => {
                                      runSingleAiEnrich(header, row);
                                    }}
                                    variant='text'
                                    color='secondary'>
                                    Run
                                  </Button>
                                )}
                            </Typography>
                          )}
                        </TableCell>
                      );
                    })}
                    {newCols.map((header, idx) => {
                      return !header.user_generated ? (
                        <TableCell
                          key={idx}
                          sx={{
                            borderLeft: "1px solid #F0F0F0",

                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: 200,

                            // backgroundColor: "white",
                          }}>
                          {renderCell(row, header)}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={idx}
                          sx={{
                            borderLeft: "1px solid #F0F0F0",

                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                            width: 200,
                          }}>
                          {showAIEnrichProgress &&
                          selectedRows.includes(row) &&
                          aiEnrichRequest?.column_name ===
                            header.column_name ? (
                            <Box
                              width='100%'
                              display='flex'
                              alignItems='center'
                              gap={1}
                              justifyContent='center'>
                              <BurstLoader fontSize='16px' />
                              <Typography
                                variant='h4'
                                fontFamily='Gilroy-Medium-Italic'
                                color='#ADADAD'>
                                Loading...
                              </Typography>
                            </Box>
                          ) : (
                            <Typography
                              display='flex'
                              alignItems='center'
                              justifyContent='space-between'>
                              {renderCell(row, header)}
                              {rowIdx === hoveredRowIndex &&
                                header.user_generated &&
                                !header.is_enrich_col && (
                                  <Button
                                    startIcon={<PlayArrow />}
                                    sx={{
                                      backgroundColor: "#F4F4F4",
                                      borderRadius: 50,
                                      paddingRight: 2,
                                      paddingLeft: 2,
                                      paddingTop: 0.4,
                                      paddingBottom: 0.4,
                                    }}
                                    onClick={(e: any) => {
                                      runSingleAiEnrich(header, row);
                                    }}
                                    variant='text'
                                    color='secondary'>
                                    Run
                                  </Button>
                                )}
                            </Typography>
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      sx={{ borderLeft: "1px solid #F0F0F0" }}></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {showRunPopup && (
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}>
                <Box
                  p={2}
                  display='flex'
                  flexDirection='column'
                  alignItems='flex-start'
                  justifyContent='flex-start'>
                  <Button
                    onClick={() => {
                      runAiEnrich(
                        10,
                        filteredData,
                        aiEnrichRequest?.is_people_col as boolean
                      );
                    }}
                    style={{ color: "#757575", fontSize: 14 }}>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'>
                      <Typography variant='h3'>Run next 10 rows</Typography>
                      <PeopleChips
                        chips={`${
                          aiEnrichRequest?.enrichments
                            ? calculateCreditsUsed(10, aiEnrichRequest)
                            : 10
                        }`}
                      />
                    </Box>
                  </Button>
                  <Button style={{ color: "#757575", fontSize: 14 }}>
                    <Box
                      onClick={() => {
                        runAiEnrich(
                          100,
                          filteredData,
                          aiEnrichRequest?.is_people_col as boolean
                        );
                      }}
                      display='flex'
                      alignItems='center'
                      gap={1}>
                      <Typography variant='h3'>Run next 100 rows</Typography>
                      <PeopleChips
                        chips={`${
                          aiEnrichRequest?.enrichments
                            ? calculateCreditsUsed(100, aiEnrichRequest)
                            : 100
                        }`}
                      />
                    </Box>
                  </Button>
                  <Button
                    onClick={() => {
                      runAiEnrich(
                        1000,
                        filteredData,
                        aiEnrichRequest?.is_people_col as boolean
                      );
                    }}
                    style={{ color: "#757575", fontSize: 14 }}>
                    <Box display='flex' alignItems='center' gap={1}>
                      <Typography variant='h3'>
                        Run all selected rows
                      </Typography>
                      <PeopleChips
                        chips={`${
                          aiEnrichRequest?.enrichments
                            ? calculateCreditsUsed(
                                selectedRows.length,
                                aiEnrichRequest
                              )
                            : filteredData.length * 1
                        }`}
                      />
                    </Box>
                  </Button>
                </Box>
              </Popover>
            )}

            {showAIEnrichment && (
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                  borderRadius: 2,
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}>
                <Box
                  p={2}
                  display='flex'
                  flexDirection='column'
                  alignItems='flex-start'
                  width={450}
                  justifyContent='flex-start'>
                  <Box
                    display='flex'
                    alignItems='center'
                    width='100%'
                    justifyContent='space-between'>
                    <Typography fontSize={20} fontWeight={600}>
                      Create new column using AI
                    </Typography>
                    <IconButton onClick={handleClose}>
                      <Close />
                    </IconButton>
                  </Box>

                  <Box sx={{ mt: 2, width: "100%" }}>
                    <Typography
                      typography='h3'
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      fontWeight={600}>
                      Name your column
                    </Typography>

                    <TextField
                      fullWidth
                      //   label='Enter link'
                      placeholder='Tech Stack'
                      variant='outlined'
                      onChange={(e) => {
                        setColumnName(e.target.value);
                      }}
                      sx={{
                        mt: 2,
                        padding: 0,
                      }}
                    />
                    {/* <Typography mt={2} typography='h4' fontWeight={600}>
                      Provide a name that you want to give to this column
                    </Typography> */}
                  </Box>

                  <Box sx={{ mt: 2, width: "100%" }}>
                    <Typography
                      typography='h3'
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      fontWeight={600}>
                      Request Prompt
                    </Typography>
                    <TextField
                      placeholder='For each company, tell me the tech stack they are using'
                      multiline
                      rows={4}
                      variant='outlined'
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "auto",
                        },
                        mt: 1,
                      }}
                      onChange={(e) => {
                        setRequestPrompt(e.target.value);
                      }}
                    />
                    <Typography
                      typography='h4'
                      sx={{
                        mt: 1,
                      }}
                      color='#ADADAD'
                      fontWeight={500}>
                      Describe some information you'd like to automatically
                      retrieve or generate about a company, person, or row.
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 3, width: "100%", pr: 10 }}>
                    <Typography
                      typography='h3'
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      fontWeight={600}>
                      Data Source
                    </Typography>
                    <Box
                      alignItems='center'
                      justifyContent='flex-start'
                      sx={{ mt: 2 }}>
                      <Box display='flex' justifyContent='space-between'>
                        <Box display='flex' alignItems='center'>
                          <Radio
                            color={"secondary"}
                            checked={dataSource === 0}
                            onChange={(e) => setDataSource(0)}
                            name='data-source'
                            inputProps={{ "aria-label": "Job Description" }}
                          />
                          <Typography
                            typography='h4'
                            fontWeight={500}
                            sx={{ ml: 0, color: "#666666" }}>
                            Job Description
                          </Typography>
                        </Box>
                        <Box display='flex' alignItems='center'>
                          <Radio
                            color={"secondary"}
                            checked={dataSource === 1}
                            onChange={(e) => setDataSource(1)}
                            name='data-source'
                            inputProps={{ "aria-label": "Website" }}
                            sx={{ ml: 0 }}
                          />
                          <Typography
                            typography='h4'
                            fontWeight={500}
                            sx={{ ml: 0, color: "#666666" }}>
                            Website
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}>
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyItems='center'>
                          <Radio
                            color={"secondary"}
                            checked={dataSource === 2}
                            onChange={(e) => setDataSource(2)}
                            name='data-source'
                            inputProps={{
                              "aria-label": "Annual Report (Latest)",
                            }}
                          />
                          <Typography
                            typography='h4'
                            fontWeight={500}
                            sx={{ ml: 0, color: "#666666" }}>
                            Annual Report (Latest)
                          </Typography>
                        </Box>
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='flex-start'
                          sx={{ pr: 1.3 }}>
                          <Radio
                            color={"secondary"}
                            checked={dataSource === 3}
                            onChange={(e) => setDataSource(3)}
                            name='data-source'
                            inputProps={{ "aria-label": "News" }}
                            sx={{ ml: 0 }}
                          />
                          <Typography
                            typography='h4'
                            fontWeight={500}
                            sx={{ ml: 0, color: "#666666" }}>
                            News
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={2} width='100%'>
                    <Typography
                      typography='h3'
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      fontWeight={600}>
                      Answer format
                    </Typography>
                    <TextField
                      fullWidth
                      select
                      placeholder='Yes/No'
                      variant='outlined'
                      defaultValue=''
                      onChange={(e) => {
                        setAnswerFormat(e.target.value);
                      }}
                      SelectProps={{
                        native: true,
                        IconComponent: CustomDropdownIcon,
                      }}
                      inputProps={{
                        style: {
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingRight: 8,
                          paddingLeft: 8,
                          textAlign: "start",

                          color: theme.palette.primary.main,
                        },
                      }}
                      sx={{ mt: 2 }}>
                      <option value={"Yes/No"}>Yes/No</option>
                      <option value={"String"}>String</option>
                      <option value={"Number"}>Number</option>
                    </TextField>
                  </Box>

                  {loading ? (
                    <Box
                      width='100%'
                      mt={2}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'>
                      <BurstLoader />
                    </Box>
                  ) : (
                    <Button
                      variant='contained'
                      sx={{
                        marginTop: 2,
                      }}
                      onClick={handleSaveColumnName}
                      fullWidth>
                      Save
                    </Button>
                  )}
                </Box>
              </Popover>
            )}

            {!showAIEnrichment && !showRunPopup && (
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}>
                <Box
                  p={2}
                  display='flex'
                  flexDirection='column'
                  alignItems='flex-start'
                  justifyContent='flex-start'>
                  <Button
                    startIcon={<img src={aiEnrich} height={16} width={16} />}
                    onClick={() => {
                      setShowAIEnrichment(true);
                    }}
                    style={{ color: "#757575", fontSize: 14 }}>
                    AI Enrichment
                  </Button>
                  <Button
                    onClick={(e: any) => {
                      setPeoplePopoverAnchor(e.currentTarget);
                    }}
                    startIcon={<img src={peopleIcon} height={16} width={16} />}
                    style={{ color: "#757575", fontSize: 14 }}>
                    <Box display='flex' alignItems='center' gap={1}>
                      People
                      {/* <Box
                        sx={{
                          borderRadius: 50,
                          padding: "0.5em 1em",
                          backgroundColor: "#FFE7E6",
                        }}>
                        <Typography
                          fontSize={10}
                          color={theme.palette.secondary.main}>
                          Coming soon
                        </Typography>
                      </Box> */}
                    </Box>
                  </Button>
                </Box>
              </Popover>
            )}

            {showColumnDetailPop && (
              <Popover
                id={showColumnDetailPop ? "cols-pop" : undefined}
                open={showColumnDetailPop}
                anchorEl={colDetailEl}
                onClose={() => {
                  setColDetailEl(null);
                  setShowColumnDetailPop(false);
                }}
                sx={{
                  borderRadius: 2,
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}>
                <Box
                  p={2}
                  display='flex'
                  flexDirection='column'
                  alignItems='flex-start'
                  width={450}
                  justifyContent='flex-start'>
                  <Box sx={{ mt: 2, width: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        display: "flex",
                      }}>
                      <Typography
                        typography='h3'
                        sx={{
                          color: theme.palette.primary.main,
                        }}
                        fontWeight={600}>
                        Column Name
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setColDetailEl(null);
                          setShowColumnDetailPop(false);
                        }}>
                        <Close></Close>
                      </IconButton>
                    </Box>

                    <TextField
                      fullWidth
                      //   label='Enter link'
                      defaultValue={aiEnrichRequest?.column_name}
                      variant='outlined'
                      onChange={(e) => {
                        setColumnName(e.target.value);
                      }}
                      sx={{
                        mt: 2,
                        padding: 0,
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography mt={2} typography='h4' fontWeight={600}>
                      Provide a name that you want to give to this column
                    </Typography>
                  </Box>

                  <Box sx={{ mt: 2, width: "100%" }}>
                    <Typography
                      typography='h3'
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      fontWeight={600}>
                      Request Prompt
                    </Typography>
                    <TextField
                      placeholder='For each company, tell me if they are using “Mixpanel” in their product team'
                      multiline
                      rows={4}
                      disabled
                      value={aiEnrichRequest?.prompt}
                      variant='outlined'
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "auto",
                        },
                        mt: 1,
                      }}
                    />
                  </Box>
                  <Box mt={2} width='100%'>
                    <Typography
                      typography='h3'
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      fontWeight={600}>
                      Answer format
                    </Typography>
                    <TextField
                      fullWidth
                      select
                      placeholder='Yes/No'
                      variant='outlined'
                      defaultValue=''
                      SelectProps={{
                        native: true,
                        IconComponent: CustomDropdownIcon,
                      }}
                      disabled
                      inputProps={{
                        style: {
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingRight: 8,
                          paddingLeft: 8,
                          textAlign: "start",

                          color: theme.palette.primary.main,
                        },
                      }}
                      sx={{ mt: 2 }}>
                      <option value={"Yes/No"}>
                        {aiEnrichRequest?.answer_format}
                      </option>
                    </TextField>
                  </Box>

                  {loading ? (
                    <Box
                      width='100%'
                      mt={2}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'>
                      <BurstLoader />
                    </Box>
                  ) : (
                    <Box
                      display='flex'
                      width='100%'
                      alignItems='center'
                      justifyContent='space-between'>
                      <Button
                        startIcon={<Delete />}
                        variant='outlined'
                        color='secondary'
                        sx={{
                          marginTop: 2,
                        }}
                        onClick={() => {
                          setNewCols((prevCols) =>
                            prevCols.filter(
                              (col) =>
                                col.column_name !== aiEnrichRequest?.column_name
                            )
                          );
                          setNewCols((prevCols) =>
                            prevCols.filter(
                              (col) =>
                                !col.column_name.startsWith(
                                  aiEnrichRequest?.column_name + " - "
                                )
                            )
                          );

                          setUpdateLocalStorage(true);
                          setColDetailEl(null);
                          setShowColumnDetailPop(false);
                          deleteColumn(
                            projectId,
                            aiEnrichRequest?.column_name as string
                          )
                            .then(() => {
                              toast.success("Column deleted successfully");
                              setHeaders(
                                headers.filter(
                                  (co) =>
                                    !co.column_name.includes(
                                      aiEnrichRequest?.column_name + " - "
                                    ) &&
                                    co.column_name !==
                                      aiEnrichRequest?.column_name
                                )
                              );
                              setNewCols(
                                newCols.filter(
                                  (co) =>
                                    !co.column_name.includes(
                                      aiEnrichRequest?.column_name + " - "
                                    ) &&
                                    co.column_name !==
                                      aiEnrichRequest?.column_name
                                )
                              );
                              setUpdateLocalStorage(true);
                            })
                            .catch((e: any) => {
                              toast.error(e.message);
                            });
                        }}>
                        Delete
                      </Button>
                      <Button
                        variant='contained'
                        sx={{
                          marginTop: 2,
                        }}
                        onClick={() => {
                          updateColumnName(
                            aiEnrichRequest?.column_name as string,
                            columnName
                          );
                        }}>
                        Save
                      </Button>
                    </Box>
                  )}
                </Box>
              </Popover>
            )}
            {showPromptDetail && (
              <EnrichmentColDetail
                selectedHeader={selectedHeader}
                setColDetailEl={setColDetailEl}
                setSelectedHeader={setSelectedHeader}
                setShowPromptDetail={setShowPromptDetail}
                showPromptDetail={showPromptDetail}
                colDetailEl={colDetailEl}
              />
            )}
          </TableContainer>
        </Box>
      )}

      <Box display='flex' flexDirection='column' marginTop='auto'>
        <Divider sx={{}} />
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            padding: 0.5,
          }}>
          <Box display='flex' alignItems='center' justifyContent='flex-start'>
            <Typography typography='h3'>View</Typography>
            <TextField
              select
              variant='outlined'
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              SelectProps={{
                native: true,
                IconComponent: CustomDropdownIcon,
              }}
              inputProps={{
                style: {
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingRight: 4,
                  paddingLeft: 4,
                  textAlign: "start",
                  marginRight: 0,
                  color: "#6A7383",
                },
              }}
              sx={{
                padding: 1,
              }}>
              {[10, 20, 50, 100].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <Typography typography='h3'>rows per page</Typography>
          </Box>

          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            <Typography color='#6A7383'>
              Showing {filteredData.length > 0 ? page + 1 : 0}-
              {Math.min((page + 1) * rowsPerPage, filteredData.length)} of{" "}
              {filteredData.length}
            </Typography>

            <Pagination
              totalPages={totalPages}
              currentPage={page}
              handlePageChange={handleChangePage}
            />
          </Box>
        </Box>
        <Divider sx={{}} />
      </Box>
      <Popover
        id={projectPopId}
        open={openProject}
        anchorEl={anchorElProject}
        onClose={handleCloseProjectPop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        {!loading ? (
          <Box
            p={2}
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            fontFamily='Gilroy-Medium'
            justifyContent='flex-start'>
            <Button
              startIcon={<img src={duplicate} height={16} width={16} />}
              onClick={handleDuplicate}
              style={{ color: "#757575", fontSize: 14 }}>
              Duplicate
            </Button>
            <Button
              startIcon={<img src={rename} height={16} width={16} />}
              onClick={() => {
                setRenameDialogOpen(true);
              }}
              style={{ color: "#757575", fontSize: 14 }}>
              Rename
            </Button>
            <Button
              startIcon={<img src={deleteIcon} height={16} width={16} />}
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
              style={{
                color: theme.palette.secondary.main,
                fontSize: 14,
              }}>
              Delete
            </Button>
          </Box>
        ) : (
          <Box padding={4}>
            <BurstLoader />
          </Box>
        )}
      </Popover>
      <AddPeoplePopOver
        anchorEl={peoplePopoverAnchor}
        handleSaveColumnName={handleSavePeopleColumnName}
        handleClosePopover={handleClosePeoplePopover}
      />
      <RenameDialog
        open={isRenameDialogOpen}
        selectedRow={projectRecord}
        onClose={() => setRenameDialogOpen(false)}
        onRename={handleRename}
      />

      <DeleteDialog
        open={isDeleteDialogOpen}
        selectedRow={projectRecord}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleDelete}
      />
    </Box>
  );
};

export default Spreadsheet;
