// Sidebar.js
import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  LinearProgress,
  Avatar,
  Container,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Home, Info, ExitToApp, Refresh } from "@mui/icons-material";
import Logo from "../common/Logo";
import home from "../../assets/images/home.png";
import add from "../../assets/images/add.png";
import theme from "../../theme";
import looper from "../../assets/images/listen-bravo-logo.png";
import logout from "../../assets/images/logout.svg";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import userIcon from "../../assets/images/user-icon.svg";

type SidebarProps = {
  credits: number;
  onCreateNew: () => void;
  onClose: () => void;
};

const Sidebar: React.FC<SidebarProps> = ({ credits, onCreateNew, onClose }) => {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { user } = useAuth();
  const handleSignout = async () => {
    try {
      await signOut();
      navigate("/signin");
    } catch (error: any) {
      toast.error(error.message);
      console.error("Error signing out:", error);
    }
  };
  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      <CssBaseline />
      <Drawer
        sx={{
          flexShrink: 0,

          "& .MuiDrawer-paper": {
            boxSizing: "content-box",
            border: "none",
            backgroundColor: "#F9F9F9",
          },
        }}
        variant='permanent'
        anchor='left'>
        <Toolbar>
          <Logo />
        </Toolbar>
        <Box sx={{ p: 2 }}>
          <Container
            sx={{
              padding: 2,
              backgroundColor: "#F3F3F3",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
            <Box
              onClick={onClose}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
              }}>
              <img src={home} height={13} width={13} />
              <Typography typography='h2' fontFamily='Gilroy-Medium'>
                My Projects
              </Typography>
            </Box>
            <Tooltip title='Create new'>
              <img
                onClick={onCreateNew}
                src={add}
                height={13}
                width={13}
                style={{
                  cursor: "pointer",
                  marginTop: 1,
                }}
              />
            </Tooltip>
          </Container>
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ p: 2 }}>
          <Divider />
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            mt={2}>
            <Typography
              typography='h3'
              fontFamily='Gilroy-Semibold'
              color={theme.palette.primary.main}>
              Free Trial
            </Typography>
            <Typography
              typography='h3'
              fontFamily='Gilroy-Medium'
              color='#ADADAD'>
              90 days left
            </Typography>
          </Box>
          {/* <Typography
            typography='h4'
            fontFamily='Gilroy-Medium'
            marginTop={1}
            color={theme.palette.primary.main}>
            Trial expires on 25 Oct 2024
          </Typography> */}
          <Box marginTop={2}>
            <LinearProgress
              sx={{
                height: 10,
                borderRadius: 50,
                backgroundColor: theme.palette.primary.light,
                "& .MuiLinearProgress-bar": {
                  borderRadius: 50,
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
              variant='determinate'
              color='secondary'
              value={((1000 - credits) / 1000) * 100}
            />
            <Typography
              typography='h3'
              fontFamily='Gilroy-Medium'
              marginTop={1}
              color='#666666'>
              {credits}/50 credits left
            </Typography>
          </Box>
          <Button
            onClick={() => {
              window.open("https://cal.com/listenbravo/talk-to-us");
            }}
            variant='contained'
            fullWidth
            sx={{ mt: 2, fontFamily: "Gilroy-Semibold" }}>
            Upgrade
          </Button>
          <Box display='flex' alignItems='center' mt={2} gap={1}>
            <img src={userIcon} height={32} width={32} />
            <Box sx={{ overflow: "hidden" }}>
              <Typography
                variant='h3'
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: theme.palette.primary.main,
                }}
                fontFamily='Gilroy-Semibold'>
                {/* {user?.id} */}
                {user?.user_metadata?.full_name}
              </Typography>
              <Typography
                variant='h4'
                color='textSecondary'
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: theme.palette.primary.main,
                }}
                fontFamily='Gilroy-Medium'>
                {user?.email}
              </Typography>
            </Box>
            <IconButton
              onClick={handleSignout}
              edge='end'
              color='inherit'
              aria-label='refresh'
              sx={{ ml: "auto" }}>
              <Tooltip title='Logout'>
                <img src={logout} />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
