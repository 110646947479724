import { apiService } from "./apiService";

export const updateColumnService = async (
  project_id: string,
  old_column: string,
  new_column: string
): Promise<void> => {
  const response: any = await apiService.post<string>(
    `/hiring/rename-column?project_id=${project_id}&old_column=${old_column}&new_column=${new_column}`,
    {}
  );
};
