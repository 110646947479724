import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import BurstLoader from "../common/BurstLoader";
import { toast } from "react-toastify";
import { onboardUser } from "../../service/onBoardUser";
import { useAuth } from "../../context/AuthContext";
import { supabase } from "../../supabaseClient";

interface LetsStartDialogProps {
  open: boolean;
  onClose: () => void;
}

const LetsStartDialog: React.FC<LetsStartDialogProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    company: "",
    homepage: "",
    role: "",
    solution: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveProfile = async () => {
    setLoading(true);
    try {
      if (
        user?.email?.trim().length === 0 ||
        formData.company.trim().length === 0 ||
        formData.homepage.trim().length === 0 ||
        formData.role.trim().length === 0 ||
        formData.solution.trim().length === 0
      ) {
        toast.error("All fields are mandatory");
      } else {
        const response = await onboardUser({
          email: user?.email as string,
          company: formData.company,
          homepage: formData.homepage,
          role: formData.role,
          solution: formData.solution,
        });
        localStorage.setItem("profileCreated", "true");
        toast.success("Profile saved successfully!");
        onClose();
      }
    } catch (error) {
      toast.error("Failed to save profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogContent
        sx={{
          marginTop: 4,
        }}>
        <Box
          paddingRight={4}
          paddingLeft={4}
          component='form'
          noValidate
          autoComplete='off'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ mb: 2 }}>
                <Typography
                  typography='h1'
                  sx={{
                    color: theme.palette.primary.main,
                  }}>
                  Let's start with the context
                </Typography>
                <Typography
                  typography='h3'
                  sx={{
                    color: "#757575",
                    marginTop: 1,
                  }}
                  fontFamily='Gilroy-Semibold'>
                  Understanding your business context is crucial. Our LLM
                  delivers tailored insights to enhance your decision-making.
                  Let's get started.
                </Typography>
                <Typography
                  typography='h2'
                  sx={{
                    color: theme.palette.primary.main,
                    marginTop: 4,
                  }}
                  fontFamily='Gilroy-Semibold'>
                  Company Name
                </Typography>
                <Typography mt={1} typography='h4'>
                  Name of your company
                </Typography>
                <TextField
                  fullWidth
                  label='Enter name of your company'
                  name='company'
                  variant='outlined'
                  sx={{
                    mt: 2,
                    fontFamily: "Gilroy-Medium",
                    fontSize: 16,
                    ".MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E5E5E5",
                        outlineColor: "#E5E5E5",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#E5E5E5",
                        outlineColor: "#E5E5E5",
                      },
                    },
                  }}
                  value={formData.company}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ mb: 2 }}>
                <Typography
                  typography='h2'
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                  fontFamily='Gilroy-Semibold'>
                  Website link
                </Typography>
                <Typography mt={1} typography='h4'>
                  Company webpage URL
                </Typography>
                <TextField
                  fullWidth
                  label='Enter link'
                  name='homepage'
                  variant='outlined'
                  sx={{
                    mt: 1,
                    fontFamily: "Gilroy-Medium",
                    fontSize: 16,
                    ".MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E5E5E5",
                        outlineColor: "#E5E5E5",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#E5E5E5",
                        outlineColor: "#E5E5E5",
                      },
                    },
                  }}
                  value={formData.homepage}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ mb: 2 }}>
                <Typography
                  typography='h2'
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                  fontFamily='Gilroy-Semibold'>
                  Your Role
                </Typography>
                <Typography mt={1} typography='h4'>
                  Role or department you are from
                </Typography>
                <TextField
                  fullWidth
                  label='Enter your role'
                  name='role'
                  variant='outlined'
                  sx={{
                    mt: 1,
                    fontFamily: "Gilroy-Medium",
                    fontSize: 16,
                    ".MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E5E5E5",
                        outlineColor: "#E5E5E5",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#E5E5E5",
                        outlineColor: "#E5E5E5",
                      },
                    },
                  }}
                  value={formData.role}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ mb: 2 }}>
                <Typography
                  typography='h2'
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                  fontFamily='Gilroy-Semibold'>
                  Solution
                </Typography>
                <Typography mt={1} typography='h4'>
                  Solution space or category (eg. CRM)
                </Typography>
                <TextField
                  fullWidth
                  label='Enter your solution space'
                  name='solution'
                  variant='outlined'
                  sx={{
                    mt: 2,
                    fontFamily: "Gilroy-Medium",
                    fontSize: 16,
                    ".MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E5E5E5",
                        outlineColor: "#E5E5E5",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#E5E5E5",
                        outlineColor: "#E5E5E5",
                      },
                    },
                  }}
                  value={formData.solution}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent='space-between'
              alignItems='center'>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "#666666",
                  fontFamily: "Gilroy-Semibold",
                  fontSize: 16,
                  cursor: "pointer",
                }}
                onClick={onClose}>
                Skip
              </Typography>
              {loading ? (
                <BurstLoader />
              ) : (
                <Button
                  onClick={handleSaveProfile}
                  variant='contained'
                  sx={{
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 4,
                    paddingLeft: 4,
                  }}
                  color='primary'>
                  Save and Continue
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LetsStartDialog;
