import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardActionArea,
  Grid,
  CardActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import code from "../../assets/images/code.svg";
import technoGraphy from "../../assets/images/technography.svg";
import trustRadius from "../../assets/images/trust-radius.svg";
import hiring from "../../assets/images/hiring.svg";
import g20 from "../../assets/images/competitor.svg";
import {
  ArrowBackIos,
  ArrowForwardIos,
  FileUpload,
  Upload,
  UploadFile,
} from "@mui/icons-material";
import GridCardWithAction from "./GridCardWithAction";
import HiringSignalDialog from "./HiringSignalDialog";

interface NewAccountDialogProps {
  open: boolean;
  onClose: () => void;
  onBackAction: () => void;
  onHiringOpen: () => void;
}

const NewAccountDialog: React.FC<NewAccountDialogProps> = ({
  open,
  onClose,
  onBackAction,
  onHiringOpen,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <DialogContent
        sx={{
          marginTop: 0,
          marginBottom: 0,
          padding: 6,
        }}>
        <Box
          width='100%'
          display='flex'
          alignItems='center'
          marginBottom={2}
          justifyContent='space-between'>
          <Box display='flex' alignItems='center' justifyContent='flex-start'>
            <IconButton aria-label='close' onClick={onBackAction}>
              <ArrowBackIos
                sx={{
                  height: 14,
                  width: 14,
                }}
              />
            </IconButton>
            <Typography variant='h2'>Back</Typography>
          </Box>
          <IconButton aria-label='close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography marginBottom={1} variant='h1'>
          Create new project
        </Typography>
        <Typography marginBottom={3} variant='h3'>
          Select primary data source for your discovery and enrichment effort
        </Typography>
        <Grid columns={18} container spacing={2} justifyContent='center'>
          <GridCardWithAction
            action={onHiringOpen}
            icon={hiring}
            title='Hiring Signal'
            subtitle='Get accounts hiring in a particular field, department, region..'
            actionIcon={<ArrowForwardIos />}
            isComingSoon={false}
          />
          <GridCardWithAction
            action={onHiringOpen}
            icon={hiring}
            title='Job Change'
            subtitle='Discover which stakeholders has shifted jobs..'
            actionIcon={<ArrowForwardIos />}
            isComingSoon={true}
          />
          <GridCardWithAction
            action={() => {}}
            icon={g20}
            title='Competitors Accounts'
            subtitle='GListen to what buyers are saying on using G2..'
            actionIcon={<ArrowForwardIos />}
            isComingSoon={true}
          />
          <GridCardWithAction
            action={() => {}}
            icon={trustRadius}
            title='Competitor Accounts'
            subtitle='Listen to what buyers saying on Trustradius..'
            actionIcon={<ArrowForwardIos />}
            isComingSoon={true}
          />
          <GridCardWithAction
            action={() => {}}
            icon={technoGraphy}
            title='Technographic'
            subtitle='Get accounts using a certain tech stack, product, or process..'
            actionIcon={<ArrowForwardIos />}
            isComingSoon={true}
          />
          <GridCardWithAction
            action={() => {
              window.open("https://cal.com/listenbravo/talk-to-us");
            }}
            icon={code}
            title='Custom'
            subtitle='Suggest your custom signal and we’ll include this for you..'
            actionIcon={<ArrowForwardIos />}
            isComingSoon={false}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NewAccountDialog;
