import { apiService } from "./apiService";

export const uploadCsv = (
  email: string,
  companyColumn: string,
  file: File
) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  // formData.append("company_column", companyColumn)
  const queryParams = new URLSearchParams({
    email: email,
    company_column: companyColumn
  });

  return apiService.post(
    `/research/save-project?${queryParams.toString()}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};