import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";
import theme from "../../theme";

type GridCardWithActionProps = {
  action: () => void;
  isComingSoon: boolean;
  title: string;
  subtitle: string;
  actionIcon: ReactNode;
  icon: string;
};

const GridCardWithAction: React.FC<GridCardWithActionProps> = ({
  action,
  actionIcon,
  isComingSoon,
  title,
  subtitle,
  icon,
}) => {
  return (
    <Grid item xs={12} md={6} display='flex'>
      <Card
        variant='outlined'
        sx={{
          borderRadius: 6,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}>
        <CardContent
          sx={{
            padding: 0,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flex: 1,
          }}>
          <Box
            sx={{
              backgroundColor: "#FAF9F8",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: 2,
              height: 60,
            }}>
            <Box
              sx={{
                borderRadius: 50,
                padding: "0.5em 1em",
                backgroundColor: isComingSoon ? "#FFE7E6" : "",
              }}>
              {isComingSoon && (
                <Typography fontSize={10} color={theme.palette.secondary.main}>
                  Coming soon
                </Typography>
              )}
            </Box>
          </Box>
          <Box paddingLeft={4} marginTop={-1}>
            <img src={icon} height={18} width={24} alt='icon' />
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            paddingLeft={4}
            paddingRight={4}
            alignItems='flex-start'>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography fontSize={18} fontFamily='Gilroy-Semibold'>
                {title}
              </Typography>
              <Typography
                fontSize={12}
                fontFamily='Gilroy-Medium'
                sx={{ wordWrap: "break-word" }}>
                {subtitle}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Box
            marginTop={2}
            alignItems='center'
            justifyContent='flex-end'
            display='flex'
            paddingRight={4}
            paddingBottom={2}
            width='100%'>
            {!isComingSoon ? (
              <IconButton
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 2,
                  bgcolor: "#F4F4F4",
                }}
                onClick={action}>
                {actionIcon}
              </IconButton>
            ) : (
              <IconButton
                disabled
                sx={{
                  ":disabled": {
                    bgcolor: "#F4F4F4",
                    cursor: "not-allowed",
                    pointerEvents: "all !important",
                  },

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 2,
                  bgcolor: "#F4F4F4",
                }}>
                {actionIcon}
              </IconButton>
            )}
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default GridCardWithAction;
