import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2B2A35",
      light: "#E5E5E5",
    },
    secondary: {
      main: "#FD4B43",
    },
    error: {
      main: "#FF0703",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: ["Gilroy-Regular", "sans-serif"].join(","),
    h1: {
      fontSize: 28,
      color: "#2B2A35",
      fontFamily: "Gilroy-Bold",
      letterSpacing: -1.5,
    },
    h2: {
      fontSize: 16,
      color: "#757575",
      fontFamily: "Gilroy-Medium",
      letterSpacing: -0.5,
    },
    h3: {
      fontSize: 14,
      color: "#757575",
      fontFamily: "Gilroy-Medium",
      letterSpacing: -0.5,
    },
    h4: {
      fontSize: 12,
      color: "#757575",
      fontFamily: "Gilroy-Medium",
      letterSpacing: -0.5,
    },
    button: {
      textTransform: "none",
      fontFamily: "Gilroy-Semibold",
      letterSpacing: -0.5,
      ".MuiOutlinedButton-root": {
        "&:hover .MuiOutlinedButton-notchedOutline": {
          borderColor: "#E5E5E5",
          outline: "none",
        },
      },
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: "rgba(0, 0, 0, 0.2)", // Default border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(0, 0, 0, 0.2)", // Default border color when focused
            },
          },
          "& .MuiInputBase-root:focus-within": {
            boxShadow: "none", // Remove any box shadow or default outline when focused
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover fieldset": {
            borderColor: "rgba(0, 0, 0, 0.2)", // Default border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "rgba(0, 0, 0, 0.2)", // Default border color when focused
          },
        },
        input: {
          "&:focus-within": {
            boxShadow: "none", // Remove any box shadow or default outline when focused
          },
        },
      },
    },
  },
});

export default theme;
