import { apiService } from "./apiService";

type Address = {
  "@type": "PostalAddress";
  addressRegion: string;
  streetAddress: string | null;
  addressCountry: string;
  addressLocality: string;
};

type Location = {
  "@type": "Place";
  address: Address;
  latitude: number;
  longitude: number;
};

type Company = {
  name: string;
  industry: string;
  linkedin: string;
  num_employees: string;
};

export type HiringSignal = {
  title: string;
  location: Location;
  posted_at: string;
  type: string;
  link: string;
  description: string;
  company: Company;
};

type HiringSignalResponse = {
  data: HiringSignal[];
  count: number | null;
};

function parseHiringSignalResponse(jsonString: string): HiringSignalResponse {
  return JSON.parse(jsonString);
}

export const staticPrompt = async (data: {
  designation: string;
  function: string;
  location: string;
}): Promise<HiringSignalResponse> => {
  const response = await apiService.get<string>(
    `/hiring/static-prompt?designation=${data.designation}&function=${data.function}&location=${data.location}`
  );
  return parseHiringSignalResponse(response);
};
