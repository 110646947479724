import { Box, IconButton, Popover, TextField, Typography } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import { Close, LaunchOutlined } from "@mui/icons-material";

type enrichmentColDetail = {
  showPromptDetail: boolean;
  colDetailEl: any;
  selectedHeader: any;
  setShowPromptDetail: (shouldShow: boolean) => void;
  setSelectedHeader: (header: any) => void;
  setColDetailEl: (colDetailEl: any) => void;
};
const EnrichmentColDetail: React.FC<enrichmentColDetail> = ({
  showPromptDetail,
  colDetailEl,
  selectedHeader,
  setSelectedHeader,
  setShowPromptDetail,
  setColDetailEl,
}) => {
  return (
    <div>
      <Popover
        id={showPromptDetail ? "cols-prompt-pop" : undefined}
        open={showPromptDetail}
        anchorEl={colDetailEl}
        onClose={() => {
          setShowPromptDetail(false);
          setColDetailEl(null);
          setSelectedHeader(null);
        }}
        sx={{
          borderRadius: 2,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <Box
          p={2}
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width={450}
          justifyContent='flex-start'>
          <Box sx={{ mt: 2, width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
              }}>
              <Typography
                typography='h3'
                sx={{
                  color: theme.palette.primary.main,
                }}
                fontWeight={600}>
                Response
              </Typography>
              <IconButton
                onClick={() => {
                  setShowPromptDetail(false);
                  setColDetailEl(null);
                  setSelectedHeader(null);
                }}>
                <Close></Close>
              </IconButton>
            </Box>

            <TextField
              fullWidth
              //   label='Enter link'
              placeholder='Headquarters'
              multiline
              variant='outlined'
              disabled
              value={selectedHeader.answer || "Not found"}
              sx={{
                mt: 2,
                padding: 0,
              }}
            />
          </Box>

          <Box sx={{ mt: 2, width: "100%" }}>
            <Typography
              typography='h3'
              sx={{
                color: theme.palette.primary.main,
              }}
              fontWeight={600}>
              Reason
            </Typography>

            <Box
              sx={{
                mt: 1,
                padding: 2,
                width: "100%",
                borderRadius: 2,
                bgcolor: "#FAF9F8",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 2,
              }}
              width={"100%"}>
              <Typography typography='h3' color='#666666' fontWeight={600}>
                {selectedHeader.reason}
              </Typography>
              <a
                target='_blank'
                style={{
                  marginTop: 4,
                  color: theme.palette.primary.main,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 600,
                  gap: 1,
                }}
                href={selectedHeader.link}>
                Proof link{" "}
                <LaunchOutlined
                  fontSize='small'
                  sx={{
                    color: "#ADADAD",
                  }}
                />
              </a>
            </Box>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default EnrichmentColDetail;
