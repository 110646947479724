import { apiService } from "./apiService";

export const onboardUser = (data: {
  email: string;
  company: string;
  homepage: string;
  role: string;
  solution: string;
}) => {
  return apiService.post("/user-onboard", data);
};
