import { Close, KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import theme from "../../theme";
import BurstLoader from "../common/BurstLoader";

interface AiEnrichmentColumnPopover {
  anchorEl: null;
  handleClose: () => void;
  setColumnName: any;
  setRequestPrompt: any;
  dataSource: any;
  setDataSource: any;
  setAnswerFormat: any;
  loading: boolean;
  handleSaveColumnName: () => void;
}

const AiEnrichmentColumnPopover: React.FC<AiEnrichmentColumnPopover> = ({
  anchorEl,
  handleClose,
  setColumnName,
  setRequestPrompt,
  dataSource,
  setDataSource,
  setAnswerFormat,
  loading,
  handleSaveColumnName,
}) => {
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;
  const CustomDropdownIcon = () => {
    return (
      <KeyboardArrowDown
        sx={{
          color: "#6A7383",
        }}
      /> // Replace this with your custom icon
    );
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      sx={{
        borderRadius: 2,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}>
      <Box
        p={2}
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        width={450}
        justifyContent='flex-start'>
        <Box
          display='flex'
          alignItems='center'
          width='100%'
          justifyContent='space-between'>
          <Typography fontSize={20} fontWeight={600}>
            Create new column using AI
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>

        <Box sx={{ mt: 2, width: "100%" }}>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={600}>
            Name your column
          </Typography>

          <TextField
            fullWidth
            //   label='Enter link'
            placeholder='Tech Stack'
            variant='outlined'
            onChange={(e) => {
              setColumnName(e.target.value);
            }}
            sx={{
              mt: 2,
              padding: 0,
            }}
          />
          {/* <Typography mt={2} typography='h4' fontWeight={600}>
                      Provide a name that you want to give to this column
                    </Typography> */}
        </Box>

        <Box sx={{ mt: 2, width: "100%" }}>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={600}>
            Request Prompt
          </Typography>
          <TextField
            placeholder='For each company, tell me the tech stack they are using'
            multiline
            rows={4}
            variant='outlined'
            fullWidth
            sx={{
              "& .MuiInputBase-root": {
                height: "auto",
              },
              mt: 1,
            }}
            onChange={(e: any) => {
              setRequestPrompt(e.target.value);
            }}
          />
          <Typography
            typography='h4'
            sx={{
              mt: 1,
            }}
            color='#ADADAD'
            fontWeight={500}>
            Describe some information you'd like to automatically retrieve or
            generate about a company, person, or row.
          </Typography>
        </Box>
        <Box sx={{ mt: 3, width: "100%", pr: 10 }}>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={600}>
            Data Source
          </Typography>
          <Box alignItems='center' justifyContent='flex-start' sx={{ mt: 2 }}>
            <Box display='flex' justifyContent='space-between'>
              {/* <Box display='flex' alignItems='center'>
                <Radio
                  color={"secondary"}
                  checked={dataSource === 0}
                  onChange={(e: any) => setDataSource(0)}
                  name='data-source'
                  inputProps={{ "aria-label": "Job Description" }}
                />
                <Typography
                  typography='h4'
                  fontWeight={500}
                  sx={{ ml: 0, color: "#666666" }}>
                  Job Description
                </Typography>
              </Box> */}
              <Box display='flex' alignItems='center'>
                <Radio
                  color={"secondary"}
                  checked={dataSource === 1}
                  onChange={(e) => setDataSource(1)}
                  name='data-source'
                  inputProps={{ "aria-label": "Website" }}
                  sx={{ ml: 0 }}
                />
                <Typography
                  typography='h4'
                  fontWeight={500}
                  sx={{ ml: 0, color: "#666666" }}>
                  Website
                </Typography>
              </Box>
            </Box>

            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              sx={{ mt: 2 }}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
                sx={{ pr: 1.3 }}>
                <Radio
                  color={"secondary"}
                  checked={dataSource === 3}
                  onChange={(e) => setDataSource(3)}
                  name='data-source'
                  inputProps={{ "aria-label": "News" }}
                  sx={{ ml: 0 }}
                />
                <Typography
                  typography='h4'
                  fontWeight={500}
                  sx={{ ml: 0, color: "#666666" }}>
                  News
                </Typography>
              </Box>
              <Box display='flex' alignItems='center' justifyItems='center'>
                <Radio
                  color={"secondary"}
                  checked={dataSource === 2}
                  onChange={(e) => setDataSource(2)}
                  name='data-source'
                  inputProps={{
                    "aria-label": "Annual Report (Latest)",
                  }}
                />
                <Typography
                  typography='h4'
                  fontWeight={500}
                  sx={{ ml: 0, color: "#666666" }}>
                  Annual Report (Latest)
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={2} width='100%'>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={600}>
            Answer format
          </Typography>
          <TextField
            fullWidth
            select
            placeholder='Yes/No'
            variant='outlined'
            defaultValue=''
            onChange={(e) => {
              setAnswerFormat(e.target.value);
            }}
            SelectProps={{
              native: true,
              IconComponent: CustomDropdownIcon,
            }}
            inputProps={{
              style: {
                paddingTop: 4,
                paddingBottom: 4,
                paddingRight: 8,
                paddingLeft: 8,
                textAlign: "start",

                color: theme.palette.primary.main,
              },
            }}
            sx={{ mt: 2 }}>
            <option value={"Yes/No"}>Yes/No</option>
            <option value={"String"}>String</option>
            <option value={"Number"}>Number</option>
          </TextField>
        </Box>

        {loading ? (
          <Box
            width='100%'
            mt={2}
            display='flex'
            alignItems='center'
            justifyContent='center'>
            <BurstLoader />
          </Box>
        ) : (
          <Button
            variant='contained'
            sx={{
              marginTop: 2,
            }}
            onClick={handleSaveColumnName}
            fullWidth>
            Save
          </Button>
        )}
      </Box>
    </Popover>
  );
};

export default AiEnrichmentColumnPopover;
