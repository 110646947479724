import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
  Box,
  DialogActions,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ArrowBackIos, Circle } from "@mui/icons-material";
import theme from "../../theme";
import { HiringSignal } from "../../service/staticPromt";
import { formatDate } from "../../utils/utils";
import { toast } from "react-toastify";
import BurstLoader from "../common/BurstLoader";
import { saveProject } from "../../service/saveResult";
import { useAuth } from "../../context/AuthContext";
import { ProjectRecord } from "../../service/listProject";

const HiringSignalList: React.FC<{
  open: boolean;
  onClose: () => void;
  onSave: (records: HiringSignal[]) => void;
  onBack: () => void;
  companies: HiringSignal[];
  count: number;
  functionValue: string;
  designation: string;
  location: string;
}> = ({
  open,
  onClose,
  onSave,
  onBack,
  companies,
  count,
  functionValue,
  designation,
  location,
}) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const headers = [
    "Company Name",
    "# Employees",
    "Company description",
    "Posted on",
    "Type",
  ];

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await saveProject({
        email: user?.email as string,
        designation: designation,
        function: "",
        location,
        team: functionValue,
        records: companies,
      });

      onSave(companies);
      onClose();
    } catch (error) {
      toast.error("Failed to save profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogTitle
        marginTop={2}
        marginRight={2}
        marginLeft={2}
        gutterBottom={false}>
        <Box
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='space-between'>
          <Box display='flex' alignItems='center' justifyContent='flex-start'>
            <IconButton aria-label='close' onClick={onBack}>
              <ArrowBackIos
                sx={{
                  height: 14,
                  width: 14,
                }}
              />
            </IconButton>
            <Typography variant='h2'>Back</Typography>
          </Box>
          <IconButton aria-label='close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography marginBottom={1} variant='h1'>
          Hiring Signal
        </Typography>
        <Typography marginBottom={3} variant='h3'>
          Ask ListenBravo for accounts that are showing hiring signals that fit
          well with your outreach needs
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingRight: 4,
          paddingLeft: 4,
          paddingBottom: 4,
          height: "100vh",
        }}>
        <Box
          sx={{
            border: "1px solid #EEEFEE",
            borderRadius: 2,
            height: "70vh",
          }}>
          <Box sx={{ padding: 1.5 }}>
            <Typography
              typography='h2'
              color={theme.palette.primary.main}
              gutterBottom={false}
              fontFamily='Gilroy-Semibold'>
              {count} hiring posts found
            </Typography>
            <Typography
              mt={0.5}
              fontStyle='italic'
              typography='h4'
              gutterBottom={false}>
              Showing {companies.length} accounts for your review
            </Typography>
          </Box>

          <TableContainer
            sx={{
              flexGrow: 1,
              overflow: "auto",
              boxShadow: "none",
              padding: 1,
            }}>
            <Table stickyHeader={false} sx={{ minWidth: 1000 }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: 14,
                      fontFamily: "Gilroy-Semibold",
                      color: "#757575",
                    }}>
                    #
                  </TableCell>
                  {headers.map((header, idx) => (
                    <TableCell
                      key={header}
                      sx={{
                        fontSize: 14,
                        fontFamily: "Gilroy-Semibold",
                        color: "#757575",
                        borderLeft: `${
                          idx === 1 ? "4px" : "1px"
                        } solid #F0F0F0`,
                      }}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {companies.map((row, idx) => (
                  <TableRow key={idx} hover>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell
                      sx={{
                        borderLeft: "1px solid #F0F0F0",
                        fontFamily: "Gilroy-Medium",
                        alignItems: "center",
                        fontSize: 14,
                        width: 180,
                      }}>
                      {row.company.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: 14,
                        borderLeft: "4px solid #F0F0F0",
                        width: 180,
                        fontFamily: "Gilroy-Medium",
                      }}>
                      {row.company.num_employees}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: 14,
                        borderLeft: "1px solid #F0F0F0",
                        fontFamily: "Gilroy-Medium",
                        maxWidth: 250,
                      }}>
                      <Tooltip title={row.description}>
                        <Typography
                          variant='h3'
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontFamily: "Gilroy-Medium",
                            textOverflow: "ellipsis",
                          }}
                          gutterBottom={false}>
                          {row.company.linkedin}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: 14,
                        borderLeft: "1px solid #F0F0F0",
                        fontFamily: "Gilroy-Medium",
                      }}>
                      {formatDate(row.posted_at)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: 14,
                        borderLeft: "1px solid #F0F0F0",
                        fontFamily: "Gilroy-Medium",
                      }}>
                      {row.type}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ marginTop: 3 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 0,
              }}>
              {/* <TextField
              fullWidth
              variant='standard'
              placeholder='Refine more as you like'
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <IconButton
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 2,
                      bgcolor: "#EEEFEE",
                      padding: 0.5,
                    }}>
                    <ChevronRightIcon />
                  </IconButton>
                ),
              }}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: 50,
                padding: "8px 12px",
                "& .MuiInputBase-input": {
                  padding: 0,
                },
              }}
              inputProps={{
                style: {
                  fontSize: 14,
                },
              }}
            /> */}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <BurstLoader />
        ) : (
          <Button
            onClick={handleSave}
            variant='contained'
            sx={{
              backgroundColor: "#333",
              color: "white",
              "&:hover": {
                backgroundColor: "#555",
              },
              paddingRight: 4,
              marginTop: 4,
              paddingLeft: 4,
            }}>
            Save Results
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default HiringSignalList;
