import React, { useEffect, useState } from "react";
import {
  Button,
  Popover,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
  Grid,
  Box,
  IconButton,
  Autocomplete,
  Chip,
} from "@mui/material";
import axios from "axios";
import theme from "../../theme";
import { Close, Add, Remove } from "@mui/icons-material";
import PeopleChips from "../common/PeopleChips";
import { toast } from "react-toastify";

type PopOverPropType = {
  anchorEl?: any;
  handleSaveColumnName: (
    numPeople: number,
    location: string,
    seniority: string[],
    title: string,
    enrichments: number[],
    columnName: string
  ) => void;
  handleClosePopover: () => void;
};

const AddPeoplePopOver: React.FC<PopOverPropType> = ({
  anchorEl,
  handleSaveColumnName,
  handleClosePopover,
}) => {
  const [location, setLocation] = useState<string>("United States");
  const [numberOfPeople, setNumberOfPeople] = useState<number>(3);
  const [countries, setCountries] = useState<string[]>([]);
  const [columnName, setColumnName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [enrichments, setEnrichments] = useState({
    name: true,
    email: false,
    linkedIn: false,
    phoneNumber: false,
  });
  const [seniorities, setSeniorities] = useState<string[]>([]);
  const [newSeniority, setNewSeniority] = useState<string>("");
  const [totalCredits, setTotalCredits] = useState(1);
  const [enrichAdded, setEnrichAdded] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("");

  const handleSeniorityDelete = (indexToDelete: number) => {
    setSeniorities((prev) =>
      prev.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleSeniorityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewSeniority(event.target.value);
  };

  const handleSeniorityKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" || event.key === "," || event.key === " ") {
      event.preventDefault();

      const trimmedValue = newSeniority.trim();

      if (trimmedValue && !seniorities.includes(trimmedValue)) {
        setSeniorities((prev) => [...prev, trimmedValue]);
        setNewSeniority("");
      }
    }
  };

  const handleClose = () => {
    handleClosePopover();
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countryNames = response.data
          .map((country: { name: { common: string } }) => country.name.common)
          .sort((a: any, b: any) => a.localeCompare(b));

        setCountries(countryNames);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);
  useEffect(() => {
    let credits = 0;
    const enrichmentsAdded = [];

    if (enrichments.name) {
      enrichmentsAdded.push(1);
      credits += 1;
    }
    if (enrichments.email) {
      enrichmentsAdded.push(2);
      credits += 1;
    }
    if (enrichments.linkedIn) {
      enrichmentsAdded.push(3);
      credits += 1;
    }
    if (enrichments.phoneNumber) {
      enrichmentsAdded.push(4);
      credits += 5;
    }

    setEnrichAdded(enrichmentsAdded);
    setTotalCredits(credits);
  }, [enrichments]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleLocationChange = (event: any) => {
    setLocation(event.target.textContent);
    // console.log(event.target.textContent);
  };

  const handleNumberOfPeopleChange = (event: SelectChangeEvent<number>) => {
    setNumberOfPeople(event.target.value as number);
  };

  const handleEnrichmentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnrichments({
      ...enrichments,
      [event.target.name]: event.target.checked,
    });
  };

  const filteredCountries = countries.filter((country) =>
    country.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}>
      <Box p={2} width={500}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography fontSize={20} fontWeight={600}>
            Find People
          </Typography>
          <IconButton onClick={handleClosePopover}>
            <Close color='primary' />
          </IconButton>
        </Box>

        <Box sx={{ mt: 3, width: "100%" }}>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={600}
            gutterBottom={false}>
            Name your column
          </Typography>
          <TextField
            fullWidth
            size='small'
            //   label='Enter link'
            placeholder='VP sales'
            variant='outlined'
            onChange={(e) => {
              setColumnName(e.target.value);
            }}
            sx={{
              mt: 1,
              padding: 0,
            }}
          />
          <Typography
            typography='h4'
            sx={{
              mt: 1,
            }}
            color='#ADADAD'
            fontWeight={600}>
            Provide a name that you want to give to this column
          </Typography>
        </Box>
        <Box sx={{ mt: 3, width: "100%" }}>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={600}
            gutterBottom={false}>
            Title
          </Typography>
          <TextField
            fullWidth
            size='small'
            //   label='Enter link'
            placeholder='VP of sales'
            variant='outlined'
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            sx={{
              mt: 1,
              padding: 0,
            }}
          />
          <Typography
            typography='h4'
            sx={{
              mt: 1,
            }}
            color='#ADADAD'
            fontWeight={600}>
            Provide the title of the person you want to know. Type only one
            title
          </Typography>
        </Box>
        <Box sx={{ mt: 3, width: "100%" }}>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={600}
            gutterBottom={false}>
            Seniorities
          </Typography>
          <Autocomplete
            freeSolo
            multiple
            options={[]}
            value={seniorities}
            sx={{
              mt: 1,
            }}
            onChange={(event, newValue) => {
              setSeniorities(newValue as string[]);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant='outlined'
                  label={option}
                  {...getTagProps({ index })}
                  onDelete={() => handleSeniorityDelete(index)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                placeholder='manager, director'
                // value={newSeniority}
                onChange={handleSeniorityChange}
                onKeyDown={handleSeniorityKeyDown}
                fullWidth
                size='small'
                sx={{ mt: 0, padding: 0 }}
              />
            )}
          />
          <Typography
            typography='h4'
            sx={{
              mt: 1,
            }}
            color='#ADADAD'
            fontWeight={600}>
            Mention the seniority of the person. Comma separated or type enter
            after one
          </Typography>
        </Box>
        <Box sx={{ mt: 3, width: "100%" }}>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={600}
            gutterBottom={false}>
            Location (Country)
          </Typography>
          <Autocomplete
            disablePortal
            options={filteredCountries}
            value={location}
            onChange={(e) => {
              handleLocationChange(e);
            }}
            sx={{ mb: 1, mt: 2 }}
            renderInput={(params) => (
              <TextField sx={{ mt: 0, padding: 0 }} {...params} />
            )}
          />
          <Typography
            typography='h4'
            sx={{
              mt: 0,
            }}
            color='#ADADAD'
            fontWeight={600}>
            Select location of the person
          </Typography>
        </Box>
        <Box sx={{ mt: 3, width: "100%" }}>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
              mb: 1,
            }}
            fontWeight={600}
            gutterBottom={false}>
            # of people
          </Typography>
          <FormControl sx={{ padding: 0, margin: 0 }} fullWidth margin='normal'>
            <Box
              sx={{ padding: 0, margin: 0 }}
              display='flex'
              alignItems='center'>
              <TextField
                fullWidth
                size='small'
                type='number'
                inputProps={{ min: 1, max: 3 }}
                value={numberOfPeople}
                sx={{ mt: 0 }}
                onChange={(e) => {
                  if (parseInt(e.target.value || "1", 10) <= 3) {
                    setNumberOfPeople(parseInt(e.target.value || "1", 10));
                  }
                }}
              />
            </Box>
          </FormControl>
          <Typography
            typography='h4'
            sx={{
              mt: 1,
            }}
            color='#ADADAD'
            fontWeight={600}>
            Mention the seniority of the person. Comma separated or type enter
            after one
          </Typography>
        </Box>
        <Box sx={{ mt: 3, width: "100%" }}>
          <Typography
            typography='h3'
            sx={{
              color: theme.palette.primary.main,
              mb: 1,
            }}
            fontWeight={600}
            gutterBottom={false}>
            Enrichments
          </Typography>
          <FormGroup>
            <Box
              justifyContent='space-between'
              alignItems='center'
              display='flex'>
              <FormControlLabel
                control={
                  <Checkbox
                    color='secondary'
                    checked={enrichments.name}
                    onChange={handleEnrichmentChange}
                    name='name'
                  />
                }
                label={
                  <Typography
                    typography='h4'
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: 400,
                    }}>
                    Name
                  </Typography>
                }
              />
              <PeopleChips chips={"1"} />
            </Box>
            <Box
              justifyContent='space-between'
              alignItems='center'
              display='flex'>
              <FormControlLabel
                control={
                  <Checkbox
                    color='secondary'
                    checked={enrichments.email}
                    onChange={handleEnrichmentChange}
                    name='email'
                  />
                }
                label={
                  <Typography
                    typography='h4'
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: 400,
                    }}>
                    Email
                  </Typography>
                }
              />
              <PeopleChips chips={"1"} />
            </Box>
            <Box
              justifyContent='space-between'
              alignItems='center'
              display='flex'>
              <FormControlLabel
                control={
                  <Checkbox
                    color='secondary'
                    checked={enrichments.linkedIn}
                    onChange={handleEnrichmentChange}
                    name='linkedIn'
                  />
                }
                label={
                  <Typography
                    typography='h4'
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: 400,
                    }}>
                    LinkedIn URL
                  </Typography>
                }
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: 600,
                }}
              />
              <PeopleChips chips={"1"} />
            </Box>
            <Box
              justifyContent='space-between'
              alignItems='center'
              display='flex'>
              <FormControlLabel
                control={
                  <Checkbox
                    color='secondary'
                    checked={enrichments.phoneNumber}
                    onChange={handleEnrichmentChange}
                    name='phoneNumber'
                  />
                }
                label={
                  <Typography
                    typography='h4'
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: 400,
                    }}>
                    Phone Number
                  </Typography>
                }
              />
              <PeopleChips chips={"5"} />
            </Box>
            <Box
              justifyContent='space-between'
              alignItems='center'
              display='flex'>
              <FormControlLabel
                control={
                  <Checkbox
                    color='secondary'
                    checked={Object.values(enrichments).every(Boolean)}
                    onChange={(e) =>
                      setEnrichments({
                        name: e.target.checked,
                        email: e.target.checked,
                        linkedIn: e.target.checked,
                        phoneNumber: e.target.checked,
                      })
                    }
                  />
                }
                label={
                  <Typography
                    typography='h4'
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: 400,
                    }}>
                    All
                  </Typography>
                }
              />
              <Box display='flex' alignItems='center'>
                <Typography
                  typography='h4'
                  sx={{
                    color: "#ADADAD",
                    fontWeight: 400,
                    mr: 1,
                  }}>
                  Total
                </Typography>
                <PeopleChips chips={totalCredits.toString()} />
              </Box>
            </Box>
          </FormGroup>
        </Box>
        <Button
          variant='contained'
          sx={{
            marginTop: 2,
          }}
          onClick={() => {
            if (title.trim().length === 0 || columnName.trim().length === 0) {
              toast.error("All fields are mandatory");
            } else {
              handleSaveColumnName(
                numberOfPeople,
                location,
                seniorities,
                title,
                enrichAdded,
                columnName
              );
            }
          }}
          fullWidth>
          Save
        </Button>
      </Box>
    </Popover>
  );
};

export default AddPeoplePopOver;
