import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import GettingReady from "../components/common/GettingReady";
import Sidebar from "../components/home/Sidebar";
import MainContainer from "../components/home/MainContainer";
import RightSidebar from "../components/home/RightSidebar";
import LetsStartDialog from "../components/home/LetsStartDialog";
import CreateProjectDialog from "../components/home/CreateProjectDialog";
import NewAccountDialog from "../components/home/NewAccountDialog";
import HiringSignalDialog from "../components/home/HiringSignalDialog";
import Spreadsheet from "../components/home/SpreadSheet";
import { HiringSignal } from "../service/staticPromt";
import { ProjectRecord, listProjects } from "../service/listProject";
import { useAuth } from "../context/AuthContext";
import { toast } from "react-toastify";
import { getProject } from "../service/getProject";
import { renameProject } from "../service/renameProject";
import { duplicateProject } from "../service/duplicateProject";
import { deleteProject } from "../service/deleteProject";
import {
  DynamicPrompt,
  ProjectPrompts,
  getProjectHeaders,
} from "../service/getProjectHeaders";
import MyTable from "../components/home/MyTable";
import { DataItem } from "../utils/utils";
import LoaderModal from "../components/common/Loader";
import { getCredits } from "../service/getCredits";

const initialHeaders = [
  "Company Name",
  "Employee strength",
  "Company description",
  "Industry",
  "Role",
  "Location",
  "Posted",
  "Type",
  "Job Link",
  "JD",
];

const headerMappings: { [key: string]: string } = {
  "Company Name": "company.name",
  "Employee strength": "company.num_employees",
  "Company description": "company.linkedin",
  Industry: "company.industry",
  Role: "title",
  Location: "location.address",
  Posted: "posted_at",
  Type: "type",
  "Job Link": "link",
  JD: "description",
};

// Function to extract dynamic keys from response and merge with initial headers
const mergeHeaders = (
  initialHeaders: string[],
  dynamicKeys: string[]
): string[] => {
  return [...initialHeaders, ...dynamicKeys];
};

// Function to create dynamic mappings
const createDynamicMappings = (
  dynamicKeys: string[]
): { [key: string]: string } => {
  const dynamicMappings: { [key: string]: string } = {};
  dynamicKeys.forEach((key) => {
    dynamicMappings[key] = key;
  });
  return dynamicMappings;
};

const Home: React.FC = () => {
  const [showReadyProgress, setShowReadyProgress] = useState<boolean>(true);
  const [showLetsStartDialog, setShowLetsStartDialog] = useState<boolean>(true);
  const [showCreateProjectDialog, setShowCreateProjectDialog] =
    useState<boolean>(false);
  const [showNewAccountDialog, setShowNewAccountDialog] =
    useState<boolean>(false);
  const [openHiring, setOpenHiring] = useState<boolean>(false);
  const [showMyTable, setShowMyTable] = useState<boolean>(false);
  const [data, setData] = useState<ProjectRecord[]>([]);
  const [projectDetailRecords, setProjectDetailRecords] = useState<
    ProjectRecord[]
  >([]);
  const [isResearch, setIsResearch] = useState<boolean>(false);
  const [tableTitle, setTableTitle] = useState("");
  const [projectId, setProjectId] = useState("");
  const [showSheetData, setShowSheetData] = useState<boolean>(false);
  const { user } = useAuth();
  const [selectedData, setSelectedData] = useState<ProjectRecord | null>(null);
  const [showRefreshLoader, setShowRefreshLoader] = useState(false);
  const [projectHeaders, setProjectHeaders] = useState<ProjectPrompts[]>([]);
  const [tableData, setTableData] = useState<DataItem[]>([]);
  const [myTableHeaders, setMyTableHeaders] = useState<string[]>([]);
  const [myTableTitle, setMyTableTitle] = useState<string>("");
  const [showSheetLoader, setShowSheetLoader] = useState<boolean>(false);
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    if (user) {
      getUserProjects();
      getUserCredits();
    }
  }, [user]);

  useEffect(() => {
    if (tableData.length > 0) {
      setShowMyTable(true);
    } else {
      setShowMyTable(false);
    }
  }, [tableData]);

  const getUserProjects = async () => {
    try {
      const response = await listProjects(user?.email as string);
      const projects = response.data ? response.data : [];
      setData(projects);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setShowReadyProgress(false);
    }
  };

  const getUserCredits = async () => {
    try {
      const response = await getCredits(user?.email as string);
      setCredits(response.credits);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  if (showReadyProgress) {
    return <GettingReady />;
  }

  const handleOnCloseLetsGetStarted = () => {
    setShowLetsStartDialog(false);
    localStorage.setItem("profileCreated", "false");
  };

  const handleDiscovery = () => {
    setShowNewAccountDialog(true);
    handleCloseCreateNew();
  };
  const handleOpenCreateNew = () => {
    setShowCreateProjectDialog(true);
  };

  const handleCloseCreateNew = () => {
    setShowCreateProjectDialog(false);
  };

  const handleOnCloseNewAccount = () => {
    setShowNewAccountDialog(false);
  };

  const handleOnBackNewAccount = () => {
    handleOnCloseNewAccount();
    setShowCreateProjectDialog(true);
  };

  const handleHiringOpen = () => {
    handleOnCloseNewAccount();
    setOpenHiring(true);
  };

  const handleHiringBack = () => {
    handleDiscovery();
    setOpenHiring(false);
  };
  const handleHiringListBack = () => {
    handleHiringOpen();
  };
  const handleHiringClose = () => {
    handleOnCloseNewAccount();
    setOpenHiring(false);
  };

  function getLatestItem(list: any) {
    return list.reduce((latest: any, item: any) => {
      const itemDate = new Date(item.created_at);
      const latestDate = new Date(latest.created_at);
      return itemDate > latestDate ? item : latest;
    }, list[0]);
  }

  const handleSave = async (records: HiringSignal[]) => {
    try {
      const response = await listProjects(user?.email as string);
      const latestItem = getLatestItem(response.data);
      console.log("response from save", response.data);
      setData(response.data);
      handleDetails(latestItem);
      handleHiringClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
    }
  };

  const handleRefresh = async () => {
    try {
      setShowRefreshLoader(true);
      if (selectedData?.type === "Discovery") {
        setShowSheetData(true);
      }
      const response = await getProject(selectedData?.id as string);
      const projectHeaders = await getProjectHeaders(
        selectedData?.id as string
      );
      setProjectDetailRecords(response.data ? response.data[0].records : []);
      setProjectHeaders(projectHeaders.data[0].dynamic_prompts);

      setTableTitle(selectedData?.name as string);
      setProjectId(selectedData?.id as string);
      if (selectedData?.type === "Research") {
        setMyTableTitle(selectedData?.name);
        setTableData(response.data[0].records);
        const records = response.data[0].records;

        // Create a set to store unique keys
        const headersSet = new Set();

        // Loop through each record and extract the keys
        records.forEach((record: any) => {
          Object.keys(record).forEach((key) => {
            headersSet.add(key);
          });
        });

        // Convert the set to an array
        const tableHeader = Array.from(headersSet) as [];
        const columnNames = projectHeaders.data[0].dynamic_prompts
          ? projectHeaders.data[0].dynamic_prompts.map(
              (item: { column_name: string }) => item.column_name
            )
          : [];

        // Filter tableHeader to exclude any item that is in columnNames
        const filteredTableHeader = tableHeader.filter(
          (header) => !columnNames.includes(header)
        );

        setMyTableHeaders(filteredTableHeader);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setShowRefreshLoader(false);
    }
  };
  const handleDetails = async (data: ProjectRecord) => {
    try {
      setShowRefreshLoader(true);
      setShowSheetLoader(true);
      if (data.type === "Discovery") {
        setShowSheetData(true);
      }

      setTableTitle(data.name);
      setProjectId(data.id);

      setSelectedData(data);
      const response = await getProject(data.id as string);
      const projectHeaders = await getProjectHeaders(data.id as string);

      console.log("the data logged of the handle Details", response.data);
      setProjectDetailRecords(response.data ? response.data[0].records : []);
      // console.log(response.data[0].records);
      setProjectHeaders(projectHeaders.data[0].dynamic_prompts);

      if (data.type === "Research") {
        setMyTableTitle(data.name);

        setTableData(response.data[0].records);
        const records = response.data[0].records;

        // Create a set to store unique keys
        const headersSet = new Set();

        // Loop through each record and extract the keys
        records.forEach((record: any) => {
          Object.keys(record).forEach((key) => {
            headersSet.add(key);
          });
        });

        // Convert the set to an array
        const tableHeader = Array.from(headersSet) as [];
        const columnNames = projectHeaders.data[0].dynamic_prompts
          ? projectHeaders.data[0].dynamic_prompts.map(
              (item: { column_name: string }) => item.column_name
            )
          : [];

        // Filter tableHeader to exclude any item that is in columnNames
        const filteredTableHeader = tableHeader.filter(
          (header) => !columnNames.includes(header)
        );

        setMyTableHeaders(filteredTableHeader);
        console.log(tableHeader);
      }
      setShowSheetLoader(false);
    } catch (error: any) {
      setSelectedData(null);
      toast.error(error.message);
    } finally {
      setShowRefreshLoader(false);
    }
  };

  const handleDuplicate = async (selectedRow: ProjectRecord | null) => {
    if (selectedRow) {
      try {
        await duplicateProject(selectedRow.id);
        await getUserProjects();
        toast.success("Duplicated project successfully");
      } catch (error) {
        console.error("Failed to duplicate project", error);
        toast.error("Failed to duplicate project");
      }
    }
  };

  const handleRename = async (
    selectedRow: ProjectRecord | null,
    newName: string
  ) => {
    if (selectedRow) {
      if (newName && newName !== selectedRow.name) {
        try {
          await renameProject(selectedRow.id, newName);
          setData((prevData) =>
            prevData.map((project) =>
              project.id === selectedRow.id
                ? { ...project, name: newName }
                : project
            )
          );
          if (showSheetData) {
            setTableTitle(newName);
          }
          toast.success("Project renamed successfully");
        } catch (error) {
          console.error("Failed to rename project", error);
          toast.error("Failed to rename project");
        }
      }
    }
  };

  const handleDelete = async (selectedRow: ProjectRecord | null) => {
    if (selectedRow) {
      try {
        await deleteProject(selectedRow.id);
        setData((prevData) =>
          prevData.filter((project) => project.id !== selectedRow.id)
        );
        if (showSheetData) {
          setShowSheetData(false);
        }
        toast.success("Project deleted successfully");
      } catch (error) {
        console.error("Failed to delete project", error);
        toast.error("Failed to delete project");
      }
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
      }}
      disableGutters
      maxWidth={false}>
      {!localStorage.getItem("profileCreated") && (
        <LetsStartDialog
          open={showLetsStartDialog}
          onClose={handleOnCloseLetsGetStarted}
        />
      )}
      <CreateProjectDialog
        open={showCreateProjectDialog}
        onClose={handleCloseCreateNew}
        discoveryAction={handleDiscovery}
        enrichAction={(data: DataItem[], headers: string[], title: string) => {
          setShowSheetLoader(true);
          listProjects(user?.email as string).then((response) => {
            setData(response.data);
            const latestItem = getLatestItem(response.data);
            handleDetails(latestItem);
            handleHiringClose();
          });
        }}
      />
      <NewAccountDialog
        open={showNewAccountDialog}
        onClose={handleOnCloseNewAccount}
        onBackAction={handleOnBackNewAccount}
        onHiringOpen={handleHiringOpen}
      />
      <HiringSignalDialog
        open={openHiring}
        onClose={handleHiringClose}
        onBack={handleHiringBack}
        onSave={handleSave}
        onHiringBack={handleHiringListBack}
      />
      <Sidebar
        credits={credits}
        onClose={() => {
          setShowSheetData(false);
          setShowMyTable(false);
          setTableData([]);
          setProjectDetailRecords([]);
        }}
        onCreateNew={handleOpenCreateNew}
      />

      {!showSheetData && !showMyTable && (
        <MainContainer
          handleDetails={handleDetails}
          data={data}
          onCreateNewPress={handleOpenCreateNew}
          handleDeleteProject={handleDelete}
          handleDuplicateProject={handleDuplicate}
          handleRenameProject={handleRename}
        />
      )}

      {!showSheetData && !showMyTable && <RightSidebar />}
      {showSheetData && (
        <Box
          flex={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 0,
            flex: 4,
            height: "100vh",
            width: "500px",
          }}>
          <Spreadsheet
            isResearch={isResearch}
            projectRecord={selectedData}
            projectHeaders={projectHeaders}
            initialHeaders={initialHeaders}
            initialHeaderMappings={headerMappings}
            onClose={() => {
              setShowSheetData(false);
              setProjectDetailRecords([]);
            }}
            showTableLoader={showRefreshLoader}
            handleRefresh={handleRefresh}
            data={projectDetailRecords}
            title={tableTitle}
            projectId={projectId}
            handleDeleteProject={handleDelete}
            handleDuplicateProject={handleDuplicate}
            handleRenameProject={handleRename}
            setCredits={setCredits}
          />
        </Box>
      )}
      {showMyTable && (
        <MyTable
          initialHeaderMappings={headerMappings}
          projectId={projectId}
          isResearch={isResearch}
          showTableLoader={showRefreshLoader}
          data={tableData}
          title={myTableTitle}
          initialHeaders={myTableHeaders}
          projectHeaders={projectHeaders}
          onClose={() => {
            setTableData([]);
            setProjectId("");
            setSelectedData(null);
          }}
          handleRefresh={handleRefresh}
          setCredits={setCredits}
        />
      )}
      <LoaderModal open={showSheetLoader} />
    </Container>
  );
};

export default Home;
