import {
  Add,
  ArrowDownward,
  ArrowDropDown,
  ContentCopy,
  CopyAll,
  KeyboardArrowDown,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Icon,
  IconButton,
  LinearProgress,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../theme";
import projectDocIcon from "../../assets/images/project-doc-icon.svg";
import discoveryIcon from "../../assets/images/discovery-icon.svg";
import enrichIcon from "../../assets/images/enrich-icon.svg";
import elipseIcon from "../../assets/images/elipses-icon.svg";
import noDataIcon from "../../assets/images/no-data.svg";
import duplicate from "../../assets/images/duplicate.svg";
import rename from "../../assets/images/rename.svg";
import deleteIcon from "../../assets/images/delete.svg";
import BurstLoader from "../common/BurstLoader";
import Pagination from "./Pagination";
import { ProjectRecord } from "../../service/listProject";
import { formatDate } from "../../utils/utils";
import { deleteProject } from "../../service/deleteProject";
import { renameProject } from "../../service/renameProject";
import { duplicateProject } from "../../service/duplicateProject";
import { DeleteDialog, RenameDialog } from "./projectDialogs";

type MainContainerProps = {
  data: ProjectRecord[];
  onCreateNewPress: () => void;
  handleDetails: (data: ProjectRecord) => void;
  handleDuplicateProject: (data: ProjectRecord | null) => void;
  handleRenameProject: (data: ProjectRecord | null, newName: string) => void;
  handleDeleteProject: (data: ProjectRecord | null) => void;
};

const MainContainer: React.FC<MainContainerProps> = ({
  data,
  onCreateNewPress,
  handleDetails,
  handleDeleteProject,
  handleRenameProject,
  handleDuplicateProject,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isRenameDialogOpen, setRenameDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState(data);
  const [selectedRow, setSelectedRow] = useState<ProjectRecord | null>(null);
  const [loading, setLoading] = useState(false);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDisplayedData = () => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredData.slice(start, end);
  };

  const totalPages =
    filteredData.length > 0
      ? Math.ceil(filteredData.length / rowsPerPage)
      : Math.ceil(1 / rowsPerPage);

  const CustomDropdownIcon = () => {
    return (
      <KeyboardArrowDown
        sx={{
          color: "#6A7383",
        }}
      /> // Replace this with your custom icon
    );
  };

  useEffect(() => {
    if (page >= totalPages) {
      setPage(totalPages - 1);
    }
  }, [totalPages, page]);
  useEffect(() => {
    if (data.length > 0) {
      handleChangePage(0);
      setFilteredData(data);
    }
  }, [data]);

  const handleDuplicate = async () => {
    setLoading(true);
    handleDuplicateProject(selectedRow);
    setLoading(false);
    handleClose();
  };

  const handleRename = async (id: string, name: string) => {
    setLoading(true);
    handleRenameProject(selectedRow, name);
    setLoading(false);
    handleClose();
    // setSelectedRow(null);
  };

  const handleDelete = async () => {
    setLoading(true);
    handleDeleteProject(selectedRow);
    setLoading(false);
    handleClose();
    // setSelectedRow(null);
  };
  const handleSearch = (searchTerm: string) => {
    if (searchTerm.trim().length === 0) {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((d) =>
          d.name.toLowerCase().trim().includes(searchTerm.toLowerCase())
        )
      );
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        pl: 3,
        pr: 3,
        pt: 3,
        pb: 3,
        flex: 3.5,
        height: "100vh",
      }}>
      <CssBaseline />
      <Toolbar
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: 4,
          paddingTop: 4,
          paddingLeft: 4,
          paddingBottom: 3,
        }}>
        <Typography typography='h1'>My Projects</Typography>
        <Button
          onClick={onCreateNewPress}
          startIcon={<Add />}
          variant='contained'>
          Create New
        </Button>
      </Toolbar>
      {data.length > 0 ? (
        <Box display='flex' flex={1} flexDirection='column'>
          <Container
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
              maxHeight: "70vh", // Adjust the height to leave room for the toolbar and pagination
              overflow: "scroll",
            }}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              sx={{
                border: "1px solid #E5E5E5",
                padding: 1.5,
                borderRadius: 2,
              }}>
              <input
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                style={{
                  outline: "none",
                  border: "none",
                  width: "100%",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                }}
                placeholder='Search your projects'
              />
              <div
                style={{
                  width: 1,
                  padding: 1,
                  backgroundColor: theme.palette.primary.light,
                  height: 16,
                  marginRight: 6,
                }}></div>
              <Search
                sx={{ color: "#9EA7B5", marginRight: 2 }}
                fontSize='small'
              />
            </Box>

            <TableContainer>
              <Table
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 16px",
                  },
                }}>
                <TableHead>
                  <TableRow
                    sx={{
                      fontFamily: "Gilroy-Semibold",
                      fontSize: 14,
                    }}>
                    <TableCell
                      sx={{
                        fontFamily: "Gilroy-Semibold",
                        fontSize: 14,
                        padding: 2,
                      }}
                      colSpan={3}>
                      PROJECTS
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Gilroy-Semibold",
                        fontSize: 14,
                        padding: 2,
                      }}>
                      TYPE
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Gilroy-Semibold",
                        fontSize: 14,
                        padding: 2,
                      }}>
                      CREATED ON
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Gilroy-Semibold",
                        fontSize: 14,
                        padding: 2,
                      }}>
                      LAST MODIFIED
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getDisplayedData().map((row) => (
                    <TableRow hover key={row.id}>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                          fontFamily: "Gilroy-Medium",
                          border: "none",
                          padding: 2,
                        }}
                        onClick={() => {
                          handleDetails(row);
                        }}
                        colSpan={3}>
                        <Box
                          maxWidth={240}
                          display='flex'
                          alignItems='center'
                          gap={1}>
                          <img src={projectDocIcon} />
                          <Tooltip title={row.name}>
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: 14,
                              }}
                              gutterBottom={false}>
                              {row.name}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Gilroy-Semibold",
                          fontSize: 14,
                          border: "none",
                          padding: 2,
                        }}>
                        {" "}
                        <Box
                          display='flex'
                          alignItems='center'
                          width={"85%"}
                          gap={0.5}
                          sx={{
                            backgroundColor:
                              row.type === "Discovery" ? "#DCEDDB" : "#FFE7E6",
                            borderRadius: 10,
                            paddingRight: 1,
                            paddingLeft: 1,
                            paddingTop: 0.4,
                            paddingBottom: 0.4,
                            justifyContent: "flex-start",
                          }}>
                          <img
                            src={
                              row.type === "Discovery"
                                ? discoveryIcon
                                : enrichIcon
                            }
                          />
                          {row.type}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Gilroy-Medium",
                          fontSize: 14,
                          border: "none",
                          padding: 2,
                        }}>
                        {formatDate(row.created_at)}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Gilroy-Medium",
                          fontSize: 14,
                          border: "none",
                          padding: 2,
                        }}>
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'>
                          {formatDate(row.updated_at)}
                          <img
                            onClick={(e: any) => {
                              setSelectedRow(row);
                              handleClick(e);
                            }}
                            src={elipseIcon}
                            style={{
                              cursor: "pointer",
                            }}
                          />{" "}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}>
                {!loading ? (
                  <Box
                    p={2}
                    display='flex'
                    flexDirection='column'
                    alignItems='flex-start'
                    fontFamily='Gilroy-Medium'
                    justifyContent='flex-start'>
                    <Button
                      startIcon={<img src={duplicate} height={16} width={16} />}
                      onClick={handleDuplicate}
                      style={{ color: "#757575", fontSize: 14 }}>
                      Duplicate
                    </Button>
                    <Button
                      startIcon={<img src={rename} height={16} width={16} />}
                      onClick={() => {
                        setRenameDialogOpen(true);
                      }}
                      style={{ color: "#757575", fontSize: 14 }}>
                      Rename
                    </Button>
                    <Button
                      startIcon={
                        <img src={deleteIcon} height={16} width={16} />
                      }
                      onClick={() => {
                        setDeleteDialogOpen(true);
                      }}
                      style={{
                        color: theme.palette.secondary.main,
                        fontSize: 14,
                      }}>
                      Delete
                    </Button>
                  </Box>
                ) : (
                  <Box padding={4}>
                    <BurstLoader />
                  </Box>
                )}
              </Popover>
            </TableContainer>
          </Container>

          <Box display='flex' flexDirection='column' marginTop='auto'>
            <Divider sx={{}} />

            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              sx={{
                padding: 1,
              }}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='flex-start'>
                <Typography
                  fontSize={14}
                  color='#6A7383'
                  fontFamily='Gilroy-Medium'>
                  View
                </Typography>
                <TextField
                  select
                  variant='outlined'
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                  SelectProps={{
                    native: true,
                    IconComponent: CustomDropdownIcon,
                  }}
                  inputProps={{
                    style: {
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingRight: 4,
                      paddingLeft: 4,
                      textAlign: "start",
                      marginLeft: 2,
                      color: "#6A7383",
                      fontSize: 14,
                      fontFamily: "Gilroy-Medium",
                    },
                  }}
                  sx={{
                    padding: 1,
                  }}>
                  {[10, 50, 100].map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
                <Typography typography='h3'>rows per page</Typography>
              </Box>

              <Box display='flex' alignItems='center' justifyContent='flex-end'>
                <Typography
                  fontFamily='Gilroy-Medium'
                  fontSize={14}
                  color='#6A7383'>
                  Showing {filteredData.length > 0 ? page + 1 : 0}-
                  {Math.min((page + 1) * rowsPerPage, filteredData.length)} of{" "}
                  {filteredData.length}
                </Typography>

                <Pagination
                  totalPages={totalPages}
                  currentPage={page}
                  handlePageChange={handleChangePage}
                />
              </Box>
            </Box>
            <Divider sx={{}} />
          </Box>
        </Box>
      ) : (
        <Container>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    fontFamily: "Gilroy-Semibold",
                  }}>
                  <TableCell
                    sx={{
                      fontFamily: "Gilroy-Semibold",
                    }}
                    colSpan={3}>
                    PROJECTS
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Gilroy-Semibold",
                    }}>
                    TYPE
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Gilroy-Semibold",
                    }}>
                    CREATED ON
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Gilroy-Semibold",
                    }}>
                    LAST MODIFIED
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <TableFooter></TableFooter>
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 3,
            }}>
            <img src={noDataIcon} />
            <Typography fontFamily='Gilroy-Medium' typography='h2'>
              No data yet
            </Typography>
            <Typography fontFamily='Gilroy-Medium' typography='h2'>
              Click on the button below to create your first project
            </Typography>
            <Button
              onClick={onCreateNewPress}
              startIcon={<Add />}
              variant='contained'>
              Create New
            </Button>
          </Box>
        </Container>
      )}
      <RenameDialog
        open={isRenameDialogOpen}
        selectedRow={selectedRow}
        onClose={() => setRenameDialogOpen(false)}
        onRename={handleRename}
      />

      <DeleteDialog
        open={isDeleteDialogOpen}
        selectedRow={selectedRow}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleDelete}
      />
    </Box>
  );
};

export default MainContainer;
