import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { NewCol } from "../home/SpreadSheet";

interface HeaderFilterProps {
  headers: NewCol[];
  visibleColumns: string[];
  onToggleColumn: (key: string) => void;
  handleClose: () => void;
  anchorEl: null | HTMLElement;
}

const HeaderFilter: React.FC<HeaderFilterProps> = ({
  headers,
  visibleColumns,
  onToggleColumn,
  handleClose,
  anchorEl,
}) => {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleToggleColumn = (key: string) => {
    onToggleColumn(key);
  };

  return (
    <>
      <Menu
        sx={{
          height: 500,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {headers.map((header) => (
          <MenuItem
            style={{
              marginTop: 2,
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 2,
              paddingRight: 24,
              alignItems: "flex-start",
              borderRadius: 24,
            }}
            key={header.column_name}>
            <FormControlLabel
              style={{
                padding: 0,
                margin: 0,
                fontSize: "10px",
                alignItems: "flex-start",
                width: "100%",
                justifyContent: "flex-start",
              }}
              control={
                <Checkbox
                  checked={visibleColumns.includes(header.column_name)}
                  onChange={() => handleToggleColumn(header.column_name)}
                />
              }
              label={
                <p
                  style={{
                    fontSize: "12px",
                    fontFamily: "Gilroy-Medium",
                  }}>
                  {header.column_name}
                </p>
              }
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default HeaderFilter;
