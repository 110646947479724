import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Link,
  Grid,
  Divider,
  createStyles,
  makeStyles,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link as RouterLink } from "react-router-dom";
import googleLogo from "../../assets/images/google-logo.png";
import theme from "../../theme";
import TermsPrivacy from "./TermsPrivacy";
import BurstLoader from "../common/BurstLoader";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";

type SignInActionProps = {
  email: string;
  onSetEmail: (email: string) => void;
  onContinuePress: () => void;
};

const SignInAction: React.FC<SignInActionProps> = ({
  email,
  onSetEmail,
  onContinuePress,
}) => {
  const { signInWithEmail, verifyOTP, signInWithGoogle } = useAuth();

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const notify = (message: string) => toast(message);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSetEmail(event.target.value);
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error: any) {
      notify(error.message);
      console.error("Error signing in with Google:", error);
    }
  };

  const handleEmailSignIn = async () => {
    try {
      await signInWithEmail(email);
      // notify("OTP sent successfully");
      setLoading(false);
      onContinuePress();
    } catch (error: any) {
      setLoading(false);
      notify(error.message);
      console.error("Error signing in with email:", error);
    }
  };

  const handleButtonClick = () => {
    if (validateEmail(email)) {
      setIsValidEmail(true);
      setLoading(true);
      handleEmailSignIn();
    } else {
      setIsValidEmail(false);
      // Handle invalid email case
    }
  };

  useEffect(() => {
    if (email.trim().length === 0) {
      setIsValidEmail(true);
    }
  }, [email]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        marginTop: 12,
        flex: 1,
        gap: 1,
        width: "100%",
        paddingRight: 6,
        paddingTop: 6,
        paddingBottom: 6,
        padding: 2,
      }}>
      <Typography typography='h1'>Welcome to ListenBravo!</Typography>
      <Typography typography='h3'>
        Listen through the entire internet to create opportunities for your
        business at scale.
      </Typography>
      <Button
        variant='outlined'
        sx={{
          marginTop: 4,
          borderColor: theme.palette.primary.light,
          fontWeight: 600,
          padding: 2,
          "&:hover": {
            borderColor: "#E5E5E5",
          },
          "&:focus": {
            borderColor: "#E5E5E5",
          },
          fontSize: 16,
        }}
        onClick={handleGoogleSignIn}
        fullWidth
        startIcon={<img src={googleLogo} height={24} width={24} />}>
        Continue with Google
      </Button>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 1,
          gap: 2,
        }}>
        <Divider sx={{ flex: 1 }} />
        <Typography typography='h4'>or</Typography>
        <Divider sx={{ flex: 1 }} />
      </Box>
      <TextField
        variant='outlined'
        onChange={handleEmailChange}
        placeholder='Enter your Email'
        inputProps={{
          style: {
            paddingTop: 16,
            paddingBottom: 16,
          },
        }}
        type='email'
        sx={{
          padding: 0,
          marginTop: 2,

          ".MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: isValidEmail
                ? "E5E5E5"
                : theme.palette.secondary.main,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: isValidEmail
                ? "#E5E5E5"
                : theme.palette.secondary.main,
              outlineColor: isValidEmail
                ? "#E5E5E5"
                : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
              borderColor: "#E5E5E5",
              outlineColor: isValidEmail
                ? "#E5E5E5"
                : theme.palette.secondary.main,
            },
          },
        }}
        fullWidth></TextField>
      {!isValidEmail && (
        <Typography
          typography='h4'
          sx={{
            color: theme.palette.error.main,
            marginTop: 0.5,
          }}>
          Please enter a valid email id to continue
        </Typography>
      )}
      {loading ? (
        <Box
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          marginTop={2}>
          <BurstLoader />
        </Box>
      ) : (
        <Button
          variant='contained'
          sx={{
            marginTop: 2,
            borderColor: theme.palette.primary.light,
            fontWeight: 600,
            padding: 2,
            fontSize: 16,
          }}
          fullWidth
          onClick={handleButtonClick}>
          Continue
        </Button>
      )}
    </Box>
  );
};

export default SignInAction;
