import React from "react";
import { Box, styled, keyframes } from "@mui/material";
const fade = keyframes`
  0% {
    background-color: #adadad;
  }
  100% {
    background-color: transparent;
  }
`;
const SpinnerBlade = styled(Box)(
  ({ delay, rotate }: { delay: string; rotate: number }) => ({
    position: "absolute",
    left: "0.4629em",
    bottom: 0,
    width: "0.074em",
    height: "0.2777em",
    borderRadius: "0.5em",
    backgroundColor: "transparent",
    transformOrigin: "center -0.2222em",
    animation: `${fade} 1s infinite linear`,
    animationDelay: delay,
    transform: `rotate(${rotate}deg)`,
  })
);
type BurstLoaderType = {
  fontSize?: number | string | undefined;
};

interface SpinnerContainerProps {
  fontSize?: number | string | undefined;
}

const SpinnerContainer = styled(Box)<SpinnerContainerProps>(
  ({ fontSize = "24px" }) => ({
    fontSize: fontSize,
    width: "1em",
    height: "1em",
    position: "relative",
    display: "inline-block",
  })
);

const BurstLoader: React.FC<BurstLoaderType> = ({ fontSize = "24px" }) => {
  return (
    <SpinnerContainer fontSize={fontSize}>
      {[...Array(8)].map((_, i) => (
        <SpinnerBlade key={i} delay={`${i * 0.13}s`} rotate={i * 45} />
      ))}
    </SpinnerContainer>
  );
};

export default BurstLoader;
