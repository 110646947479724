import { apiService } from "./apiService";

export type ProjectRecord = {
  id: string;
  name: string;
  type: string;
  created_at: string;
  updated_at: string;
  [key: string]: any;
};

export type ListProjectsResponse = {
  data: ProjectRecord[];
  count: number | null;
};

export const listProjects = async (
  email: string
): Promise<ListProjectsResponse> => {
  const response = await apiService.get<string>(
    `/list-projects?email=${email}`
  );

  // Assuming response is a JSON string, parse it into an object
  const parsedResponse: ListProjectsResponse = JSON.parse(response);

  return parsedResponse;
};
