import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  FirstPage,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  LastPage,
} from "@mui/icons-material";
import theme from "../../theme";

type PaginationProps = {
  totalPages: number;
  currentPage: number;
  handlePageChange: (page: number) => void;
};
const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  handlePageChange,
}) => {
  const maxVisiblePages = 3;

  const getVisiblePages = () => {
    const visiblePages = [];
    let start = Math.max(0, currentPage - Math.floor(maxVisiblePages / 2));
    let end = Math.min(totalPages - 1, start + maxVisiblePages - 1);

    if (end - start + 1 < maxVisiblePages) {
      start = Math.max(0, end - maxVisiblePages + 1);
    }

    for (let i = start; i <= end; i++) {
      visiblePages.push(i);
    }
    return visiblePages;
  };

  return (
    <Box display='flex' alignItems='center'>
      <IconButton
        onClick={() => handlePageChange(0)}
        disabled={currentPage === 0}>
        <KeyboardDoubleArrowLeft />
      </IconButton>
      <Box
        sx={{
          height: 18,
          width: 2,
          backgroundColor: theme.palette.primary.light,
        }}
      />
      <IconButton
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 0}>
        <ArrowBackIos sx={{ width: 20, height: 20 }} />
      </IconButton>
      {getVisiblePages().map((page) => (
        <IconButton
          key={page}
          onClick={() => handlePageChange(page)}
          style={{
            backgroundColor:
              currentPage === page ? "rgba(255, 255, 255, 0.1)" : "transparent",
            color: page === currentPage ? "white" : theme.palette.primary.main,
          }}>
          {page === currentPage ? (
            <Typography
              sx={{
                paddingTop: 0.4,
                paddingBottom: 0.4,
                paddingRight: 1.4,
                paddingLeft: 1.4,
                borderRadius: 2,
                bgcolor: theme.palette.primary.main,
              }}
              fontFamily='Gilroy-Medium'
              fontSize={14}>
              {page + 1}
            </Typography>
          ) : (
            <Typography>{page + 1}</Typography>
          )}
        </IconButton>
      ))}
      <IconButton
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages - 1}>
        <ArrowForwardIos sx={{ width: 20, height: 20 }} />
      </IconButton>
      <Box
        sx={{
          height: 18,
          width: 2,
          backgroundColor: theme.palette.primary.light,
        }}
      />
      <IconButton
        onClick={() => handlePageChange(totalPages - 1)}
        disabled={currentPage === totalPages - 1}>
        <KeyboardDoubleArrowRight />
      </IconButton>
    </Box>
  );
};

export default Pagination;
