import React from "react";
import { Box } from "@mui/material";
import looper from "../../assets/images/looper.png";
import theme from "../../theme";

const SignInDesign: React.FC = () => {
  return (
    <Box
      sx={{
        padding: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: 1.5,
        backgroundColor: theme.palette.primary.main,
      }}>
      <img src={looper} alt='looper' style={{ maxHeight: "300px" }} />
    </Box>
  );
};

export default SignInDesign;
