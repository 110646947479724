// src/context/AuthContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { User } from "@supabase/supabase-js";
import { supabase } from "../supabaseClient";
import BurstLoader from "../components/common/BurstLoader";
import logo from "../assets/images/listen-bravo-logo.png";
import { Box, Typography, useMediaQuery } from "@mui/material";
import theme from "../theme";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Home from "../pages/Home";
import Signin from "../pages/Signin";
import ScreenSiizeUnavailable from "../components/common/ScreenSizeUnavailable";

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signInWithEmail: (email: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  verifyOTP: (email: string, token: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{}> = ({}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const initSupabase = () => {
    supabase.auth.getSession().then((v) => {
      setUser(v?.data.session?.user ?? null);
      setLoading(false);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user ?? null);
      }
    );
  };
  useEffect(() => {
    initSupabase();
  }, []);

  const signInWithEmail = async (email: string) => {
    const { error, data } = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: true,
      },
    });
    if (error) throw error;
  };

  const verifyOTP = async (email: string, token: string) => {
    const { data, error } = await supabase.auth.verifyOtp({
      email: email,
      token: token,
      type: "email",
      options: {
        redirectTo: "/home",
      },
    });
    if (error) throw error;
    setUser(data.user);
  };

  const signInWithGoogle = async () => {
    const { error, data } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: window.location.origin,
      },
    });

    if (error) throw error;
  };

  const signOut = async () => {
    await supabase.auth.signOut();
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        signInWithEmail,
        signInWithGoogle,
        verifyOTP,
        signOut,
      }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            backgroundColor: "#ffffff",
          }}>
          <img src={logo} height={100} width={100} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#333",
              marginTop: theme.spacing(2),
              gap: 1,
            }}>
            <BurstLoader />
            <Typography
              typography='h2'
              color={theme.palette.primary.main}
              fontWeight={600}>
              ListenBravo
            </Typography>
          </Box>
        </Box>
      ) : (
        <Router>
          {user ? (
            isMobile ? (
              <Routes>
                <Route path='*' element={<ScreenSiizeUnavailable />} />
              </Routes>
            ) : (
              <Routes>
                <Route path='/' element={<Navigate to={"/home"} />} />
                <Route path='/home' element={<Home />} />
                <Route path='*' element={<Navigate to='/home' />} />
              </Routes>
            )
          ) : (
            <Routes>
              <Route path='/' element={<Navigate to={"/signin"} />} />
              <Route path='/signin' element={<Signin />} />
              <Route path='*' element={<Navigate to='/signin' />} />
            </Routes>
          )}
        </Router>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
